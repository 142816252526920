import React from 'react';
import styled from "styled-components";
import AwardsLogo from "../images/AwardsLogo";
import QuestionInCircleSvg from "../images/QuestionInCircleSvg";
import MenuHomeSvg from "../images/MenuHomeSvg";
import MenuShopSvg from "../images/MenuShopSvg";
import MenuUsersSvg from "../images/MenuUsersSvg";
import MenuBellSvg from "../images/MenuBellSvg";
import MenuSettingsSvg from "../images/MenuSettingsSvg";
import {Link, useLocation} from "react-router-dom";
import GiveAwardsSvg from "../images/GiveAwardsSvg";
import {useSelector} from "react-redux";
import nouser from "../images/nouser.png";
import BankSvg from "../images/BankSvg";
import {Button} from "antd";
import ExitSvg from "../images/ExitSvg";
import {api} from "../config";
import MenuUserSvg from "../images/MenuUserSvg";

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 305px;
  min-width: 305px;
  border: 1px solid #E0E9FF;
  box-shadow: 0 0 15px rgba(147, 197, 234, 0.15);
  border-radius: 12px;
  width: 100%;

  * {
    font-family: Inter, Montserrat, sans-serif;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

const MenuHead = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #B3C3ED;
  padding: 32px 0;
  margin: 0 28px;
`;

const Avatar = styled.div`
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 12px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: 0 38px;
`;

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  color: #6780be;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;

  &:not(:last-child) {
    margin-bottom: 38px;
  }

  &.active {
    position: relative;
    color: #3a3a3a;

    svg {
      path, circle {
        fill: #2361FF;
      }
    }

    &:before {
      content: '';
      transform: translateX(-38px);
      position: absolute;
      left: 0;
      height: 100%;
      width: 8px;
      background: #2361FF;
      box-shadow: 1px 0 5px rgba(35, 97, 255, 0.35);
      border-radius: 0 6px 6px 0;
    }
  }

  svg {
    margin-right: 18px;
  }
`;

const GiveAwards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 68px 20px 68px;
  background: #F5F8FE;
  border: 1px solid #E4EBFA;
  border-radius: 12px 8px 12px 12px;
  margin: auto 16px 0 16px;

  svg {
    margin-bottom: 18px;
  }

  h1 {
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: 600;
    color: #262626;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: #868a92;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 28px 0 28px;
  border-top: 1px solid #B3C3ED;
  padding: 32px 10px 32px 0;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Greetings = styled.div`
  color: #757575;
  font-size: 14px;
  font-weight: 500;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #262626;
`;

function Menu() {
    const location = useLocation();

    const userData = useSelector(({loginReducer: {userData}}: any) => userData);

    return (
        <StyledMenu>
            <MenuHead>
                <Avatar>
                    <img
                        src={userData.Photo ? `/${userData.Photo}` : nouser}
                        alt="avatar"/>
                </Avatar>
                <Text>
                    <Greetings>{userData.Greeting}</Greetings>
                    <Title>{userData.Name || userData.Login}</Title>
                </Text>
            </MenuHead>
            <MenuItems>
                <MenuItem className={location.pathname.startsWith("/home") ? "active" : ""} to={"/home"}><MenuHomeSvg/>Home</MenuItem>
                <MenuItem className={location.pathname.startsWith("/shop") ? "active" : ""} to={"/shop"}><MenuShopSvg/>Awards
                    shop</MenuItem>
                <MenuItem className={location.pathname.startsWith("/companies/my") ? "active" : ""}
                          to={"/companies/my"}><BankSvg/>My company</MenuItem>
                {/*<MenuItem className={location.pathname.startsWith("/users") ? "active" : ""}*/}
                {/*          to={"/users"}><MenuUsersSvg/>Users</MenuItem>*/}
                {/*<MenuItem className={location.pathname.startsWith("/notifications") ? "active" : ""}*/}
                {/*          to={"/notifications"}><MenuBellSvg/>Notification</MenuItem>*/}
                <MenuItem className={location.pathname.startsWith("/user/profile") ? "active" : ""}
                          to={"/user/profile"}><MenuUserSvg/>Profile</MenuItem>
            </MenuItems>
            {/*<GiveAwards>*/}
            {/*    <GiveAwardsSvg/>*/}
            {/*    <h1>Give awards</h1>*/}
            {/*    <p>Please your employee</p>*/}
            {/*</GiveAwards>*/}
            <Footer>
                <AwardsLogo/>
                <QuestionInCircleSvg/>
                <a style={{height: 30, transform: "rotateZ(90deg)"}} href={`${api}/exit`}><ExitSvg/></a>
            </Footer>
        </StyledMenu>
    );
}

export default Menu;