import React from 'react';

function UserInputSvg(props:any) {
    return (
        <svg {...props} width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.71 11.7101C14.6904 10.9388 15.406 9.88105 15.7572 8.68407C16.1085 7.48709 16.0779 6.21039 15.6698 5.03159C15.2617 3.85279 14.4963 2.83052 13.4801 2.10698C12.4639 1.38344 11.2474 0.994629 10 0.994629C8.75255 0.994629 7.53611 1.38344 6.51993 2.10698C5.50374 2.83052 4.73834 3.85279 4.33021 5.03159C3.92208 6.21039 3.89151 7.48709 4.24276 8.68407C4.59401 9.88105 5.3096 10.9388 6.29 11.7101C4.61007 12.3832 3.14428 13.4995 2.04889 14.94C0.953495 16.3806 0.26956 18.0914 0.0699967 19.8901C0.0555513 20.0214 0.0671132 20.1543 0.104022 20.2812C0.140931 20.408 0.202464 20.5264 0.285108 20.6294C0.452016 20.8376 0.69478 20.971 0.959997 21.0001C1.22521 21.0293 1.49116 20.9519 1.69932 20.785C1.90749 20.6181 2.04082 20.3753 2.07 20.1101C2.28958 18.1553 3.22168 16.3499 4.68822 15.0389C6.15475 13.7279 8.0529 13.0032 10.02 13.0032C11.9871 13.0032 13.8852 13.7279 15.3518 15.0389C16.8183 16.3499 17.7504 18.1553 17.97 20.1101C17.9972 20.3558 18.1144 20.5828 18.2991 20.7471C18.4838 20.9115 18.7228 21.0016 18.97 21.0001H19.08C19.3421 20.97 19.5817 20.8374 19.7466 20.6314C19.9114 20.4253 19.9881 20.1625 19.96 19.9001C19.7595 18.0963 19.0719 16.3811 17.9708 14.9383C16.8698 13.4955 15.3969 12.3796 13.71 11.7101ZM10 11.0001C9.20887 11.0001 8.43551 10.7655 7.77772 10.326C7.11992 9.88648 6.60723 9.26176 6.30448 8.53086C6.00173 7.79995 5.92251 6.99569 6.07686 6.21976C6.2312 5.44384 6.61216 4.73111 7.17157 4.1717C7.73098 3.61229 8.44371 3.23132 9.21964 3.07698C9.99556 2.92264 10.7998 3.00186 11.5307 3.30461C12.2616 3.60736 12.8863 4.12005 13.3259 4.77784C13.7654 5.43564 14 6.209 14 7.00012C14 8.06099 13.5786 9.07841 12.8284 9.82855C12.0783 10.5787 11.0609 11.0001 10 11.0001Z"
                fill="#C6CFE5"/>
        </svg>
    );
}

export default UserInputSvg;