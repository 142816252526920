import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";


export const NavItem = styled(Link)`
  color: #8e8e8e;
  font-size: 16px;
  font-weight: 600;
`;

export const LinkA = styled.a`
  color: #8e8e8e;
  font-size: 16px;
  font-weight: 600;
`;

function NavList() {
    return (
        <React.Fragment>
            <LinkA href={"#"}>Home</LinkA>
            <LinkA href={"#forwhat"}>For what</LinkA>
            <LinkA href={"#why"}>Why it is amazing?</LinkA>
            <LinkA href={"#how"}>How to use</LinkA>
            <LinkA href={"#contacts"}>Contacts</LinkA>
        </React.Fragment>
    );
}

export default NavList;