import React from 'react';
import styled from "styled-components";
import {StyledInput} from "./styled/StyledInput";
import {TextAreaProps} from "antd/es/input";

export const StyledLabeledTextarea = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  textarea {
    padding-top: 28px;
    padding-left: 20px;
    color: #09101d;
    font-size: 14px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const Label = styled.div`
  width: 99%;
  background: white;
  border-bottom: none;  
  border-radius: 12px 12px 0 0;
  position: absolute;
  top: 1px;
  color: #747b84;
  padding: 10px 0 0 20px;
  font-size: 12px;
  z-index: 2;
`;

type LabeledTextareaProps = TextAreaProps & {
    label?: string
}


function LabeledTextarea({label = "", ...props}: LabeledTextareaProps) {
    return (
        <StyledLabeledTextarea>
            <StyledInput.TextArea {...props}/>
            <Label>{label}</Label>
        </StyledLabeledTextarea>
    );
}

export default LabeledTextarea;