import React from 'react';

function UserSvg(props: any) {
    return (
        <svg {...props} width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.42 22.42C29.3808 20.8773 30.812 18.7618 31.5145 16.3679C32.217 13.9739 32.1558 11.4205 31.3396 9.06294C30.5233 6.70534 28.9925 4.66079 26.9601 3.21372C24.9278 1.76664 22.4949 0.989014 20 0.989014C17.5051 0.989014 15.0722 1.76664 13.0399 3.21372C11.0075 4.66079 9.47668 6.70534 8.66042 9.06294C7.84415 11.4205 7.78302 13.9739 8.48551 16.3679C9.18801 18.7618 10.6192 20.8773 12.58 22.42C9.22015 23.7661 6.28857 25.9987 4.09778 28.8798C1.90699 31.761 0.539119 35.1826 0.139993 38.78C0.111103 39.0426 0.134226 39.3084 0.208044 39.5621C0.281862 39.8158 0.404928 40.0525 0.570216 40.2587C0.904031 40.675 1.38956 40.9417 1.91999 41C2.45043 41.0583 2.98231 40.9036 3.39864 40.5698C3.81497 40.236 4.08165 39.7504 4.13999 39.22C4.57916 35.3104 6.44337 31.6996 9.37643 29.0776C12.3095 26.4555 16.1058 25.0061 20.04 25.0061C23.9742 25.0061 27.7705 26.4555 30.7036 29.0776C33.6366 31.6996 35.5008 35.3104 35.94 39.22C35.9944 39.7114 36.2289 40.1653 36.5982 40.494C36.9675 40.8227 37.4456 41.003 37.94 41H38.16C38.6843 40.9397 39.1634 40.6746 39.4931 40.2625C39.8228 39.8504 39.9762 39.3247 39.92 38.8C39.519 35.1924 38.1437 31.762 35.9417 28.8764C33.7396 25.9908 30.7938 23.759 27.42 22.42V22.42ZM20 21C18.4177 21 16.871 20.5308 15.5554 19.6518C14.2398 18.7727 13.2145 17.5233 12.609 16.0615C12.0035 14.5997 11.845 12.9911 12.1537 11.4393C12.4624 9.88743 13.2243 8.46197 14.3431 7.34315C15.462 6.22433 16.8874 5.4624 18.4393 5.15372C19.9911 4.84504 21.5997 5.00347 23.0615 5.60897C24.5233 6.21447 25.7727 7.23985 26.6517 8.55544C27.5308 9.87104 28 11.4178 28 13C28 15.1217 27.1571 17.1566 25.6568 18.6569C24.1566 20.1571 22.1217 21 20 21Z"
                fill="#7EA2FF"/>
        </svg>
    );
}

export default UserSvg;