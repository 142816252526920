import React from 'react';

function BalanceSvg(props:any) {
    return (
        <svg {...props} width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_48_109)">
                <path
                    d="M5.1978 3.60541C5.55764 3.39766 5.73756 3.29378 5.93513 3.29378C6.13269 3.29378 6.31261 3.39766 6.67245 3.60541L7.11343 3.86001C7.47327 4.06777 7.65319 4.17164 7.75198 4.34274C7.85076 4.51384 7.85076 4.72159 7.85076 5.1371V5.6463C7.85076 6.06181 7.85076 6.26956 7.75198 6.44066C7.65319 6.61176 7.47327 6.71563 7.11343 6.92339L6.67245 7.17799C6.31261 7.38574 6.13269 7.48962 5.93513 7.48962C5.73756 7.48962 5.55764 7.38574 5.1978 7.17798L4.75682 6.92339C4.39698 6.71563 4.21706 6.61176 4.11828 6.44066C4.01949 6.26956 4.01949 6.06181 4.01949 5.6463V5.1371C4.01949 4.72159 4.01949 4.51384 4.11828 4.34274C4.21706 4.17164 4.39698 4.06777 4.75682 3.86001L5.1978 3.60541Z"
                    fill="#2361FF"/>
            </g>
            <path
                d="M4.64489 1.40107C5.01094 1.18973 5.26275 1.04483 5.47172 0.950168C5.6733 0.858852 5.8084 0.827201 5.93522 0.827201C6.06203 0.827201 6.19713 0.858852 6.39872 0.950168C6.60769 1.04483 6.85949 1.18973 7.22554 1.40107L8.74604 2.27893C9.11209 2.49027 9.36348 2.63589 9.54994 2.76953C9.72982 2.89845 9.82478 2.99962 9.88819 3.10945C9.95159 3.21927 9.99173 3.3521 10.0134 3.57234C10.0359 3.80064 10.0364 4.09116 10.0364 4.51384V6.26956C10.0364 6.69224 10.0359 6.98276 10.0134 7.21106C9.99173 7.43129 9.95159 7.56412 9.88819 7.67395C9.82478 7.78377 9.72982 7.88495 9.54994 8.01386C9.36348 8.14751 9.11209 8.29312 8.74604 8.50446L7.22554 9.38232C6.85949 9.59366 6.60769 9.73856 6.39872 9.83323C6.19713 9.92454 6.06203 9.95619 5.93522 9.95619C5.8084 9.95619 5.6733 9.92454 5.47172 9.83323C5.26275 9.73857 5.01094 9.59366 4.64489 9.38232L3.12439 8.50446C2.75834 8.29312 2.50695 8.14751 2.32049 8.01386C2.14061 7.88495 2.04565 7.78377 1.98225 7.67395C1.91884 7.56412 1.8787 7.43129 1.85699 7.21106C1.83448 6.98276 1.83407 6.69224 1.83407 6.26956V4.51384C1.83407 4.09116 1.83448 3.80064 1.85699 3.57234C1.8787 3.3521 1.91884 3.21927 1.98225 3.10945C2.04565 2.99962 2.14061 2.89845 2.32049 2.76953C2.50695 2.63589 2.75834 2.49027 3.12439 2.27893L4.64489 1.40107Z"
                stroke="#2361FF" strokeWidth="0.737327"/>
            <defs>
                <filter id="filter0_d_48_109" x="1.07022" y="0.344469" width="9.72967" height="10.0945"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="1.47465"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.138558 0 0 0 0 0.379762 0 0 0 0 1 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_48_109"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_48_109" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
}

export default BalanceSvg;