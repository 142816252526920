import React from 'react';
import styled from "styled-components";
import EyeSvg from "../images/EyeSvg";
import moment from "moment";
import ApplySvg from "../images/ApplySvg";
import DeclineSvg from "../images/DeclineSvg";
import axios from "axios";
import {api} from "../config";
import {message, Spin} from "antd";
import store from "../redux/store";
import {
    addLoadingNotifications,
    getAwards,
    getNotifications,
    removeLoadingNotifications
} from "../redux/actions/pageActions";
import {useSelector} from "react-redux";

const Image = styled.div`
  margin-right: 18px;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Date = styled.div`
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ACB4C3;
`;

const Title = styled.div`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #262626;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2361FF;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  ${Action} {
    cursor: pointer;

    &:hover {
      opacity: .8;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  svg {
    flex: 0 0 auto;
    margin-right: 6px;
  }
`;


const Index = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 12px;
`;

export const StyledMiniAwardInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 1px;
    background: #E7E7E7;
  }
`;


function MiniAwardInfo({index, notification}: any) {
    const loadingNotifications = useSelector(({pageReducer: {loadingNotifications}}: any) => loadingNotifications);

    const applyInvite = (notifId: number, id: string) => {
        console.log(id);
        store.dispatch(addLoadingNotifications(id))

        axios(`${api}/transitions/${id}/apply`).then(() => {
            message.success("Invitation successfully accepted");
            removeNotification(notifId);
            store.dispatch(getAwards());
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        }).finally(() => {
            store.dispatch(removeLoadingNotifications(id))
        })
    }


    const cancelInvite = (notifId: number, id: number) => {
        axios(`${api}/transitions/${id}/cancel`).then(() => {
            message.warning("Invitation successfully declined");
            removeNotification(notifId);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const removeNotification = (id: number) => {
        axios.delete(`${api}/user/notifications/${id}`).then(() => {
            store.dispatch(getNotifications())
        })
    }

    return (
        <StyledMiniAwardInfo>
            {index && <Index>{index}</Index>}
            <Image>
                {notification?.Data?.StampDetail?.Images?.filter((el: any) => el.OrderZ < 0).sort((el: any, prev: any) => el.OrderZ - prev.OrderZ)?.map((image: any, index: number) =>
                    <img key={index}
                         src={image?.RawImage}
                         alt=""/>)}
                <img
                    src={`/${notification?.Data?.Template?.Image}`}
                    alt=""/>
                {notification?.Data?.StampDetail?.Images?.filter((el: any) => el.OrderZ > 0).sort((el: any, prev: any) => el.OrderZ - prev.OrderZ)?.map((image: any, index: number) =>
                    <img key={index}
                         src={image?.RawImage}
                         alt=""/>)}
            </Image>
            <Info>
                <Date>{moment(notification?.Created).format("DD.MM.YY")}</Date>
                <Title>{notification?.Text}</Title>
                {loadingNotifications.includes(notification.Data.Id) ? <Spin/> : <Actions>
                    <Action onClick={() => applyInvite(notification.Id, notification.Data.Id)}><ApplySvg/> Accept</Action>
                    <Action
                        onClick={() => cancelInvite(notification.Id, notification.Data.Id)}><DeclineSvg/> Decline</Action>
                </Actions>}
            </Info>
        </StyledMiniAwardInfo>
    );
}

export default MiniAwardInfo;