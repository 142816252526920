import React from 'react';

function DeclineSvg(props: any) {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6L6 14" stroke="#677FBE" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L14 14" stroke="#677FBE" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default DeclineSvg;