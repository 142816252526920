import React from 'react';

function Facebook2Svg(props: any) {
    return (
        <svg {...props} width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.617188" y="0.308838" width="29.3825" height="29.3825" rx="8" fill="#425993"/>
            <path
                d="M17.0064 11.3653H18.0294V9.63503C17.5341 9.58353 17.0364 9.5581 16.5385 9.55885C15.0585 9.55885 14.0464 10.4621 14.0464 12.1162V13.5418H12.376V15.4789H14.0464V20.4412H16.0488V15.4789H17.7138L17.9641 13.5418H16.0488V12.3067C16.0488 11.7353 16.2011 11.3653 17.0064 11.3653Z"
                fill="white"/>
        </svg>
    );
}

export default Facebook2Svg;