import React from 'react';

function BurgerSvg(props: any) {
    return (
        <svg {...props} width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.5625 9.14617C27.5625 8.4873 26.9749 7.95319 26.25 7.95319H1.75C1.02513 7.95319 0.4375 8.4873 0.4375 9.14617C0.4375 9.80503 1.02513 10.3391 1.75 10.3391H26.25C26.9749 10.3391 27.5625 9.80503 27.5625 9.14617Z"
                  fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.5625 1.19298C27.5625 0.534115 26.9749 0 26.25 0H1.75C1.02513 0 0.4375 0.534115 0.4375 1.19298C0.4375 1.85184 1.02513 2.38596 1.75 2.38596H26.25C26.9749 2.38596 27.5625 1.85184 27.5625 1.19298Z"
                  fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.5625 17.0994C27.5625 16.4405 26.9749 15.9064 26.25 15.9064H1.75C1.02513 15.9064 0.4375 16.4405 0.4375 17.0994C0.4375 17.7582 1.02513 18.2923 1.75 18.2923H26.25C26.9749 18.2923 27.5625 17.7582 27.5625 17.0994Z"
                  fill="black"/>
        </svg>
    );
}

export default BurgerSvg;