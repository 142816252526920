import React from 'react';

function MenuHomeSvg(props:any) {
    return (
        <svg {...props} width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.0001 10L17.0001 2.11001C16.1751 1.37208 15.107 0.964111 14.0001 0.964111C12.8932 0.964111 11.8252 1.37208 11.0001 2.11001L2.00014 10C1.52369 10.4261 1.14349 10.9488 0.88485 11.5334C0.626208 12.1179 0.495061 12.7508 0.500142 13.39V26.5C0.500142 27.6935 0.974248 28.8381 1.81816 29.682C2.66208 30.5259 3.80667 31 5.00014 31H23.0001C24.1936 31 25.3382 30.5259 26.1821 29.682C27.026 28.8381 27.5001 27.6935 27.5001 26.5V13.375C27.5031 12.7384 27.3709 12.1083 27.1123 11.5265C26.8538 10.9447 26.4747 10.4244 26.0001 10V10ZM17.0001 28H11.0001V20.5C11.0001 20.1022 11.1582 19.7207 11.4395 19.4393C11.7208 19.158 12.1023 19 12.5001 19H15.5001C15.898 19 16.2795 19.158 16.5608 19.4393C16.8421 19.7207 17.0001 20.1022 17.0001 20.5V28ZM24.5001 26.5C24.5001 26.8978 24.3421 27.2794 24.0608 27.5607C23.7795 27.842 23.398 28 23.0001 28H20.0001V20.5C20.0001 19.3065 19.526 18.1619 18.6821 17.318C17.8382 16.4741 16.6936 16 15.5001 16H12.5001C11.3067 16 10.1621 16.4741 9.31816 17.318C8.47425 18.1619 8.00014 19.3065 8.00014 20.5V28H5.00014C4.60232 28 4.22079 27.842 3.93948 27.5607C3.65818 27.2794 3.50014 26.8978 3.50014 26.5V13.375C3.50041 13.162 3.54603 12.9516 3.63397 12.7576C3.7219 12.5636 3.85014 12.3906 4.01014 12.25L13.0101 4.37501C13.2839 4.13453 13.6358 4.00191 14.0001 4.00191C14.3645 4.00191 14.7164 4.13453 14.9901 4.37501L23.9901 12.25C24.1501 12.3906 24.2784 12.5636 24.3663 12.7576C24.4542 12.9516 24.4999 13.162 24.5001 13.375V26.5Z"
                fill="#677FBE"/>
        </svg>
    );
}

export default MenuHomeSvg;