import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {Button, message, Modal} from "antd";
import axios from "axios";
import {api} from "../config";
import {Link, useParams} from "react-router-dom";
import {IconButton} from "../components/styled/IconButton";
import ListItemSvg from "../images/ListItemSvg";
import store from "../redux/store";
import {getGroups} from "../redux/actions/pageActions";
import {useSelector} from "react-redux";
import Spoiler from "../components/Spoiler";
import clip from "text-clipper";
import CopySvg from "../images/CopySvg";
import moment from "moment";
import HearthSvg from "../images/HearthSvg";
import History from "../utils/history";
import BackSvg from "../images/BackSvg";
import ShareIconSvg from "../images/ShareIconSvg";
import AwardsLogo from "../images/AwardsLogo";
import ViewSvg from "../images/ViewSvg";
import CalendarSvg from "../images/CalendarSvg";
import TimeSvg from "../images/TimeSvg";
import HomeSvg from "../images/HomeSvg";

const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  max-width: 560px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const Image = styled.div<{ color: string }>(({color}: any) => css`
  border-radius: 21px;
  overflow: hidden;
  max-height: 560px;
  min-width: 560px;
  position: relative;
  background: ${color || "none"};
  width: 100%;
  height: 100%;
  flex: 1 0 auto;

  img {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`);

const Action = styled.div`
  display: flex;
  align-items: center;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  a {
    display: flex;
    align-items: center;
    color: #3f3f3f;
  }

  svg {
    margin-right: 8px;
  }
`;

const TokenId = styled.div`

`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Collection = styled.div`
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;

  span {
    color: #3d3d3d;
  }
`;

const Views = styled.div`
  color: #3a3a3a;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

const AwardInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 16px;
  min-width: 30%;

  h2 {
    color: #414141;
    font-size: 24px;
    font-weight: 600;
    margin-top: 40px;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${IconButton} {
    flex: 0 0 auto;
    margin-left: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const Text = styled.div`
  margin-right: auto;
  color: #262626;
  font-weight: 600;
  font-size: 18px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #707070;
  margin-top: 20px;
`;

const MobileDescription = styled(Description)`
  display: none;
  margin-bottom: 42px;
`;

const ElementItemName = styled.div`
  margin-right: auto;
  font-size: 14px;
  color: #2A2A2A;
`;
const Created = styled.div`
  svg {
    margin-right: 10px;
  }
`;

const Type = styled.div`

`;

const Secret = styled.div`
  color: #09101d;
  word-break: break-all;
`;

const Who = styled.div`
  img {
    border-radius: 100%;
    overflow: hidden;
    height: 48px;
    width: 48px;
    margin-right: 10px;
    flex: 0 0 auto;
  }
`;

const Contract = styled.div`

`;


const StyledCopySvg = styled(CopySvg)`
  margin-left: auto;
`;

const QrImg = styled.img`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  height: 100%;
`;

const BlockWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  ${Created} {
    flex: 1 1 auto;

    svg {
      flex: 0 0 auto;
    }
  }
`;

const Home = styled(Link)`
  display: flex;
  align-items: center;
  color: #494949;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  svg {
    margin-left: 10px;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
  margin-bottom: 64px;
  border-bottom: 1px solid #D3D3D3;
`;

const StyledCreateAward = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 120px;
  width: 100%;
  height: 100%;
  overflow: auto;

  @media (max-width: 1200px) {
    padding: 45px 30px;
    ${Home} {
      span {
        display: none;
      }
    }

    ${Content} {
      overflow: visible;
      flex-direction: column;
      justify-content: flex-start;

      ${BlockWrap} {
        flex-wrap: wrap;

        ${Created} {
          margin-right: 0;
        }
      }

      ${Left} {
        max-width: none;
        overflow-y: initial;
        margin-bottom: 30px;
        margin-right: 0;
      }

      ${AwardInfo} {
        max-width: none;
        overflow-y: initial;
      }

      ${Description} {
        display: none;
      }

      ${MobileDescription} {
        display: block;
      }

      ${Image} {
        min-width: auto;
        max-width: 320px;
        width: 100%;
        height: 300px;
      }
    }
  }

  b {
    color: #747b84;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  > svg {
    margin-bottom: 40px;
    cursor: pointer;
    flex: 1 0 auto;
  }

  ${Type}, ${Who}, ${Secret}, ${Contract}, ${Created}, ${TokenId} {
    display: flex;
    align-items: center;
    border: 2px solid #B1C5FF;
    border-radius: 12px;
    padding: 10px 20px;
    margin-bottom: 10px;
  }

  ${Type}, ${Contract}, ${Created}, ${TokenId} {
    padding: 10px 20px;
  }

  h1 {
    font-weight: 800;
    font-size: 42px;
    line-height: 51px;
    margin: 0;
    color: #262626;
    text-align: center;
  }
`;

function PublicAward() {

    const groups = useSelector(({pageReducer: {groups}}: any) => groups);

    const [award, setAward] = useState<any>({});
    const {awardId} = useParams();

    const [color, setColor] = useState("");

    const [images, setImages] = useState<any[]>([{
        img: "",
        element: <React.Fragment><ListItemSvg/><ElementItemName>template</ElementItemName></React.Fragment>
    }]);

    const getAward = () => {
        axios(`${api}/public/token/${awardId}`).then(({data}) => {
            setAward(data);
            setColor(data.BackgroundColor);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    useEffect(() => {
        getAward();
        store.dispatch(getGroups());
    }, [])

    // useEffect(() => {
    //     sendAward();
    // }, [images])

    // const testRef = useRef(null);
    // drag(drop(testRef))

    const getQr = () => {
        new (Modal.success as any)({
            maskClosable: true,
            icon: null,
            content: <QrImg src={`${api}/tokens/${awardId}/share`} alt={"qr"}/>
        })
    }

    return (
        <StyledCreateAward>
            <HeaderTop>
                <AwardsLogo onClick={() => History.push("/")}/>
                <Home to={"/"}><span>Go to home page</span> <HomeSvg/></Home>
            </HeaderTop>
            <Content>
                <Left>
                    <Image color={award.Meta?.background_color}>
                        <img src={award.Meta?.image} alt="awardImage"/>
                    </Image>
                    <Description>
                        <h2>Description</h2>
                        {award.Meta?.description}
                    </Description>
                </Left>
                <AwardInfo>
                    {/*<Header>*/}
                    {/*    <Collection>Category: <span>Last chance</span></Collection>*/}
                    {/*    <Views>19 times</Views>*/}
                    {/*</Header>*/}
                    <Top>
                        <h1>Award #{award.Id || " -"}</h1>

                        <IconButton onClick={getQr}><ShareIconSvg/></IconButton>
                        {/*<IconButton onClick={() => History.push(-1)}><BackSvg/></IconButton>*/}
                    </Top>
                    <MobileDescription>
                        {award.Meta?.description}
                    </MobileDescription>
                    <b>Presenter</b>
                    <Who>
                        {award?.Producer?.Login && <img src={`/${award?.Producer?.Photo}`} alt="avatar"/>}
                        <Text>{award?.Group?.Name || award?.Producer?.Name || award?.Producer?.Login}</Text>
                        <Action>
                            <a target={"_blank"} rel={"noopener noreferrer nofollow"}
                               href={award?.Group?.Id ? `/public/company/${award?.Group?.Id}` : `/public/profile/${award?.Producer?.Login}`}><ViewSvg
                                style={{cursor: "pointer"}}
                                onClick={() => History.push("")}/>
                                View</a>
                        </Action>
                    </Who>
                    <h2>Details</h2>
                    <b>Token</b>
                    <TokenId>
                        <Text>{clip(award.TokenId, 16)}</Text>
                        <Action onClick={() => {
                            let blob = new Blob([award.TokenId], {type: "text/plain"});
                            let data = [new ClipboardItem({["text/plain"]: blob})];

                            navigator.clipboard.write(data).then(() => {
                                message.success("Copied!")
                            }).catch(() => {
                                message.error(`Can't Copied :c, Your secret: ${award.TokenId}`)
                            });
                        }}>
                            <StyledCopySvg/>
                            Copy
                        </Action>
                        <Action>
                            <a target={"_blank"} rel={"noopener noreferrer nofollow"}
                               href={award.TokenLink}><ViewSvg
                                style={{cursor: "pointer"}}
                                onClick={() => History.push("")}/>
                                View</a>
                        </Action>
                    </TokenId>
                    <b>Type</b>
                    <Type>
                        <Text>{award.TokenType}</Text>
                    </Type>
                    <b>Transfer</b>
                    <Type>
                        <Text>{clip(award.Transaction, 16)}</Text>
                        <Action onClick={() => {
                            let blob = new Blob([award.Transaction], {type: "text/plain"});
                            let data = [new ClipboardItem({["text/plain"]: blob})];

                            navigator.clipboard.write(data).then(() => {
                                message.success("Copied!")
                            }).catch(() => {
                                message.error(`Can't Copied :c, Your secret: ${award.Transaction}`)
                            });
                        }}>
                            <StyledCopySvg/>
                            Copy
                        </Action>
                        <Action>
                            <a target={"_blank"} rel={"noopener noreferrer nofollow"}
                               href={award.TransactionLink}><ViewSvg
                                style={{cursor: "pointer"}}
                                onClick={() => History.push("")}/>
                                View</a>
                        </Action>
                    </Type>
                    <b>Created</b>
                    <BlockWrap>
                        <Created>
                            <CalendarSvg/><Text>{moment(award.Created).format("DD.MM.YYYY")}</Text>
                        </Created>
                        <Created>
                            <TimeSvg/><Text>{moment(award.Created).format("HH:mm:ss")}</Text>
                        </Created>
                    </BlockWrap>
                </AwardInfo>
            </Content>
        </StyledCreateAward>
    );
}

export default PublicAward;