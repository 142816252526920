import React from 'react';
import styled from "styled-components";
import CameraSvg from "../images/CameraSvg";
import {Upload} from "antd";
import SettingsContent from "../components/SettingsContent";

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 42px;
`;

const Avatar = styled.div`
  width: 184px;
  height: 184px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 18px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Info = styled.div`
  .ant-upload {
    display: flex;
    align-items: center;
    color: #7b7b7b;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }
`;

const Title = styled.div`
  color: #262626;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 8px;
`;

const StyledSettings = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 45px 60px 45px 60px;

  h1 {
    color: #262626;
    font-size: 42px;
    line-height: 51px;
    font-weight: 800;
    margin-bottom: 50px;
  }
`;

function Settings() {
    return (
        <StyledSettings>
            <h1>Settings</h1>
            <Top>
                <Avatar>
                    <img
                        src={"https://sun9-21.userapi.com/impg/OCkY5kbSfHXnv6wosINFUNDaBZoKhzWzgI2wqw/KSsW3zXjld4.jpg?size=2560x1440&quality=96&sign=f4af1220dde4376057329b43497b6bfe&type=album"}
                        alt=""/>
                </Avatar>
                <Info>
                    <Title>Techen Group</Title>
                    <Upload>
                        <CameraSvg/> Upload photo
                    </Upload>
                </Info>
            </Top>
            <SettingsContent/>
        </StyledSettings>
    );
}

export default Settings;