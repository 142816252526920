import React, {useRef} from 'react';
import {useDrag, useDrop} from "react-dnd";
import styled from "styled-components";

const StyledElementItem = styled.div`
  background: #FFFFFF;
  border: 1px solid #DDE5FA;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  cursor: move;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    flex: 0 0 auto;

    &:first-child {
      margin-right: 10px;
    }
  }
`;
type ElementItemProps = {
    children: any,
    index: number,
    moveItem: any,
    onDrop: any,
}


function ElementItem({children, index, moveItem, onDrop}: ElementItemProps) {

    const ref = useRef<any>(null);

    const [{handlerId}, drop] = useDrop({
        accept: "item",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item: any, monitor: any) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        // hover(item: any, monitor: any) {
        //     // if (!ref.current) {
        //     //     return;
        //     // }
        //     // const dragIndex = item.index;
        //     // const hoverIndex = index;
        //     // // Don't replace items with themselves
        //     // if (dragIndex === hoverIndex) {
        //     //     return;
        //     // }
        //     // // Determine rectangle on screen
        //     // const hoverBoundingRect = ref.current?.getBoundingClientRect();
        //     // // Get vertical middle
        //     // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        //     // // Determine mouse position
        //     // const clientOffset = monitor.getClientOffset();
        //     // // Get pixels to the top
        //     // const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        //     // // Only perform the move when the mouse has crossed half of the items height
        //     // // When dragging downwards, only move when the cursor is below 50%
        //     // // When dragging upwards, only move when the cursor is above 50%
        //     // // Dragging downwards
        //     // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        //     //     return;
        //     // }
        //     // // Dragging upwards
        //     // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        //     //     return;
        //     // }
        //     // // Time to actually perform the action
        //     // moveItem(dragIndex, hoverIndex);
        //     // // Note: we're mutating the monitor item here!
        //     // // Generally it's better to avoid mutations,
        //     // // but it's good here for the sake of performance
        //     // // to avoid expensive index searches.
        //     // item.index = hoverIndex;
        // },
    });
    const [{isDragging}, drag] = useDrag(() => ({
        type: "item",
        item: () => {
            return {children, index};
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))
    drag(drop(ref));
    return (
        <StyledElementItem ref={ref}>{children}</StyledElementItem>
    );
}

export default ElementItem;