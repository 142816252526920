import React from 'react';

function ApplySvg(props: any) {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10L9 13L14 7" stroke="#677FBE" strokeWidth="1.2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
}

export default ApplySvg;