import React from 'react';

function EyeSvg(props: any) {
    return (
        <svg {...props} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6 6.75C6 4.67893 7.67893 3 9.75 3C11.8211 3 13.5 4.67893 13.5 6.75C13.5 8.82107 11.8211 10.5 9.75 10.5C7.67893 10.5 6 8.82107 6 6.75ZM9.75 4.5C8.50736 4.5 7.5 5.50736 7.5 6.75C7.5 7.99264 8.50736 9 9.75 9C10.9926 9 12 7.99264 12 6.75C12 5.50736 10.9926 4.5 9.75 4.5Z"
                  fill="#2361FF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.07343 5.39635C1.65431 6.00029 1.5 6.4727 1.5 6.75C1.5 7.0273 1.65431 7.49971 2.07343 8.10365C2.47857 8.68744 3.08078 9.32028 3.84267 9.90503C5.36978 11.0771 7.46345 12 9.75 12C12.0365 12 14.1302 11.0771 15.6573 9.90503C16.4192 9.32028 17.0214 8.68744 17.4266 8.10365C17.8457 7.49971 18 7.0273 18 6.75C18 6.4727 17.8457 6.00029 17.4266 5.39635C17.0214 4.81256 16.4192 4.17972 15.6573 3.59497C14.1302 2.42292 12.0365 1.5 9.75 1.5C7.46345 1.5 5.36978 2.42292 3.84267 3.59497C3.08078 4.17972 2.47857 4.81256 2.07343 5.39635ZM2.92941 2.40503C4.65965 1.07708 7.06598 0 9.75 0C12.434 0 14.8403 1.07708 16.5706 2.40503C17.4374 3.07028 18.1532 3.81244 18.6589 4.54115C19.1506 5.24971 19.5 6.0273 19.5 6.75C19.5 7.4727 19.1506 8.25029 18.6589 8.95885C18.1532 9.68756 17.4374 10.4297 16.5706 11.095C14.8403 12.4229 12.434 13.5 9.75 13.5C7.06598 13.5 4.65965 12.4229 2.92941 11.095C2.06262 10.4297 1.34681 9.68756 0.841103 8.95885C0.349371 8.25029 0 7.4727 0 6.75C0 6.0273 0.349371 5.24971 0.841103 4.54115C1.34681 3.81244 2.06262 3.07028 2.92941 2.40503Z"
                  fill="#2361FF"/>
        </svg>
    );
}

export default EyeSvg;