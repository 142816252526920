import React from 'react';

function ArrowCircleSvg(props: any) {
    return (
        <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="13" fill="white" stroke="#C6CFE5" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.5303 11.9697C18.8232 12.2626 18.8232 12.7374 18.5303 13.0303L14.5303 17.0303C14.2374 17.3232 13.7626 17.3232 13.4697 17.0303L9.46967 13.0303C9.17678 12.7374 9.17678 12.2626 9.46967 11.9697C9.76256 11.6768 10.2374 11.6768 10.5303 11.9697L14 15.4393L17.4697 11.9697C17.7626 11.6768 18.2374 11.6768 18.5303 11.9697Z"
                  fill="#2361FF"/>
        </svg>

    );
}

export default ArrowCircleSvg;