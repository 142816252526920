import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Input, message, Upload} from "antd";
import BalanceSvg from "../images/BalanceSvg";
import CameraSvg from "../images/CameraSvg";
import LabeledInput, {StyledLabeledInput} from "../components/LabeledInput";
import LabeledTextarea, {StyledLabeledTextarea} from "../components/LabeledTextarea";
import Google2Svg from "../images/Google2Svg";
import PhoneSvg from "../images/PhoneSvg";
import MailSvg from "../images/MailSvg";
import Facebook2Svg from "../images/Facebook2Svg";
import {useSelector} from "react-redux";
import nouser from "../images/nouser.png";
import axios from "axios";
import {api} from "../config";
import store from "../redux/store";
import {refreshTokenAction} from "../redux/actions/loginActions";
import {StyledInput} from "../components/styled/StyledInput";
import {getAwards} from "../redux/actions/pageActions";
import EthereumSvg from "../images/EthereumSvg";

const Settings = styled.div`
  display: flex;

  ${StyledLabeledInput} {
    margin-bottom: 24px;
  }

  ${StyledLabeledTextarea}, textarea {
    height: 100%;
  }
`;

const SettingsBlock = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-right: 120px;
  }

`;

const Button = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const Left = styled.div`
  margin-right: 90px;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
`;

const AvatarWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-upload {
    .uploadText {
      margin-top: 10px;
      display: flex;
      align-items: center;
      color: #7b7b7b;
      font-weight: 500;
      cursor: pointer;
      justify-content: center;
    }

    svg {
      margin-right: 10px;
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 184px;
  height: 184px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 18px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #373737;
  padding: 5px 10px;
  background: #ffb400;
  border-radius: 26px;
  margin-bottom: 6px;
`;

const Title = styled.div`
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 4px;
`;

const Balance = styled.div`
  display: flex;
  align-items: center;
  color: #2361ff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  svg {
    margin-right: 5px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusInput = styled(StyledInput)`
  padding: 5px;
  border: none !important;
  background: transparent !important;
  font-size: 12px;
`;

const StyledUserSettings = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px 60px 45px 0;
  overflow: auto;

  h1 {
    color: #262626;
    font-size: 42px;
    font-weight: 800;
    line-height: 51px;
    margin-bottom: 46px;
  }

  h2 {
    color: #262626;
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
  }

  @media (max-width: 1100px) {
    padding: 45px 48px;

    ${Content} {
      ${Avatar} {
        width: 84px;
        height: 84px;
      }

      ${Left} {
        margin: 0;
      }

      ${AvatarWrap} {
        .uploadText {
          display: none;
        }
      }

      ${Text} {
        ${Title} {
          font-size: 24px;
          line-height: 29px;
        }

        ${Balance} {
          font-size: 10px;
          line-height: 12px;
        }
      }

      ${Settings} {
        flex-direction: column;
      }
    }
  }
`;

function UserSettings() {

    const userData = useSelector(({loginReducer: {userData}}: any) => userData);
    const awards = useSelector(({pageReducer: {awards}}: any) => awards);

    const [editActive, setEditActive] = useState(false);

    const editProfile = (name: string, value: any) => {

        if (userData[name] !== value)
            axios.post(`${api}/user`, {
                [name]: value
            }).then(() => {
                store.dispatch(refreshTokenAction());
                message.success("Profile saved successfully!");
            }).catch(({response}) => {
                message.error(response?.data || "Error")
            })
    }

    useEffect(() => {
        store.dispatch(getAwards());
    }, [])

    return (
        <StyledUserSettings>
            <h1>Settings</h1>
            <Content>
                <Left>
                    <UserInfo>
                        <Info>
                            <AvatarWrap>
                                <Upload showUploadList={false} customRequest={(_: any) => {
                                    axios.put(`${api}/user/photo`, _.file).then(() => {
                                        store.dispatch(refreshTokenAction());
                                    })
                                }}>
                                    <Avatar>
                                        <img
                                            src={userData.Photo ? `/${userData.Photo}` : nouser}
                                            alt="avatar"/>
                                    </Avatar>
                                    <span className={"uploadText"}><CameraSvg/> Upload photo</span>
                                </Upload>
                            </AvatarWrap>
                            <Text>
                                <Status onClick={() => !editActive && setEditActive(true)}>{editActive ?
                                    <StatusInput defaultValue={userData.Bio || "Enter your bio here ✏️"}
                                                 onBlur={({target: {value}}: any) => {
                                                     editProfile("Bio", value.trim())
                                                     setEditActive(false);
                                                 }}/> : (userData.Bio || "Enter your bio here ✏️")}</Status>
                                <Title>{userData.Name || userData.Login}</Title>
                                <Balance><BalanceSvg/> {awards?.length} Awards</Balance>
                            </Text>
                        </Info>
                    </UserInfo>
                </Left>
                <Settings>
                    <SettingsBlock>
                        <LabeledInput onBlur={({target: {value}}: any) => editProfile("Name", value.trim())}
                                      defaultValue={userData.Name} label={"My name"}/>
                        <LabeledInput onBlur={({target: {value}}: any) => editProfile("Phone", value.trim())}
                                      defaultValue={userData.Phone} prefix={<PhoneSvg/>} label={"Phone"}/>
                        <LabeledInput onBlur={({target: {value}}: any) => editProfile("Email", value.trim())}
                                      defaultValue={userData.Email} prefix={<MailSvg/>} label={"E-mail"}/>
                        <LabeledInput onBlur={({target: {value}}: any) => editProfile("EtherWallet", value.trim())}
                                      defaultValue={userData.EtherWallet} prefix={<EthereumSvg/>}
                                      label={"Ethereum wallet"}/>
                    </SettingsBlock>
                    <SettingsBlock>
                        <LabeledTextarea
                            defaultValue={userData.AboutMe}
                            onBlur={({target: {value}}: any) => editProfile("AboutMe", value.trim())}
                            label={"About me"}/>
                        {/*<LabeledInput disabled defaultValue={"https://www.facebook.com/zuck"} suffix={<Facebook2Svg/>}*/}
                        {/*              label={"Facebook"}/>*/}
                        {/*<Button><Google2Svg/> Connect</Button>*/}
                    </SettingsBlock>
                </Settings>
            </Content>
        </StyledUserSettings>
    );
}

export default UserSettings;