import React from 'react';

function QuotesSvg(props: any) {
    return (
        <svg {...props} width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.2246 12.3387L12.4332 0H6.01604L1.76471 11.4516C0.320856 15.1613 0 16.5323 0 18.3871C0 22.2581 2.72727 25 6.57754 25C10.1872 25 12.9947 22.3387 12.9947 18.3871C12.9947 15.4032 11.5508 13.3065 9.2246 12.3387ZM26.2299 12.3387L29.4385 0H23.0214L18.7701 11.4516C17.3262 15.1613 17.0053 16.5323 17.0053 18.3871C17.0053 22.2581 19.7326 25 23.5829 25C27.1925 25 30 22.3387 30 18.3871C30 15.4032 28.5562 13.3065 26.2299 12.3387Z"
                fill="#B3C8FF"/>
        </svg>
    );
}

export default QuotesSvg;