import React from 'react';
import styled from "styled-components";
import AwardsLogo from "../images/AwardsLogo";
import NavList from "./NavList";
import BurgerSvg from "../images/BurgerSvg";
import History from "../utils/history";
import {useSelector} from "react-redux";

const Logo = styled(AwardsLogo)`
  border-right: 1px solid #D3D3D3;
  padding-right: 100px;
  margin-right: 100px;
  width: auto;
`;

export const NavItemList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.div`
  border: 2px solid #BCC6E0;
  border-radius: 9px;
  padding: 18px 55px;
  color: #393939;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  line-height: 24px;

  &:hover {
    opacity: .6;
  }

  &:active {
    opacity: .8;
  }
`;


export const StyledBurger = styled(BurgerSvg)`
  display: none;
  margin-left: auto;
  cursor: pointer;
`;

export const StyledNavigation = styled.div`
  border-bottom: 1px solid #D3D3D3;
  display: flex;
  align-items: center;
  padding-bottom: 28px;

  @media (max-width: 1480px) {
    ${Logo} {
      margin-right: auto;
    }

    ${StyledBurger} {
      display: flex;
    }

    ${NavItemList} {
      display: none;
    }
  }

`;

function Navigation({className = "", onBurgerClick}: any) {

    const userData = useSelector(({loginReducer: {userData}}: any) => userData);

    return (
        <StyledNavigation className={className}>
            <Logo/>
            <NavItemList>
                <NavList/>
                <Button onClick={() => userData.Login ? History.push("/home") : History.push("/signup")}>To try</Button>
            </NavItemList>
            <StyledBurger onClick={onBurgerClick}/>
        </StyledNavigation>
    );
}

export default Navigation;