import React from 'react';

function FacebookSvg(props:any) {
    return (
        <svg {...props} width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42.6175" height="42.6175" rx="8" fill="#425993"/>
            <path
                d="M23.7711 16.0367H25.2549V13.527C24.5365 13.4523 23.8147 13.4154 23.0924 13.4165C20.9458 13.4165 19.4778 14.7266 19.4778 17.1258V19.1936H17.0549V22.0032H19.4778V29.2008H22.3821V22.0032H24.7971L25.1602 19.1936H22.3821V17.4021C22.3821 16.5734 22.6031 16.0367 23.7711 16.0367Z"
                fill="white"/>
        </svg>
    );
}

export default FacebookSvg;