import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import BalanceSvg from "../images/BalanceSvg";
import PhoneSvg from "../images/PhoneSvg";
import MailSvg from "../images/MailSvg";
import AwardsLogo from "../images/AwardsLogo";
import {useParams} from "react-router-dom";
import axios from "axios";
import {api} from "../config";
import History from "../utils/history";
import nouser from "../images/nouser.png";

const ProgressContent = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #4b4b4b;
`;

const Value = styled.div`
  color: #2361ff;
  font-size: 42px;
  line-height: 51px;
  font-weight: 800;
`;

const Right = styled.div`
  display: flex;
  overflow: hidden;
`;

const Awards = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

const AwardsTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-left: auto;
  }

  h2 {
    margin: 0;
  }
`;

const Count = styled.div`
  color: #a0a0a0;
  margin-left: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
`;

const Image = styled.div`
  border-radius: 21px;
  overflow: hidden;
  cursor: pointer;
`;

const AwardsShowcase = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  padding: 24px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  display: flex;
  gap: 20px 20px;
  flex-wrap: wrap;

  div {
    img {
      max-width: 200px;
      max-height: 200px;
      height: 100%;
      width: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }

  .test1 {
    grid-area: test1;
  }

  .test2 {
    grid-area: test2;
  }

  .test3 {
    grid-area: test3;
  }

  .test4 {
    grid-area: test4;
  }

  .test5 {
    grid-area: test5;
  }

  .test6 {
    grid-area: test6;
  }

  .test7 {
    grid-area: test7;
  }
`;
const Left = styled.div`
  margin-right: 90px;
  max-width: 500px;
  width: 100%;
  overflow: auto;

  background: #FFFFFF;
  border: 1px solid #E0E9FF;
  border-radius: 12px;
  padding: 50px 30px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 124px;
  height: 124px;
  min-height: 124px;
  min-width: 124px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 18px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const About = styled.div`
  margin-top: 28px;
  margin-bottom: 50px;
`;

const AboutText = styled.div`
  color: #474747;
  font-size: 16px;
`;

const CompanyItem = styled.div`
  width: 106px;
  height: 106px;
  border-radius: 100%;
  overflow: hidden;


  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Companies = styled.div`
  display: flex;
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  padding: 38px 20px;
  justify-content: center;

  ${CompanyItem} {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

const Company = styled.div`

`;

const Socials = styled.div`
  display: flex;
  align-items: center;

  svg {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  color: #7b7b7b;
  line-height: 19px;
  font-weight: 500;
  font-size: 16px;
  word-wrap: anywhere;

  svg {
    margin-right: 16px;
    flex: 0 0 auto;
  }
`;

const Contacts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ContactItem} {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
`;


const Contact = styled.div`
  margin-top: 50px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #373737;
  padding: 5px 10px;
  background: #ffb400;
  border-radius: 26px;
  margin-bottom: 6px;
`;

const Title = styled.div`
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 4px;
  word-wrap: anywhere;
`;

const Balance = styled.div`
  display: flex;
  align-items: center;
  color: #2361ff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  svg {
    margin-right: 5px;
  }
`;

const Best = styled.div`
`;

const BestAward = styled.div`
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  padding: 20px 25px;
  margin-bottom: 85px;


  img {
    height: 100%;
  }
`;

const Center = styled.div`
  margin-right: 42px;
`;

const Content = styled.div`
  display: flex;
  overflow: hidden;

  ${Right} {
    width: 100%;
  }

  ${Center} {
    width: 30%;
  }

  ${Left} {

  }
`;

const StyledShowcaseHome = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px 60px;

  @media (max-width: 1100px) {
    overflow: auto;
    padding: 45px 48px;

    ${Content} {
      flex-direction: column;
      overflow: visible;

      ${Left} {
        max-width: none;
        margin-bottom: 60px;

        ${UserInfo} {
          ${Info} {
            ${Avatar} {
              width: 82px;
              height: 82px;
              min-width: 82px;
              min-height: 82px;
            }

            ${Text} {
              ${Title} {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }

  > svg {
    margin-bottom: 52px;
    max-height: 44px;
    flex: 1 0 auto;
    cursor: pointer;
  }

  h2 {
    color: #262626;
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
  }

  @media (max-width: 500px) {
    padding: 45px 28px;

    ${Content} {
      ${Left} {
        ${UserInfo} {
          ${Info} {
            flex-direction: column;
            justify-content: center;
            width: 100%;

            ${Avatar} {
              margin-bottom: 20px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

function UserHome() {

    const {userLogin} = useParams();

    const [userInfo, setUserInfo] = useState<any>({});

    const getUserInfo = () => {
        axios(`${api}/public/profile/${userLogin}`).then(({data}) => {
            setUserInfo(data);
        })
    }

    useEffect(() => {
        getUserInfo();
    }, [])

    return (
        <StyledShowcaseHome>
            <AwardsLogo onClick={() => History.push("/home")}/>
            <Content>
                <Left>
                    <UserInfo>
                        <Info>
                            <Avatar>
                                <img
                                    src={userInfo.Photo ? `/${userInfo.Photo}` : nouser}
                                    alt=""/>
                            </Avatar>
                            <Text>
                                <Status>{userInfo.Bio}</Status>
                                <Title>{userInfo.Name || userInfo.Login}</Title>
                                <Balance><BalanceSvg/> {userInfo.Tokens?.length || 0} Awards</Balance>
                            </Text>
                        </Info>
                        {/*<Progress trailColor={"#A4BDFF"} strokeColor={"#2361FF"} type="circle" percent={15}*/}
                        {/*          format={(value) =>*/}
                        {/*              <ProgressContent>*/}
                        {/*                  <Value>{value}</Value>*/}
                        {/*                  <div>Level</div>*/}
                        {/*              </ProgressContent>}/>*/}
                    </UserInfo>
                    <About>
                        <h2>About me</h2>
                        <AboutText>
                            {userInfo.AboutMe}
                        </AboutText>
                    </About>
                    <Contact>
                        <h2>Contact</h2>
                        <Contacts>
                            <div>
                                <ContactItem><PhoneSvg/> {userInfo.Phone || "-"}</ContactItem>
                                <ContactItem><MailSvg/> {userInfo.Email || "-"}</ContactItem>
                            </div>
                            {/*<Socials>*/}
                            {/*    <FacebookSvg/>*/}
                            {/*    <GoogleSvg/>*/}
                            {/*    <FacebookSvg/>*/}
                            {/*    <GoogleSvg/>*/}
                            {/*</Socials>*/}
                        </Contacts>
                    </Contact>
                </Left>
                {/*<Center>*/}
                {/*    /!*<Best>*!/*/}
                {/*    /!*    <h2>Best award</h2>*!/*/}
                {/*    /!*    <BestAward>*!/*/}
                {/*    /!*        <img src={award2} alt=""/>*!/*/}
                {/*    /!*    </BestAward>*!/*/}
                {/*    /!*</Best>*!/*/}
                {/*    /!*<Company>*!/*/}
                {/*    /!*    <h2>Company</h2>*!/*/}
                {/*    /!*    <Companies>*!/*/}
                {/*    /!*        <CompanyItem>*!/*/}
                {/*    /!*            <img*!/*/}
                {/*    /!*                src={"https://sun9-21.userapi.com/impg/OCkY5kbSfHXnv6wosINFUNDaBZoKhzWzgI2wqw/KSsW3zXjld4.jpg?size=2560x1440&quality=96&sign=f4af1220dde4376057329b43497b6bfe&type=album"}*!/*/}
                {/*    /!*                alt=""/>*!/*/}
                {/*    /!*        </CompanyItem>*!/*/}
                {/*    /!*        <CompanyItem>*!/*/}
                {/*    /!*            <img*!/*/}
                {/*    /!*                src={"https://sun9-21.userapi.com/impg/OCkY5kbSfHXnv6wosINFUNDaBZoKhzWzgI2wqw/KSsW3zXjld4.jpg?size=2560x1440&quality=96&sign=f4af1220dde4376057329b43497b6bfe&type=album"}*!/*/}
                {/*    /!*                alt=""/>*!/*/}
                {/*    /!*        </CompanyItem>*!/*/}
                {/*    /!*        <CompanyItem>*!/*/}
                {/*    /!*            <img*!/*/}
                {/*    /!*                src={"https://sun9-21.userapi.com/impg/OCkY5kbSfHXnv6wosINFUNDaBZoKhzWzgI2wqw/KSsW3zXjld4.jpg?size=2560x1440&quality=96&sign=f4af1220dde4376057329b43497b6bfe&type=album"}*!/*/}
                {/*    /!*                alt=""/>*!/*/}
                {/*    /!*        </CompanyItem>*!/*/}
                {/*    /!*    </Companies>*!/*/}
                {/*    /!*</Company>*!/*/}
                {/*</Center>*/}
                <Right>
                    <Awards>
                        <AwardsTop><h2>Awards</h2> <Count>{userInfo.Tokens?.length || 0}</Count></AwardsTop>
                        <AwardsShowcase>
                            {userInfo.Tokens && userInfo.Tokens?.map((token: any) =>
                                <a target={"_blank"} rel={"noopener noreferrer nofollow"}
                                   href={`/public/award/${token.Id}`}><Image
                                    style={{background: token.Meta?.background_color}}><img src={token.Meta?.image}
                                                                                            alt=""/>
                                </Image></a>)}
                            {/*<div className="test2"><img src={award2} alt=""/></div>*/}
                            {/*<div className="test3"><img src={award2} alt=""/></div>*/}
                            {/*<div className="test4"><img src={award3} alt=""/></div>*/}
                            {/*<div className="test5"><img src={award2} alt=""/></div>*/}
                            {/*<div className="test6"><img src={award2} alt=""/></div>*/}
                            {/*<div className="test7"><img src={award2} alt=""/></div>*/}
                        </AwardsShowcase>
                    </Awards>
                </Right>
            </Content>
        </StyledShowcaseHome>
    );
}

export default UserHome;