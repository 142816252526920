import React from 'react';

function AppleSvg(props: any) {
    return (
        <svg {...props} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="54" height="54" rx="10.125" fill="#09101D"/>
            <path
                d="M29.9399 20.19C30.318 19.7543 30.6062 19.2482 30.7881 18.7007C30.97 18.1533 31.042 17.5753 30.9999 17C29.8393 17.0937 28.7619 17.6396 27.9999 18.52C27.635 18.942 27.3585 19.4329 27.1867 19.9636C27.015 20.4944 26.9514 21.0542 26.9999 21.61C27.5661 21.6147 28.1257 21.4891 28.6355 21.2429C29.1453 20.9966 29.5916 20.6364 29.9399 20.19ZM32.4599 27.63C32.4666 26.8637 32.6685 26.1118 33.0464 25.4452C33.4243 24.7786 33.9659 24.2193 34.6199 23.82C34.2071 23.2252 33.6615 22.7348 33.0262 22.3877C32.3909 22.0405 31.6834 21.8462 30.9599 21.82C29.3999 21.66 27.9599 22.73 27.1299 22.73C26.2999 22.73 25.1299 21.84 23.8299 21.86C22.98 21.888 22.1519 22.1358 21.4264 22.5792C20.7008 23.0226 20.1025 23.6465 19.6899 24.39C17.9299 27.45 19.2399 32 20.9999 34.47C21.7999 35.68 22.7999 37.05 24.1199 37C25.4399 36.95 25.8699 36.18 27.3999 36.18C28.9299 36.18 29.3999 37 30.6999 36.97C31.9999 36.94 32.9199 35.73 33.7599 34.52C34.355 33.6415 34.8198 32.6816 35.1399 31.67C34.3474 31.332 33.6712 30.7693 33.195 30.0513C32.7187 29.3333 32.4631 28.4916 32.4599 27.63Z"
                fill="white"/>
        </svg>
    );
}

export default AppleSvg;