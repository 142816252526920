import React from 'react';

function BalloonSvg(props: any) {
    return (
        <svg {...props} width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.25003 9C4.25003 6.37665 6.37667 4.25 9.00002 4.25C11.6234 4.25 13.75 6.37665 13.75 9C13.75 11.6234 11.6234 13.75 9.00002 13.75C6.37667 13.75 4.25003 11.6234 4.25003 9ZM9.00002 5.75C7.2051 5.75 5.75003 7.20507 5.75003 9C5.75003 10.7949 7.2051 12.25 9.00002 12.25C10.795 12.25 12.25 10.7949 12.25 9C12.25 7.20507 10.795 5.75 9.00002 5.75Z"
                  fill="#2361FF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.524388 7.85685C0.878716 3.55824 4.47087 0.25 8.78406 0.25H9.216C13.5292 0.25 17.1213 3.55824 17.4757 7.85685C17.666 10.166 16.9527 12.4589 15.4861 14.2526L10.693 20.1144C9.818 21.1845 8.18205 21.1845 7.30705 20.1144L2.51399 14.2526C1.04733 12.4589 0.334049 10.166 0.524388 7.85685ZM8.78406 1.75C5.25152 1.75 2.30952 4.45948 2.01932 7.98008C1.8609 9.90192 2.45455 11.8102 3.67521 13.3031L8.46827 19.1649C8.7431 19.501 9.25695 19.501 9.53178 19.1649L14.3248 13.3031C15.5455 11.8102 16.1391 9.90192 15.9807 7.98008C15.6905 4.45948 12.7485 1.75 9.216 1.75H8.78406Z"
                  fill="#2361FF"/>
        </svg>
    );
}

export default BalloonSvg;