import React, {useEffect} from 'react';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import MainPage from "./pages/MainPage";
import SignIn from "./pages/SignIn";
import MainLayout from "./pages/MainLayout";
import Home from "./pages/Home";
import Shop from "./pages/Shop";
import ShopItem from "./pages/ShopItem";
import CompanyProfile from "./pages/CompanyProfile";
import Settings from "./pages/Settings";
import UserHome from "./pages/UserHome";
import UserShowcase from "./pages/UserShowcase";
import UserSettings from "./pages/UserSettings";
import History from "./utils/history";
import store from "./redux/store";
import {refreshTokenAction} from "./redux/actions/loginActions";
import CreateTemplate from "./pages/CreateTemplate";
import {useSelector} from "react-redux";
import MyCompany from "./pages/MyCompany";
import CreateAward from "./pages/CreateAward";
import OldAward from "./pages/OldAward";
import PublicAward from "./pages/PublicAward";
import SignUp from "./pages/SignUp";
import PublicCompany from "./pages/PublicCompany";
import Award from "./pages/Award";
import CreateCompany from "./pages/CreateCompany";
import EditCompany from "./pages/EditCompany";
import Company from "./pages/Company";

function App() {
    History.navigate = useNavigate();

    const loading = useSelector(({loginReducer: {loading}}: any) => loading);

    useEffect(() => {
        store.dispatch(refreshTokenAction())
    }, [])

    return (
        <Routes>
            {!loading &&
                <React.Fragment>
                    <Route path={"/home"} element={
                        <MainLayout>
                            <Home/>
                        </MainLayout>
                    }/>
                    <Route path={"/shop"} element={
                        <MainLayout noUsers>
                            <Shop/>
                        </MainLayout>
                    }/>
                    <Route path={"/company/:id"} element={
                        <MainLayout noUsers>
                            <Company/>
                        </MainLayout>
                    }/>
                    <Route path={"/company/create"} element={
                        <MainLayout noUsers>
                            <CreateCompany/>
                        </MainLayout>
                    }/>
                    <Route path={"/company/edit/:editId"} element={
                        <MainLayout noUsers>
                            <EditCompany/>
                        </MainLayout>
                    }/>
                    <Route path={"/settings"} element={
                        <MainLayout noUsers>
                            <Settings/>
                        </MainLayout>
                    }/>
                    <Route path={"/user/:userLogin"} element={
                        <MainLayout noUsers>
                            <UserHome/>
                        </MainLayout>
                    }/>
                    <Route path={"/user/profile"} element={
                        <MainLayout noUsers>
                            <UserSettings/>
                        </MainLayout>
                    }/>
                    <Route path={"/template/create"} element={
                        <MainLayout noUsers>
                            <CreateTemplate/>
                        </MainLayout>
                    }/>
                    <Route path={"/companies/my"} element={
                        <MainLayout noUsers>
                            <MyCompany/>
                        </MainLayout>
                    }/>
                    <Route path={"/award/:awardId"} element={
                        <MainLayout noUsers>
                            <Award/>
                        </MainLayout>
                    }/>
                    <Route path={"/award/create/:templateId"} element={
                        <MainLayout noUsers>
                            <CreateAward/>
                        </MainLayout>
                    }/>
                </React.Fragment>
            }
            <Route path={"/"} element={
                <MainPage/>
            }/>

            <Route path={"/public/award/:awardId"} element={
                <MainLayout noUsers noMenu>
                    <PublicAward/>
                </MainLayout>
            }/>
            <Route path={"/public/profile/:userLogin"} element={
                <MainLayout noUsers noMenu>
                    <UserShowcase/>
                </MainLayout>
            }/>
            <Route path={"/public/company/:publicId"} element={
                <MainLayout noMenu noUsers>
                    <PublicCompany/>
                </MainLayout>
            }/>
            <Route path={"/signin"} element={
                <SignIn/>
            }/>
            <Route path={"/signup"} element={
                <SignUp/>
            }/>
            <Route path="*" element={<div>
                <div>Route not found</div>
                <Link to={"/"}>To main page</Link>
            </div>}/>
        </Routes>
    );
}

export default App;