import React, {useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import nouser from "../images/nouser.png";
import BurgerSvg from "../images/BurgerSvg";
import MobileDashboardMenu from "./MobileDashboardMenu";
import CrossSvg from "../images/CrossSvg";

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b3c3ed;
  padding: 30px 0;
  width: 100%;
  position: relative;
`;

const Avatar = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 12px;
  flex: 0 0 auto;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const Greeting = styled.div`
  color: #757575;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 4px;
`;

const Title = styled.div`
  color: #262626;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
`;

const StyledBurger = styled(BurgerSvg)`
  cursor: pointer;
`;
const StyledCross = styled(CrossSvg)`

`;

export const StyledMobileNav = styled.div`
  /*TODO: Вернуть display: none;*/
  display: none;
  //display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0 48px;
  z-index: 999;

  @media (max-width: 1100px) {
    display: flex;
  }

  &.menu-opened {
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0 48px;
  }

  ${StyledBurger}, ${StyledCross} {
    margin-left: auto;
    cursor: pointer;
    flex: 0 0 auto;
    width: 42px;
  }
`;


function MobileNav() {
    const userData = useSelector(({loginReducer: {userData}}: any) => userData);
    const [menuOpened, setMenuOpened] = useState<boolean>(false);

    return (
        <StyledMobileNav className={menuOpened ? "menu-opened" : ""}>
            <UserInfo>
                <Avatar>
                    <img
                        src={userData.Photo ? `/${userData.Photo}` : nouser}
                        alt="avatar"/>
                </Avatar>
                <RightSide>
                    <Greeting>{userData.Greeting}</Greeting>
                    <Title>{userData.Name || userData.Login}</Title>
                </RightSide>
                {!menuOpened ? <StyledBurger onClick={() => setMenuOpened(true)} className={"burger"}/> :
                    <StyledCross onClick={() => setMenuOpened(false)} className={"cross"}/>}
            </UserInfo>
            {menuOpened && <MobileDashboardMenu callback={()=>setMenuOpened(false)}/>}
        </StyledMobileNav>
    );
}

export default MobileNav;