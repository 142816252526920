import axios from "axios";
import {api} from "../../config";
import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from "../actionTypes";
import {message} from "antd";
import History from "../../utils/history";

// import {getChats, getContacts} from "./pageActions";


export function loginAction({login, password}) {

    return (dispatch) => {
        axios(`${api}/auth?a123=${login}&a321=${password}`).then(({data}) => {
            // store.dispatch(getChats());
            // store.dispatch(getContacts());
            dispatch({type: LOGIN_SUCCESS, payload: data})
            History.push("/home")
        }).catch(({response: {data}}) => {
            message.error(data);
        })

    }
}

export function logoutAction() {
    return (dispatch) => {
        dispatch({type: LOGOUT_SUCCESS})
    }
}

export function registerAction({login, password}) {
    return (dispatch) => {
        axios.post(`${api}/reg`, {
            Login: login,
            Password: password
        }).then(({data}) => {
            // store.dispatch(getChats());
            // store.dispatch(getContacts());
            dispatch({type: LOGIN_SUCCESS, payload: data})
            History.push("/home")
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }
}

export function refreshTokenAction() {
    return (dispatch) => {
        axios(`${api}/user`).then(({data}) => {
            // store.dispatch(getChats());
            // store.dispatch(getContacts());
            dispatch({type: LOGIN_SUCCESS, payload: data})
            // history.location.pathname === "/login" && history.push("/");
        }).catch(err => {
            // if (history.location.pathname)
            if (!window.location.pathname.startsWith("/public/profile") && !window.location.pathname.startsWith("/public/award") && !window.location.pathname.startsWith("/public/company") && !window.location.pathname.startsWith("/signup") && !window.location.pathname.startsWith("/signin"))
                History.push("/")
            console.log(err);
            // history.push("/login")
        })
    }
}