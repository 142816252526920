import React from 'react';

function CameraSvg(props: any) {
    return (
        <svg {...props} width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.25003 9.05017C6.25003 6.9791 7.92897 5.30017 10 5.30017C12.0711 5.30017 13.75 6.9791 13.75 9.05017C13.75 11.1212 12.0711 12.8002 10 12.8002C7.92897 12.8002 6.25003 11.1212 6.25003 9.05017ZM10 6.80017C8.75739 6.80017 7.75003 7.80753 7.75003 9.05017C7.75003 10.2928 8.75739 11.3002 10 11.3002C11.2427 11.3002 12.25 10.2928 12.25 9.05017C12.25 7.80753 11.2427 6.80017 10 6.80017Z"
                  fill="#2361FF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.61617 1.80017C7.571 1.80017 6.72372 2.64745 6.72372 3.69262C6.72372 4.35858 6.2125 4.91301 5.54872 4.96691L3.31912 5.14799C2.88135 5.18354 2.52132 5.50726 2.43961 5.93881C1.99087 8.30881 1.95741 10.7388 2.34072 13.1202L2.43797 13.7244C2.52982 14.295 2.99945 14.7292 3.57551 14.7759L5.51783 14.9337C8.50106 15.176 11.499 15.176 14.4822 14.9337L16.4246 14.7759C17.0006 14.7292 17.4703 14.295 17.5621 13.7244L17.6593 13.1202C18.0427 10.7388 18.0092 8.30881 17.5605 5.93881C17.4787 5.50726 17.1187 5.18354 16.681 5.14799L14.4514 4.96691C13.7876 4.91301 13.2764 4.35858 13.2764 3.69262C13.2764 2.64745 12.4291 1.80017 11.3839 1.80017H8.61617ZM5.2298 3.48788C5.33565 1.70961 6.81132 0.300171 8.61617 0.300171H11.3839C13.1888 0.300171 14.6644 1.70961 14.7703 3.48788L16.8024 3.65291C17.9133 3.74313 18.8269 4.56464 19.0343 5.65975C19.5153 8.20052 19.5512 10.8056 19.1403 13.3586L19.043 13.9628C18.8414 15.2154 17.8105 16.1683 16.546 16.271L14.6037 16.4288C11.5396 16.6776 8.46045 16.6776 5.39641 16.4288L3.45409 16.271C2.18956 16.1683 1.15865 15.2154 0.957035 13.9628L0.859784 13.3586C0.448851 10.8056 0.484722 8.20052 0.965797 5.65975C1.17315 4.56463 2.08678 3.74313 3.1977 3.65291L5.2298 3.48788Z"
                  fill="#2361FF"/>
        </svg>
    );
}

export default CameraSvg;