import React from 'react';

function BellSvg(props:any) {
    return (
        <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8414 1.85517C10.2707 1.62571 9.64871 1.5 8.99999 1.5C6.48295 1.5 4.36842 3.39259 4.09046 5.89424L3.90161 7.5939L3.89707 7.63444C3.81245 8.37186 3.57242 9.08296 3.19282 9.72081L3.17187 9.75581L2.73834 10.4784L2.72024 10.5085L2.72023 10.5085C2.53909 10.8104 2.37498 11.0839 2.26566 11.316C2.15457 11.5518 2.03579 11.8714 2.09297 12.2338C2.14948 12.5919 2.33381 12.9175 2.61186 13.1502C2.89316 13.3857 3.22832 13.4483 3.48773 13.4744C3.74301 13.5001 4.06194 13.5 4.41397 13.5L4.44917 13.5H13.5508L13.586 13.5C13.938 13.5 14.257 13.5001 14.5123 13.4744C14.7717 13.4483 15.1068 13.3857 15.3881 13.1502C15.6662 12.9175 15.8505 12.5919 15.907 12.2338C15.9642 11.8714 15.8454 11.5518 15.7343 11.316C15.625 11.0838 15.4609 10.8104 15.2798 10.5085L15.2797 10.5085L15.2616 10.4784L14.8281 9.75581L14.8072 9.72081C14.5231 9.2435 14.3172 8.72518 14.1961 8.1855C13.9705 8.22787 13.7377 8.25002 13.4998 8.25002C13.2119 8.25002 12.9316 8.21758 12.6623 8.15615C12.8007 8.97865 13.0906 9.76943 13.5182 10.4879L13.5419 10.5276L13.9754 11.2501C14.1805 11.592 14.3042 11.7998 14.3773 11.9551L14.3883 11.979L14.3621 11.9819C14.1913 11.9991 13.9495 12 13.5508 12H4.44917C4.05047 12 3.80869 11.9991 3.63788 11.9819L3.61166 11.979L3.62269 11.9551C3.69584 11.7998 3.81945 11.592 4.02458 11.2501L4.45811 10.5276L4.48183 10.4879C4.96989 9.66783 5.2785 8.75356 5.38729 7.80545L5.39244 7.75955L5.58129 6.05989C5.77484 4.31789 7.24728 3 8.99999 3C9.3472 3 9.68341 3.05172 10.0009 3.1482C10.1898 2.65941 10.4783 2.22012 10.8414 1.85517ZM12.7365 3.20847C12.2955 3.46967 11.9998 3.95031 11.9998 4.50002C11.9998 4.60858 12.0113 4.71444 12.0332 4.81645C12.1238 4.98546 12.2007 5.16318 12.2625 5.34822C12.5328 5.74186 12.9862 6.00002 13.4998 6.00002C13.6438 6.00002 13.783 5.97974 13.9148 5.94187L13.9095 5.89424C13.7953 4.86653 13.3712 3.94162 12.7365 3.20847Z" fill="#2361FF"/>
            <path d="M6.82667 13.2485C6.95486 13.9662 7.23734 14.6003 7.63029 15.0526C8.02324 15.5048 8.5047 15.75 9 15.75C9.4953 15.75 9.97676 15.5048 10.3697 15.0526C10.7627 14.6003 11.0451 13.9662 11.1733 13.2485" stroke="#2361FF" strokeWidth="1.5" strokeLinecap="round"/>
            <circle cx="13.5" cy="4.5" r="1.875" fill="#2361FF" stroke="#2361FF" strokeWidth="0.75"/>
        </svg>
    );
}

export default BellSvg;