import React from 'react';
import styled from "styled-components";
import Navigation, {StyledBurger, StyledNavigation} from "./Navigation";
import MailSvg from "../images/MailSvg";
import FacebookSvg from "../images/FacebookSvg";
import GoogleSvg from "../images/GoogleSvg";
import moment from 'moment';
import PhoneSvg from "../images/PhoneSvg";
import NavList, {LinkA, NavItem} from "./NavList";

const Copyright = styled.div`
  font-size: 14px;
  color: #828282;
`;

const Additional = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  color: #7b7b7b;
  font-weight: 500;
  font-size: 18px;
  word-break: break-all;

  svg {
    margin-right: 15px;
  }
`;

const Contacts = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${Contact} {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;

const Social = styled.div`
`;

const Socials = styled.div`
  display: flex;
  margin-left: 55px;

  ${Social} {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

`;

const NavListWrap = styled.div`
  display: none;
  flex-direction: column;
  padding-top: 25px;

  ${NavItem}, ${LinkA} {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
`;

const StyledFooter = styled.div`
  padding: 40px 220px;
  background: #f5f8fe;

  @media (max-width: 1480px) {
    padding: 40px 80px;
    ${NavListWrap} {
      display: flex;
    }

    ${StyledNavigation} {


      svg {
        border-right: none;
      }
    }

    ${Copyright} {
      margin-top: 12px;
    }

    ${Additional} {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 32px;
    }

    ${Socials} {
      margin: 0 0 32px;
    }

    ${Contacts} {
      flex-direction: column;
      align-items: flex-start;
      margin: 0;

      ${Contact} {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
  @media (max-width: 1480px) {
    padding: 40px 30px;
  }
`;


function Footer({onBurgerClick}:any) {
    return (
        <StyledFooter>
            <Navigation onBurgerClick={onBurgerClick}/>
            <NavListWrap>
                <NavList/>
            </NavListWrap>
            <Additional>
                <Copyright>
                    N-Awards copyright. 2021-{moment().year()}
                </Copyright>
                <Contacts id={"contacts"}>
                    <Contact>
                        <MailSvg/>
                        nawards@vaicom.tech
                    </Contact>
                    <Contact>
                        <PhoneSvg/>
                        +972 54-312-6546
                    </Contact>
                </Contacts>
                <Socials>
                    <Social><FacebookSvg/></Social>
                    <Social><GoogleSvg/></Social>
                    <Social><FacebookSvg/></Social>
                    <Social><GoogleSvg/></Social>
                </Socials>
            </Additional>
        </StyledFooter>
    );
}

export default Footer;