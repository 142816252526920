import React from 'react';

function ImageListSvg(props:any) {
    return (
        <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="13" fill="white" stroke="#C6CFE5" strokeWidth="2"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.3454 10.9968C7.33301 11.4823 7.33301 12.0347 7.33301 12.6666V13.9999H12.731C12.5092 13.8955 12.2665 13.8266 12.0099 13.8009L10.6663 13.6666C9.79076 13.579 9.35297 13.5352 8.99779 13.3912C8.2435 13.0854 7.67017 12.4519 7.44094 11.6709C7.38547 11.482 7.35851 11.2739 7.3454 10.9968ZM20.5825 9.84961L20.333 17.3332C19.6927 17.3332 19.0787 17.0789 18.6259 16.6261L17.8746 15.8748C17.6279 15.6281 17.5045 15.5047 17.3794 15.4256C16.944 15.1499 16.3887 15.1499 15.9532 15.4256C15.8282 15.5047 15.7048 15.6281 15.4581 15.8748L15.4581 15.8748L15.4581 15.8748L14.9427 16.3902C14.5947 16.7382 13.9997 16.4917 13.9997 15.9996V20.6666H15.333C17.8472 20.6666 19.1042 20.6666 19.8853 19.8855C20.6663 19.1045 20.6663 17.8474 20.6663 15.3332V12.6666C20.6663 11.4715 20.6663 10.5605 20.5825 9.84961Z"
                  fill="#2361FF"/>
            <path
                d="M8.00016 12.6668C8.00016 11.3909 8.00158 10.501 8.09185 9.8296C8.17954 9.17736 8.33994 8.83196 8.58595 8.58595C8.83196 8.33994 9.17736 8.17954 9.8296 8.09185C10.501 8.00158 11.3909 8.00016 12.6668 8.00016H15.3335C16.6094 8.00016 17.4993 8.00158 18.1707 8.09185C18.823 8.17954 19.1684 8.33994 19.4144 8.58595C19.6604 8.83196 19.8208 9.17736 19.9085 9.8296C19.9987 10.501 20.0002 11.3909 20.0002 12.6668V15.3335C20.0002 16.6094 19.9987 17.4993 19.9085 18.1707C19.8208 18.823 19.6604 19.1684 19.4144 19.4144C19.1684 19.6604 18.823 19.8208 18.1707 19.9085C17.4993 19.9987 16.6094 20.0002 15.3335 20.0002H14.6668V15.3335C14.6668 15.3214 14.6668 15.3093 14.6668 15.2972C14.6669 15.0145 14.6669 14.7398 14.6365 14.5131C14.6021 14.2577 14.5184 13.9662 14.2763 13.724C14.0342 13.4819 13.7426 13.3982 13.4872 13.3638C13.2606 13.3334 12.9858 13.3334 12.7031 13.3335C12.691 13.3335 12.6789 13.3335 12.6668 13.3335H8.00016V12.6668Z"
                stroke="#2361FF" strokeWidth="1.33333"/>
            <circle cx="16.6668" cy="11.3333" r="1.33333" fill="#2361FF"/>
            <path
                d="M11.3335 16.6665V15.9998H12.0002V16.6665H11.3335ZM8.41663 19.8537C8.12912 20.0838 7.70959 20.0371 7.47958 19.7496C7.24958 19.4621 7.29619 19.0426 7.5837 18.8126L8.41663 19.8537ZM10.6668 19.9998V16.6665H12.0002V19.9998H10.6668ZM11.3335 17.3332H8.00016V15.9998H11.3335V17.3332ZM11.75 17.1871L8.41663 19.8537L7.5837 18.8126L10.917 16.1459L11.75 17.1871Z"
                fill="#2361FF"/>
        </svg>
    );
}

export default ImageListSvg;