import React from 'react';

function PlusCircleSvg(props:any) {
    return (
        <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="13" fill="white" stroke="#C6CFE5" strokeWidth="2"/>
            <rect x="13" y="9" width="2" height="10" rx="1" fill="#3F75FF"/>
            <rect x="19" y="13" width="2" height="10" rx="1" transform="rotate(90 19 13)" fill="#3F75FF"/>
        </svg>

    );
}

export default PlusCircleSvg;