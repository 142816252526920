export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_GROUPS = "GET_GROUPS";
export const GET_AWARDS = "GET_AWARDS";
export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const ADD_LOADING_NOTIFICATIONS = "ADD_LOADING_NOTIFICATIONS";
export const REMOVE_LOADING_NOTIFICATIONS = "REMOVE_LOADING_NOTIFICATIONS";

