import React from 'react';
import styled from "styled-components";
import History from "../utils/history";
import {useSelector} from "react-redux";
import RightArrowSvg from "../images/RightArrowSvg";
import Navigation, {StyledNavigation} from "./Navigation";

const MenuItem = styled.a`
  color: #8e8e8e;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EEEEEE;
  padding: 35px 80px;
  width: 100%;
  align-items: center;

  svg {
    path {
      fill: #8E8E8E;
    }
  }
`;

const StyledMobileMenu = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(90deg, #F8F8F8 0%, #FBFBFB 18.23%, #F3F7FF 39.15%, #FFFFFF 50.52%, #FCFDFF 77.09%, #F4F7FF 100%);
  flex: 1 1 auto;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding-bottom: 20px;
  z-index: 999;

  ${StyledNavigation} {
    width: 100%;
    padding: 28px;
  }
`;

const Button = styled.div`
  border: 2px solid #BCC6E0;
  border-radius: 9px;
  padding: 18px 55px;
  color: #393939;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  line-height: 24px;
  max-width: 320px;
  justify-content: center;
  display: flex;
  margin-top: 42px;

  &:hover {
    opacity: .6;
  }

  &:active {
    opacity: .8;
  }
`;

function MobileMenu({onBurgerClick}: any) {
    const userData = useSelector(({loginReducer: {userData}}: any) => userData);

    return (
        <StyledMobileMenu>
            <Navigation onBurgerClick={onBurgerClick}/>
            <MenuItem onClick={onBurgerClick} href={"#"}>Home <RightArrowSvg/></MenuItem>
            <MenuItem onClick={onBurgerClick} href={"#forwhat"}>For what <RightArrowSvg/></MenuItem>
            <MenuItem onClick={onBurgerClick} href={"#why"}>Why it is amazing? <RightArrowSvg/></MenuItem>
            <MenuItem onClick={onBurgerClick} href={"#how"}>How to use <RightArrowSvg/></MenuItem>
            <MenuItem onClick={onBurgerClick} href={"#contacts"}>Contacts <RightArrowSvg/></MenuItem>
            <Button onClick={() => userData.Login ? History.push("/home") : History.push("/signup")}>To try</Button>
        </StyledMobileMenu>
    );
}

export default MobileMenu;