import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Progress} from "antd";
import BalanceSvg from "../images/BalanceSvg";
import PhoneSvg from "../images/PhoneSvg";
import MailSvg from "../images/MailSvg";
import FacebookSvg from "../images/FacebookSvg";
import GoogleSvg from "../images/GoogleSvg";
import award1 from "../images/Award6.svg";
import award2 from "../images/Award4.svg";
import award3 from "../images/Award8.svg";
import EditSvg from "../images/EditSvg";
import {useMatch, useParams} from "react-router-dom";
import axios from "axios";
import {api} from "../config";

const ProgressContent = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #4b4b4b;
`;

const Value = styled.div`
  color: #2361ff;
  font-size: 42px;
  line-height: 51px;
  font-weight: 800;
`;

const Right = styled.div`

`;

const Awards = styled.div`

`;

const AwardsTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-left: auto;
  }

  h2 {
    margin: 0;
  }
`;

const Count = styled.div`
  color: #a0a0a0;
  margin-left: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
`;

const AwardsShowcase = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  padding: 24px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px 20px;
  grid-template-areas: 
    "test3 test2 test1"
    "test4 test4 test1"
    "test5 test6 test7";

  div {
    img {
      height: 100%;
      width: 100%;
    }
  }

  .test1 {
    grid-area: test1;
  }

  .test2 {
    grid-area: test2;
  }

  .test3 {
    grid-area: test3;
  }

  .test4 {
    grid-area: test4;
  }

  .test5 {
    grid-area: test5;
  }

  .test6 {
    grid-area: test6;
  }

  .test7 {
    grid-area: test7;
  }
`;
const Left = styled.div`
  margin-right: 90px;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 48px;
  border-bottom: 1px solid #e4e4e4;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 124px;
  height: 124px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 18px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const About = styled.div`
  margin-top: 28px;
  margin-bottom: 50px;
`;

const AboutText = styled.div`
  color: #474747;
  font-size: 16px;
`;

const CompanyItem = styled.div`
  width: 106px;
  height: 106px;
  border-radius: 100%;
  overflow: hidden;


  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Companies = styled.div`
  display: flex;
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  padding: 38px 20px;
  justify-content: center;

  ${CompanyItem} {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

const Company = styled.div`

`;

const Socials = styled.div`
  display: flex;
  align-items: center;

  svg {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  color: #7b7b7b;
  line-height: 19px;
  font-weight: 500;
  font-size: 16px;

  svg {
    margin-right: 16px;
  }
`;

const Contacts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ContactItem} {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
`;


const Contact = styled.div`
  margin-top: 50px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #373737;
  padding: 5px 10px;
  background: #ffb400;
  border-radius: 26px;
  margin-bottom: 6px;
`;

const Title = styled.div`
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 4px;
`;

const Balance = styled.div`
  display: flex;
  align-items: center;
  color: #2361ff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  svg {
    margin-right: 5px;
  }
`;

const Content = styled.div`
  display: flex;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${Right} {
    width: 50%;
  }

  ${Left} {
    width: 50%;
  }
`;

const StyledUserHome = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px 60px 45px 0;
  overflow: hidden;

  h1 {
    color: #262626;
    font-size: 42px;
    font-weight: 800;
    line-height: 51px;
    margin-bottom: 46px;
  }

  h2 {
    color: #262626;
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
  }
`;

function UserHome() {

    const {userLogin} = useParams();

    const [userInfo, setUserInfo] = useState<any>({});

    const getUserInfo = () => {
        axios(`${api}/public/${userLogin}`).then(({data}) => {
            setUserInfo(data);
        })
    }

    useEffect(() => {
        getUserInfo();
    }, [])

    return (
        <StyledUserHome>
            <h1>Home</h1>
            <Content>
                <Left>
                    <UserInfo>
                        <Info>
                            <Avatar>
                                <img
                                    src={"https://sun9-21.userapi.com/impg/OCkY5kbSfHXnv6wosINFUNDaBZoKhzWzgI2wqw/KSsW3zXjld4.jpg?size=2560x1440&quality=96&sign=f4af1220dde4376057329b43497b6bfe&type=album"}
                                    alt=""/>
                            </Avatar>
                            <Text>
                                <Status>{userInfo.Bio}</Status>
                                <Title>{userInfo.Name || userInfo.Login}</Title>
                                <Balance><BalanceSvg/> 137 Awards</Balance>
                            </Text>
                        </Info>
                        <Progress trailColor={"#A4BDFF"} strokeColor={"#2361FF"} type="circle" percent={15}
                                  format={(value) =>
                                      <ProgressContent>
                                          <Value>{value}</Value>
                                          <div>Level</div>
                                      </ProgressContent>}/>
                    </UserInfo>
                    <About>
                        <h2>About me</h2>
                        <AboutText>
                            {userInfo.AboutMe}
                        </AboutText>
                    </About>
                    <Contact>
                        <h2>Contact</h2>
                        <Contacts>
                            <div>
                                <ContactItem><PhoneSvg/> {userInfo.Phone || "-"}</ContactItem>
                                <ContactItem><MailSvg/> {userInfo.Email || "-"}</ContactItem>
                            </div>
                        </Contacts>
                    </Contact>
                </Left>
                <Right>
                    <Awards>
                        <AwardsTop><h2>Awards</h2> <Count>137</Count> <EditSvg/></AwardsTop>
                        <AwardsShowcase>
                            <div className="test1"><img src={award1} alt=""/></div>
                            <div className="test2"><img src={award2} alt=""/></div>
                            <div className="test3"><img src={award2} alt=""/></div>
                            <div className="test4"><img src={award3} alt=""/></div>
                            <div className="test5"><img src={award2} alt=""/></div>
                            <div className="test6"><img src={award2} alt=""/></div>
                            <div className="test7"><img src={award2} alt=""/></div>
                        </AwardsShowcase>
                    </Awards>
                </Right>
            </Content>
        </StyledUserHome>
    );
}

export default UserHome;