import React from 'react';

function MailSvg(props:any) {
    return (
        <svg {...props} width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.33964 4.94989C0.00595354 8.05027 0.0208277 11.626 0.485157 14.7138C0.74208 16.4223 2.1365 17.7322 3.85776 17.8818L5.65663 18.0382C9.40754 18.3642 13.1797 18.3642 16.9306 18.0382L18.7295 17.8818C20.4507 17.7322 21.8451 16.4223 22.1021 14.7138C22.5664 11.626 22.5813 8.05047 22.2476 4.95008C22.2045 4.59065 22.156 4.23169 22.1021 3.87334C21.8451 2.1648 20.4507 0.854987 18.7295 0.705361L16.9306 0.548988C13.1797 0.222928 9.40754 0.222929 5.65663 0.548988L3.85775 0.705361C2.1365 0.854987 0.74208 2.1648 0.485157 3.87334C0.431279 4.23163 0.382773 4.59052 0.33964 4.94989ZM5.81136 2.32897C9.45931 2.01186 13.1279 2.01186 16.7759 2.32896L18.5747 2.48534C19.4732 2.56344 20.2011 3.24717 20.3352 4.13903C20.3492 4.23165 20.3627 4.32431 20.3759 4.41701L13.752 8.09693C12.2231 8.94634 10.364 8.94634 8.8351 8.09693L2.21132 4.41706C2.2245 4.32434 2.23805 4.23166 2.25198 4.13903C2.3861 3.24717 3.11398 2.56344 4.01248 2.48534L5.81136 2.32897ZM20.5945 6.33945C20.8256 9.04063 20.7391 11.7622 20.3352 14.4481C20.2011 15.34 19.4732 16.0237 18.5747 16.1018L16.7759 16.2582C13.1279 16.5753 9.45931 16.5753 5.81136 16.2582L4.01249 16.1018C3.11398 16.0237 2.3861 15.34 2.25198 14.4481C1.84808 11.7622 1.76165 9.04066 1.99269 6.33949L7.96741 9.65878C10.036 10.808 12.5512 10.808 14.6197 9.65878L20.5945 6.33945Z"
                  fill="#2361FF"/>
        </svg>
    );
}

export default MailSvg;