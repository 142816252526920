import {initialState} from "../initialState";
import {
    ADD_LOADING_NOTIFICATIONS,
    GET_AWARDS,
    GET_COLLECTIONS,
    GET_GROUPS,
    GET_NOTIFICATIONS,
    GET_TEMPLATES, REMOVE_LOADING_NOTIFICATIONS
} from "../actionTypes";

export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TEMPLATES:
            return {...state, templates: action.payload}
        case GET_GROUPS:
            return {...state, groups: action.payload}
        case GET_AWARDS:
            return {...state, awards: action.payload}
        case GET_COLLECTIONS:
            return {...state, collections: action.payload}
        case GET_NOTIFICATIONS:
            return {...state, notifications: action.payload}
        case ADD_LOADING_NOTIFICATIONS:
            return {...state, loadingNotifications: [...state.loadingNotifications, action.payload]}
        case REMOVE_LOADING_NOTIFICATIONS:
            return {...state, loadingNotifications: [...state.loadingNotifications].filter(el => el !== action.payload)}
        default:
            return state;
    }
}