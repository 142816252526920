import React from 'react';

function LockSvg(props:any) {
    return (
        <svg {...props} width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 22C15.4696 22 14.9609 22.2107 14.5858 22.5858C14.2107 22.9609 14 23.4696 14 24V30C14 30.5304 14.2107 31.0391 14.5858 31.4142C14.9609 31.7893 15.4696 32 16 32C16.5304 32 17.0391 31.7893 17.4142 31.4142C17.7893 31.0391 18 30.5304 18 30V24C18 23.4696 17.7893 22.9609 17.4142 22.5858C17.0391 22.2107 16.5304 22 16 22ZM26 14V10C26 7.34784 24.9464 4.8043 23.0711 2.92893C21.1957 1.05357 18.6522 0 16 0C13.3478 0 10.8043 1.05357 8.92893 2.92893C7.05357 4.8043 6 7.34784 6 10V14C4.4087 14 2.88258 14.6321 1.75736 15.7574C0.632141 16.8826 0 18.4087 0 20V34C0 35.5913 0.632141 37.1174 1.75736 38.2426C2.88258 39.3679 4.4087 40 6 40H26C27.5913 40 29.1174 39.3679 30.2426 38.2426C31.3679 37.1174 32 35.5913 32 34V20C32 18.4087 31.3679 16.8826 30.2426 15.7574C29.1174 14.6321 27.5913 14 26 14ZM10 10C10 8.4087 10.6321 6.88258 11.7574 5.75736C12.8826 4.63214 14.4087 4 16 4C17.5913 4 19.1174 4.63214 20.2426 5.75736C21.3679 6.88258 22 8.4087 22 10V14H10V10ZM28 34C28 34.5304 27.7893 35.0391 27.4142 35.4142C27.0391 35.7893 26.5304 36 26 36H6C5.46957 36 4.96086 35.7893 4.58579 35.4142C4.21071 35.0391 4 34.5304 4 34V20C4 19.4696 4.21071 18.9609 4.58579 18.5858C4.96086 18.2107 5.46957 18 6 18H26C26.5304 18 27.0391 18.2107 27.4142 18.5858C27.7893 18.9609 28 19.4696 28 20V34Z"
                fill="#7EA2FF"/>
        </svg>
    );
}

export default LockSvg;