import React from 'react';

function ListItemSvg(props:any) {
    return (
        <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="4" fill="#DFE6FA"/>
            <rect x="7" y="8" width="14" height="2" rx="1" fill="#2361FF"/>
            <rect x="7" y="13" width="14" height="2" rx="1" fill="#2361FF"/>
            <rect x="7" y="18" width="14" height="2" rx="1" fill="#2361FF"/>
        </svg>
    );
}

export default ListItemSvg;