import styled from "styled-components";
import {Input} from "antd";

export const StyledInput = styled(Input)`
  background: #FFFFFF;
  border: 2px solid #EDF2FF;
  border-radius: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  font-size: 14px;
  line-height: normal;

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, &:hover,&:active, &:focus {
    border-right-width: 2px !important;
  }

`;

export const StyledTextArea = styled(Input.TextArea)`
  background: #FFFFFF;
  border: 2px solid #EDF2FF;
  border-radius: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  font-size: 14px;
  line-height: normal;
  resize: none;

  &:hover, &:active, &:focus {
    border-right-width: 2px !important;
  }
`;

StyledInput.TextArea = StyledTextArea;
