import React from 'react';
import styled from "styled-components";
import AwardsLogo from "../images/AwardsLogo";
import award from "../images/Award3.png";
import RightArrowSvg from "../images/RightArrowSvg";
import {Link} from "react-router-dom";
import {Button, Form, Input} from "antd";
import FacebookSvg from "../images/FacebookSvg";
import GoogleSvg from "../images/GoogleSvg";
import AppleSvg from "../images/AppleSvg";
import moment from "moment";
import store from "../redux/store";
import {loginAction} from "../redux/actions/loginActions";
import History from "../utils/history";

const StyledLink = styled(Link)`

`;

const StyledButton = styled(Button)`

`;

const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;

  a {
    height: 54px;
    width: 54px;
    border-radius: 12px;
    display: flex;
    align-items: center;

    &.apple {
      background: #09101D;
    }

    &.fb {
      background: #425993;
    }

    &.google {
      background: #df3737;
    }

    span {
      display: none;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  svg {
    height: 54px;
    width: 54px;
  }
`;

const Logo = styled.div`
  margin-top: 36px;
  margin-bottom: 85px;

  svg {
    cursor: pointer;
  }
`;

const FormWrap = styled.div`
  width: 100%;

  h2 {
    font-size: 18px;
    color: #828282;
    margin-bottom: 42px;
  }

  .ant-form {

    .ant-btn {
      background: #2361ff;
      border-radius: 12px;
      border: none;
      font-size: 14px;
      color: #ffffff;
      font-weight: 600;
      padding: 22px 86px;
      height: auto;
      margin-right: 20px;
    }

    .ant-input, .ant-input-password {
      background: #f4f6f9;
      font-size: 14px;
      padding: 20px 20px;
      border-radius: 12px;

      .ant-input {
        padding: 0;
        border-radius: 0;
      }
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: #2361ff;
    font-size: 14px;
    font-weight: 500;

    svg {
      margin-left: 18px;
    }
  }

  h1 {
    color: #1c1c28;
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 22px;
    line-height: 54px;
  }
`;

const Forget = styled.div`
  text-decoration: underline;
  text-align: right;

  a {
    color: #c2c2c2;
    font-size: 14px;
  }
`;


const Footer = styled.div`
  margin-top: auto;
  padding-bottom: 52px;
  color: #828282;
  font-size: 14px;
`;

const Content = styled.div`
  display: flex;
  width: 50%;
  align-items: center;

  img {
    margin-right: 115px;
    overflow: hidden;
  }
`;

const BottomMobile = styled.div`
  display: none;
`;

const StyledSignup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow: auto;

  * {
    font-family: Inter, Montserrat, sans-serif;
  }

  @media (max-width: 1100px) {
    background: #2361ff;
    align-items: flex-start;
    padding: 0 80px;

    ${Logo} {
      svg {
        path, rect {
          fill: #fff;
        }
      }
    }

    h1 {
      color: #ffffff;
    }

    ${Footer} {
      color: #6e96ff;
      width: 100%;
      text-align: center;
      padding-bottom: 20px;
    }

    ${Content} {
      width: 100%;

      > img {
        display: none;
      }

      h2 {
        color: #ffffff;
      }

      ${BottomMobile} {
        display: flex;
        justify-content: center;
        font-size: 18px;

        a {
          color: #ffffff;

          svg {
            flex: 1 0 auto;
            margin-left: 15px;

            path {
              fill: #ffffff;
            }
          }
        }
      }

      ${Title} {
        ${StyledLink} {
          display: none;
        }
      }

      ${ButtonsGroup} {
        flex-direction: column;

        ${StyledButton} {
          margin-bottom: 18px;
          width: 100%;
          margin-right: 0;
          background: #ffffff;
          color: #2361ff;
          padding: 22px 20px;
        }

        a {
          width: 100%;
          margin: 0;
          color: #ffffff;
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          justify-content: center;

          svg {
            position: absolute;
            left: 0;
          }

          &:not(:last-child) {
            margin-bottom: 14px;
          }

          span {
            display: inline;
          }
        }
      }
    }
  }
  @media (max-width: 420px) {
    ${Content} {
      ${ButtonsGroup} {
        a {
          padding-left: 54px;
          padding-right: 10px;
        }
      }
    }
  }
  @media (max-width: 460px) {
    padding: 0 40px;
    ${Content} {
      ${ButtonsGroup} {
        a {
          padding-left: 54px;
          padding-right: 10px;
        }
      }
    }
  }
`;

function SignIn() {

    return (
        <StyledSignup>
            <Logo>
                <AwardsLogo onClick={() => History.push("/")}/>
            </Logo>
            <Content>
                <img src={award} alt=""/>
                <FormWrap>
                    <Title>
                        <h1>Sign in</h1>
                        <StyledLink to={"/signup"}>Join now<RightArrowSvg/></StyledLink>
                    </Title>
                    <h2>Login right now!</h2>
                    <Form onFinish={fields => store.dispatch(loginAction(fields))}>
                        <Form.Item name={"login"}>
                            <Input placeholder={"Enter your Email"}/>
                        </Form.Item>
                        <Form.Item name={"password"}>
                            <Input.Password placeholder={"Enter your password"}/>
                        </Form.Item>
                        <Forget>
                            <Link to={"/"}>
                                Forgot password
                            </Link>
                        </Forget>
                        <Form.Item>
                            <ButtonsGroup>
                                <StyledButton htmlType={"submit"}>Login</StyledButton>
                                <Link className={"disabled apple"} to={"#"}><AppleSvg/> <span>Login with Apple</span>
                                </Link>
                                <Link className={"disabled fb"} to={"#"}><FacebookSvg/> <span>Login with Facebook</span>
                                </Link>
                                <Link className={"disabled google"} to={"#"}><GoogleSvg/> <span>Login with Google</span>
                                </Link>
                            </ButtonsGroup>
                        </Form.Item>
                    </Form>
                    <BottomMobile>
                        <StyledLink to={"/signup"}>Join now<RightArrowSvg/></StyledLink>
                    </BottomMobile>
                </FormWrap>
            </Content>
            <Footer>
                N-Awards copyright. 2021-{moment().year()}
            </Footer>
        </StyledSignup>
    );
}

export default SignIn;