import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import nouser from "../images/nouser.png";
import EyeEditSvg from "../images/EyeEditSvg";
import PenEditSvg from "../images/PenEditSvg";
import RectPlusSvg2 from "../images/RectPlusSvg2";
import History from "../utils/history";
import {Button, message, Modal, Popover} from "antd";
import Settings from "./Settings";
import SettingsContent from "../components/SettingsContent";
import SettingsCompanyContent from "../components/SettingsCompanyContent";
import NextSvg from "../images/NextSvg";
import axios from "axios";
import {api} from "../config";
import {useSelector} from "react-redux";
import store from "../redux/store";
import {getGroups} from "../redux/actions/pageActions";
import TrashSvg from "../images/TrashSvg";
import MoreSvg from "../images/MoreSvg";

const Company = styled.div`
  padding: 20px 25px;
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Companies = styled.div`
  display: flex;
  flex-direction: column;

  ${Company} {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
`;

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 12px;
  flex: 0 0 auto;
`;

const Title = styled.div`
  margin-right: auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #262626;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 6px;
  }
`;

const Actions = styled.div`
  display: flex;

  ${Action} {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;

const CreateButton = styled.div`
  margin-top: 42px;
  display: flex;
  align-items: center;
  color: #6780be;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  cursor: pointer;

  &.mobileCreate {
    display: none;
  }

  svg {
    margin-right: 16px;
  }
`;

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

`;

const CompanyCreate = styled(Button)`
  display: flex;
  align-items: center;
  background: #2361FF !important;
  border: none;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  padding: 24px 25px;
  border-radius: 12px;

  &:hover {
    opacity: .5;
  }

  &:active, &:focus {
    opacity: .8;
  }

  svg {
    margin-left: 80px;
  }
`;

const CompanyCancel = styled(Button)`
  display: flex;
  align-items: center;
  background: none !important;
  border: none;
  color: #ff5f12;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  margin-right: 20px;
`;


const StyledMyCompany = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 120px 120px 0;
  width: 100%;
  overflow: auto;

  h1 {
    font-weight: 800;
    font-size: 42px;
    line-height: 51px;
    color: #262626;
  }

  .popover{
    display: none;
    cursor: pointer;
  }
  
  @media (max-width: 1100px) {
    padding: 45px 48px 120px 48px;

    h1 {
      font-size: 32px;
    }

    .popover {
      display: flex;
      flex: 0 0 auto;
      margin-left: 10px;
    }

    ${CreateButton} {
      display: none;
      &.mobileCreate {
        display: flex;
      }
    }

    ${Actions} {
      &:not(.mobileActions) {
        display: none;
      }

      ${Action} {
        &:not(:last-child) {
          margin-right: 25px;
        }

        span {
          display: none;
        }
      }
    }
  }

`;

function MyCompany() {

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

    const [fields, setFields] = useState<any>({});

    const [editId, setEditId] = useState<any>(null);

    const [editFields, setEditFields] = useState<any>({});

    const companies = useSelector(({pageReducer: {groups}}: any) => groups);

    const removeCompany = (groupId: number) => {
        axios.delete(`${api}/groups/${groupId}`).then(() => {
            message.warning("Company successfully removed!");
            store.dispatch(getGroups());
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const createCompany = () => {


        const reader = new FileReader();
        reader.onloadend = function () {
            // preview.src = reader.result;
            console.log({
                ...fields,
                Photo: reader.result,
                Type: "company",
                Visibility: "public",
            })

            axios.post(`${api}/groups`, {
                ...fields,
                Photo: "",
                PhotoRaw: reader.result,
                Type: "company",
                Visibility: "public",
            }).then(() => {
                setFields("");
                setModalVisible(false);
                store.dispatch(getGroups());
            })
        }
        if (fields.Photo) {
            reader.readAsDataURL(fields.Photo);
        } else {
            message.error("You need to upload photo!");
        }
    }

    const editCompany = () => {
        const reader = new FileReader();
        reader.onloadend = function () {
            axios.post(`${api}/groups/${editId}`, {
                ...editFields,
                Photo: "",
                PhotoRaw: reader.result,
                Type: "company",
                Visibility: "public",
            }).then(() => {
                setEditFields("");
                setEditModalVisible(false);
                store.dispatch(getGroups());
            })
        }
        if (editFields.Photo) {
            reader.readAsDataURL(editFields.Photo);
        } else {
            axios.post(`${api}/groups/${editId}`, {
                ...editFields,
                Type: "company",
                Photo: undefined,
                Visibility: "public",
            }).then(() => {
                setEditFields("");
                setEditModalVisible(false);
                store.dispatch(getGroups());
            })
        }
    }

    useEffect(() => {
        store.dispatch(getGroups());
    }, [])

    return (
        <StyledMyCompany>
            <Modal width={"auto"} style={{maxWidth: 900}} visible={modalVisible} footer={null} onCancel={() => setModalVisible(false)}>
                <h1>Create company</h1>
                <SettingsCompanyContent fields={fields}
                                        setFields={setFields}/>
                <ModalActions>
                    <CompanyCancel onClick={() => setModalVisible(false)}>Cancel</CompanyCancel>
                    <CompanyCreate onClick={createCompany}>Create<NextSvg/></CompanyCreate>
                </ModalActions>
            </Modal>
            <Modal width={"auto"} style={{maxWidth: 900}} visible={editModalVisible} footer={null} onCancel={() => setEditModalVisible(false)}>
                <h1>Edit company</h1>
                <SettingsCompanyContent fields={editFields}
                                        setFields={setEditFields}/>
                <ModalActions>
                    <CompanyCancel onClick={() => setEditModalVisible(false)}>Cancel</CompanyCancel>
                    <CompanyCreate onClick={editCompany}>Save<NextSvg/></CompanyCreate>
                </ModalActions>
            </Modal>
            <h1>My company</h1>
            <Companies>
                {companies?.map((company: any) => <Company>
                    <Avatar src={company.Photo ? `/${company.Photo}` : nouser} alt={"avatar"}/>
                    <Title title={company.Name || `#${company.Id}`}>{company.Name || `#${company.Id}`}</Title>
                    <Actions>
                        <Action
                            onClick={() => History.push(`/company/${company.Id}`)}><EyeEditSvg/><span>View</span></Action>
                        <Action onClick={() => {
                            setEditFields({
                                Name: company.Name,
                                Description: company.Description,
                                Phone: company.Phone,
                                Email: company.Email,
                                Address: company.Address,
                                FacebookLink: company.FacebookLink,
                                LinkedinLink: company.LinkedinLink,
                            });
                            setEditId(company.Id)
                            setEditModalVisible(true);
                        }}><PenEditSvg/><span>Edit</span></Action>
                        <Action onClick={() => removeCompany(company.Id)}><TrashSvg/><span>Remove</span></Action>
                    </Actions>
                    <Popover getPopupContainer={() => document.querySelector(`.${Companies.styledComponentId}`)!}
                             className={"popover"}
                             content={<Actions className={"mobileActions"}>
                                 <Action
                                     onClick={() => History.push(`/company/${company.Id}`)}><EyeEditSvg/><span>View</span></Action>
                                 <Action onClick={() => {History.push(`/company/edit/${company.Id}`)}}><PenEditSvg/><span>Edit</span></Action>
                                 <Action
                                     onClick={() => removeCompany(company.Id)}><TrashSvg/><span>Remove</span></Action>
                             </Actions>} title={null} trigger="click">
                        <MoreSvg/>
                    </Popover>
                </Company>)}
            </Companies>
            <CreateButton onClick={() => setModalVisible(true)}><RectPlusSvg2/>Create company</CreateButton>
            <CreateButton className={"mobileCreate"} onClick={() => History.push("/company/create")}><RectPlusSvg2/>Create company</CreateButton>
        </StyledMyCompany>
    );
}

export default MyCompany;