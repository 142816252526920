import React from 'react';

function LinkedInSvg(props: any) {
    return (
        <svg {...props} width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="43" height="43" rx="8.0625" fill="#007BB6"/>
            <g clipPath="url(#clip0_393_2636)">
                <path d="M28.2852 13.537H14.711C14.0654 13.537 13.5371 14.0496 13.5371 14.6835V28.3125C13.5371 28.9464 14.0654 29.463 14.711 29.463H28.2852C28.9348 29.463 29.463 28.9464 29.463 28.3125V14.6835C29.463 14.0496 28.9348 13.537 28.2852 13.537Z" fill="white"/>
                <path d="M15.8968 19.5083H18.2603V27.1073H15.8968V19.5083ZM17.0785 15.7283C17.8337 15.7283 18.4481 16.3427 18.4481 17.0979C18.4481 17.8531 17.8337 18.4674 17.0785 18.4674C16.3194 18.4674 15.709 17.8531 15.709 17.0979C15.709 16.3427 16.3194 15.7283 17.0785 15.7283Z" fill="#007BB6"/>
                <path d="M19.7441 19.5083H22.0098V20.5491H22.0411C22.3541 19.9504 23.125 19.3204 24.2754 19.3204C26.6662 19.3204 27.1084 20.8935 27.1084 22.94V27.1073H24.745V23.4095C24.745 22.5291 24.7293 21.3943 23.5163 21.3943C22.2876 21.3943 22.0998 22.353 22.0998 23.3469V27.1073H19.7402V19.5083H19.7441Z" fill="#007BB6"/>
            </g>
            <defs>
                <clipPath id="clip0_393_2636">
                    <rect width="15.9259" height="15.9259" fill="white" transform="translate(13.5371 13.5371)"/>
                </clipPath>
            </defs>
        </svg>

    );
}

export default LinkedInSvg;