import React from 'react';

function EditSvg(props:any) {
    return (
        <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25.6061 4.221C25.8624 4.60915 25.8196 5.13663 25.4779 5.47834L13.2214 17.7349C13.0958 17.8605 12.9389 17.9504 12.767 17.9953L7.66247 19.3286C7.49598 19.3721 7.32437 19.3712 7.16312 19.3302C6.9917 19.2866 6.83201 19.1975 6.70265 19.0682C6.45162 18.8172 6.3525 18.4518 6.44222 18.1084L7.77555 13.0038C7.81484 12.8534 7.88857 12.7048 7.99055 12.5883L20.2925 0.292893C20.4002 0.185208 20.5299 0.104614 20.6711 0.0555083C20.7757 0.0190991 20.8867 0 20.9996 0C21.2648 0 21.5192 0.105357 21.7067 0.292893L25.4779 4.06413C25.5268 4.11294 25.5695 4.16555 25.6061 4.221ZM23.3566 4.77124L20.9996 2.41421L9.64204 13.7718L8.80873 16.9621L11.9991 16.1288L23.3566 4.77124Z"
                  fill="#2361FF"/>
            <path
                d="M23.1881 19.8804C23.5526 16.7636 23.6686 13.625 23.536 10.4944C23.5297 10.3445 23.5861 10.1986 23.6921 10.0926L25.0033 8.78145C25.1645 8.62025 25.4399 8.72259 25.455 8.95006C25.7015 12.6696 25.6081 16.406 25.1745 20.1128C24.8591 22.8095 22.6933 24.9228 20.0106 25.2226C15.3885 25.7392 10.6107 25.7392 5.98862 25.2226C3.30588 24.9228 1.1401 22.8095 0.824694 20.1128C0.271977 15.387 0.271977 10.613 0.824694 5.88724C1.1401 3.19053 3.30588 1.07719 5.98862 0.777349C9.49495 0.385465 13.0909 0.290865 16.6405 0.493548C16.8692 0.506611 16.9735 0.783433 16.8115 0.945458L15.4881 2.26887C15.3832 2.37379 15.2393 2.43014 15.091 2.42514C12.1224 2.32502 9.13366 2.4383 6.21077 2.76497C4.43729 2.96319 3.01664 4.36267 2.81115 6.11957C2.27649 10.6909 2.27649 15.309 2.81115 19.8804C3.01664 21.6373 4.43729 23.0368 6.21076 23.235C10.6852 23.7351 15.314 23.7351 19.7885 23.235C21.5619 23.0368 22.9826 21.6373 23.1881 19.8804Z"
                fill="#2361FF"/>
        </svg>
    );
}

export default EditSvg;