import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {Button, message, Select} from "antd";
import ArrowCircleSvg from "../images/ArrowCircleSvg";
import award from "../images/Award7.svg";
import EyeSvg from "../images/EyeSvg";
import {useSelector} from "react-redux";
import store from "../redux/store";
import {getCollections, getTemplates} from "../redux/actions/pageActions";
import CreateFileSvg from "../images/CreateFileSvg";
import History from "../utils/history";
import TrashSvg from "../images/TrashSvg";
import axios from "axios";
import {api} from "../config";
import * as _ from "lodash";

const {Option} = Select;

const Filters = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 18px;
  border-bottom: 1px solid #B3C3ED;
  gap: 18px 10px;
  flex-wrap: wrap;

  .ant-input {
    background: #FFFFFF;
    border: 1px solid #C6CFE5;
    border-radius: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;
    font-size: 14px;
    line-height: normal;
    max-width: 115px;
  }

  .ant-select {
    min-width: 240px;

    .ant-select-arrow {
      top: 34%;
      right: 23px;
    }

    .ant-select-selection-item {
      line-height: normal;
    }

    .ant-select-selector {
      font-size: 14px;
      padding-top: 15px;
      padding-bottom: 15px;
      height: auto;
      background: #FFFFFF;
      border: 1px solid #C6CFE5;
      border-radius: 12px;
      color: #828282;

      &:after {
        line-height: normal;
      }

      .ant-select-selection-placeholder {
        line-height: normal;
      }
    }
  }
`;

const ShopItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #C6D5FD;
  box-shadow: 0 0 15px rgba(147, 197, 234, 0.15);
  border-radius: 16px;
  background: #FBFCFF;
`;

const CreateButton = styled(Button)`
  background: #2361FF;
  border-radius: 12px;
  box-shadow: 0 0 25px #2361FF;
  border: 2px solid #2361FF;
  padding: 22px 35px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  bottom: 45px;
  right: 60px;
  line-height: 19px;
  display: flex;
  align-items: center;

  &:hover, &:focus, &:active {
    background: #2361FF;
    color: #ffffff;
    border: 2px solid #2361FF;
    opacity: .5;
  }

  &:focus, &:active {
    opacity: .8;
  }

  svg {
    margin-right: 14px;
  }
`;

const Items = styled.div`
  display: flex;
  margin: 24px 0 45px 0;
  overflow: hidden;

  .container {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 24px 24px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Collection = styled.div`
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;

  span {
    color: #3D3D3D;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }
`;

const Views = styled.div`
  color: #3a3a3a;
  line-height: 15px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
`;

const UIItem = styled.div`
  width: 38px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #D4DBEB;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;

`;


const UI = styled.div`
  opacity: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 12px;
  transition: all .1s;
`;

const View = styled.div`
  background: #FFFFFF;
  border: 1px solid #C6D6FD;
  border-radius: 26px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  margin-top: auto;
  cursor: pointer;

  svg {
    margin-left: 14px;
  }
`;

const Photo = styled.div<{ color: string }>(({color}: any) => css`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  background: ${color || "none"};
  border-radius: 16px;
  overflow: hidden;

  img {
    width: 250px;
    height: 250px;
    overflow: hidden;
    object-fit: cover;
  }

  &:hover {
    ${UI} {
      display: flex;
      opacity: 1;
    }
  }

`);

const Rarity = styled.div`
  color: #710ed5;
  font-size: 12px;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 8px;
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #262626;
  font-size: 18px;
  font-weight: 600;
`;

const Free = styled.div`
  color: #006c00;
  font-size: 24px;
`;

const ShopItemCost = styled.div`
  display: flex;
  align-items: center;
  font-size: 32px;
  line-height: 39px;
  color: #262626;
  font-weight: 800;

  span {
    margin-left: 5px;
    font-size: 18px;
    color: #2361ff;
  }
`;

const Cost = styled.div`
  display: flex;
  align-items: center;
`;

const StyledShop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 45px 110px 0 0;

  h1 {
    color: #262626;
    font-weight: 800;
    font-size: 42px;
    line-height: 51px;
    margin-bottom: 28px;
  }

  @media (max-width: 1100px) {
    padding: 45px 48px 0 48px;
    h1 {
      font-size: 32px;
    }

    ${Filters} {
      .ant-select {
        min-width: auto;
        width: 100%;
      }
    }

    ${CreateButton} {
      bottom: 20px;
      right: 20px;
      padding: 22px;

      svg {
        margin: 0;
      }

      span {
        display: none;
      }
    }

    ${Items} {
      .container {
        ${ShopItem} {
          flex: 1 1 auto;
        }
      }
    }

    ${Photo} {
      img {
        width: 165px;
        height: 165px;
      }
    }
  }
`;

function Shop() {
    const templates = useSelector(({pageReducer: {templates}}: any) => templates);
    const userData = useSelector(({loginReducer: {userData}}: any) => userData);
    const collections = useSelector(({pageReducer: {collections}}: any) => collections);

    // const getCollections = () => {
    //     axios(`${api}/collections`).then(()=>{
    //
    //     })
    // }


    const removeTemplate = (id: number) => {
        axios.delete(`${api}/templates/${id}`).then(() => {
            message.warning("Remove template successfully!")
            store.dispatch(getTemplates());
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    // useEffect(() => {
    //
    // }, [templates])

    useEffect(() => {
        store.dispatch(getCollections());
        store.dispatch(getTemplates());
        console.log(templates);
        // getCollections();
    }, [])

    const [typeFilter, setTypeFilter] = useState("award");
    const [rarityFilter, setRarityFilter] = useState("");
    const [collectionFilter, setCollectionFilter] = useState("");

    const filteredTemplates = templates.filter((template: any) => (template.Type === typeFilter) && (collectionFilter === "" || (template.CollectionId === collectionFilter)) && (rarityFilter === "" || (template.Frequency === rarityFilter)));

    return (
        <StyledShop>
            <h1>Awards shop</h1>
            <Filters>
                <Select value={typeFilter} onChange={setTypeFilter} suffixIcon={<ArrowCircleSvg/>} placeholder={"Type"}>
                    <Option value={"award"}>Award</Option>
                    <Option value={"diploma"}>Diploma</Option>
                </Select>
                <Select value={rarityFilter} onChange={setRarityFilter} suffixIcon={<ArrowCircleSvg/>}
                        placeholder={"Rarity"}>
                    <Option value={""}>Rarity</Option>
                    {_.sortedUniq(templates.map((template: any) => template.Frequency)).map((rarity: any, index) => <Option
                        value={rarity} key={index}>{rarity}</Option>
                    )}
                </Select>
                <Select suffixIcon={<ArrowCircleSvg/>} value={collectionFilter} onChange={setCollectionFilter}
                        placeholder={"Collection"}>
                    <Option value={""}>Collection</Option>
                    {_.sortedUniq(collections.map((collection: any, index:number) => <Option
                        value={collection.Id} key={index}>{collection.Name}</Option>
                    ))}
                </Select>
                {/*<Cost>*/}
                {/*    <Input/>-<Input/>*/}
                {/*</Cost>*/}
                {/*<Select suffixIcon={<ArrowCircleSvg/>} placeholder={"Popularity"}>*/}
                {/*    <Option value={1}>1</Option>*/}
                {/*</Select>*/}
            </Filters>
            <Items>
                <div className="container">
                    {filteredTemplates?.map((template: any, index: number) => <ShopItem key={index}
                        onClick={() => History.push(`/award/create/${template.Id}`)}>
                        <Top>
                            <Collection>Collection: <span>{template.Collection?.Name || "none"}</span></Collection>
                            <Views>{template.Bought} times</Views>
                        </Top>
                        <Photo color={template.BackgroundColor}>
                            <img src={`/${template.Image}`} alt=""/>
                            <UI>
                                {/*<UIItem><HearthSvg/></UIItem>*/}
                                {template.OwnerId === userData.Login && <UIItem onClick={(e) => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    removeTemplate(template.Id)
                                }}><TrashSvg/></UIItem>
                                }
                                {/*<UIItem><MoreSvg/></UIItem>*/}
                                <View>View <EyeSvg/></View>
                            </UI>
                        </Photo>
                        <Rarity>
                            {template.Frequency}
                        </Rarity>
                        <Text>
                            <Title>{template.Name}</Title>
                            <ShopItemCost>{template.Price ? <>{template.Price / 100}<span>$</span></> :
                                <Free>FREE</Free>} </ShopItemCost>
                        </Text>
                    </ShopItem>)}
                </div>
            </Items>
            <CreateButton onClick={() => History.push("/template/create")}><CreateFileSvg/><span>Create a
                template</span></CreateButton>
        </StyledShop>
    );
}

export default Shop;