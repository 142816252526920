import axios from "axios";
import {api} from "../../config";
import {
    ADD_LOADING_NOTIFICATIONS,
    GET_AWARDS,
    GET_COLLECTIONS,
    GET_GROUPS,
    GET_NOTIFICATIONS,
    GET_TEMPLATES, REMOVE_LOADING_NOTIFICATIONS
} from "../actionTypes";

export function getTemplates() {
    return (dispatch: any) => {
        axios(`${api}/templates`).then(({data}) => {
            dispatch({type: GET_TEMPLATES, payload: data});
        })
    }
}

export function getGroups() {
    return (dispatch: any) => {
        axios(`${api}/groups`).then(({data}) => {
            dispatch({type: GET_GROUPS, payload: data});
        })
    }
}

export function getCollections() {
    return (dispatch: any) => {
        axios(`${api}/collections`).then(({data}) => {
            dispatch({type: GET_COLLECTIONS, payload: data});
        })
    }
}

export function getNotifications() {
    return (dispatch: any) => {
        axios(`${api}/user/notifications`).then(({data}) => {
            dispatch({type: GET_NOTIFICATIONS, payload: data});
        })
    }
}

export function getAwards() {
    return (dispatch: any) => {
        axios(`${api}/tokens`).then(({data}) => {
            dispatch({type: GET_AWARDS, payload: data});
        })
    }
}

export function addLoadingNotifications(id: any) {
    return (dispatch: any) => {
        dispatch({type: ADD_LOADING_NOTIFICATIONS, payload: id});
    }
}

export function removeLoadingNotifications({id}: any) {
    return (dispatch: any) => {
        dispatch({type: REMOVE_LOADING_NOTIFICATIONS, payload: id});
    }
}
