import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {Button, Divider, Form, Input, message, Modal, Select, Space, Typography, Upload} from "antd";
import PhotoUploadSvg from "../images/PhotoUploadSvg";
import ColorPicker from "../components/ColorPicker";
import NextSvg from "../images/NextSvg";
import {StyledInput} from "../components/styled/StyledInput";
import {StyledSelect} from "../components/styled/StyledSelect";
import ArrowCircleSvg from "../images/ArrowCircleSvg";
import DollarSvg from "../images/DollarSvg";
import axios from "axios";
import {api} from "../config";
import {Cropper} from "react-cropper";
import PlusCircleSvg from "../images/PlusCircleSvg";
import store from "../redux/store";
import {getCollections} from "../redux/actions/pageActions";
import {useSelector} from "react-redux";
import CrossCircleSvg from "../images/CrossCircleSvg";

const {Option} = Select;

const Content = styled.div`
  display: flex;
`;

const Image = styled.div<{ color: string }>(({color}: any) => css`
  background: ${color || "#E7EAF0"};
  border: 2px solid #D6E1FF;
  border-radius: 21px;
  margin-right: 30px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 560px;
  height: 560px;

  &.uploaded {
    img {
      display: inline;
    }

    svg {
      opacity: .5;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }


  img {
    display: none;
    height: 100%;
  }
`);

const StyledCreateTemplate = styled.div`
  padding: 45px 10% 45px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  > h1 {
    color: #262626;
    font-size: 42px;
    line-height: 51px;
    font-weight: 800;
    margin-bottom: 44px;
  }

  @media (max-width: 1100px) {
    padding: 45px 48px;
    > h1 {
      font-size: 32px;
      line-height: 39px;
    }

    ${Content} {
      flex-direction: column;

      ${Image} {
        margin-right: 0;
        height: 300px;
        margin-bottom: 40px;
        width: auto;
      }
    }
  }
`;


const StyledPhotoUploadSvg = styled(PhotoUploadSvg)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const CostWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const CostLabel = styled.div`
  color: #aeaeae;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.02em;
`;

const Cost = styled.div`
  display: flex;
  margin-top: 2px;
  align-items: center;
  font-size: 42px;
  font-weight: 800;
  color: #262626;
  line-height: 51px;
  letter-spacing: -0.1em;

  span {
    margin-left: 16px;
    color: #2361ff;
    font-weight: 600;
    font-size: 24px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2361FF;
  border-radius: 12px;
  color: #ffffff;
  border: none;
  padding: 24px 25px;
  height: auto;

  &:hover {
    opacity: .6;
    background: #2361FF;
    color: #ffffff;
  }

  &:active, &:focus {
    opacity: .8;
    background: #2361FF;
    color: #ffffff;
  }

  svg {
    margin-left: 90px;
  }
`;

const StyledUpload = styled(Upload)`
  .ant-upload {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  width: 100%;
  height: 100%;
  position: absolute;
`;

const FormWrap = styled.div`
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #414141;
  }
`;

const StyledForm = styled(Form)`

`;

type CreateTemplateProps = {}

function CreateTemplate({}: CreateTemplateProps) {

    const [file, setFile] = useState<any>("");
    const [color, setColor] = useState<any>("");
    const [form] = Form.useForm();

    const createTemplate = (fields: any) => {
        axios.post(`${api}/templates`, {
            ...fields,
            Price: parseFloat(fields.Price) * 100,
            ...{
                RawImage: file,
                BackgroundColor: color,
            }
        }).then(() => {
            message.success("Template created successfully!");
            form.resetFields();
            setFile("");
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const [image_, setImage_] = useState<any>("");

    const [cropper, setCropper] = useState<any>();

    const [modalVisible, setModalVisible] = useState(false);

    const [name, setName] = useState('');

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            // setCropData(cropper.getCroppedCanvas().toDataURL());
            setFile(cropper.getCroppedCanvas().toDataURL())
            setModalVisible(false);
        }
    };

    const onCollectionAdd = () => {
        axios.post(`${api}/collections`, {
            Name: name
        }).then(() => {
            setName("");
            store.dispatch(getCollections());
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })

    }

    const collections = useSelector(({pageReducer: {collections}}: any) => collections);

    useEffect(() => {
        store.dispatch(getCollections());
    }, [])

    return (
        <StyledCreateTemplate>
            <h1>Create a template</h1>
            <Modal onCancel={() => setModalVisible(false)} onOk={getCropData} visible={modalVisible}>
                <Cropper
                    style={{height: 400, width: "100%"}}
                    zoomTo={0.2}
                    aspectRatio={1}
                    preview=".img-preview"
                    src={image_}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    guides={true}
                />
            </Modal>

            <Content>
                <Image color={color} className={file !== "" ? "uploaded" : ""}>
                    <StyledUpload showUploadList={false} customRequest={(_: any) => {
                        setFile(_.file);
                        const reader = new FileReader();
                        reader.onloadend = function () {
                            // preview.src = reader.result;
                            // setFile(reader.result)
                            setImage_(reader.result);
                            setModalVisible(true);
                        }
                        if (_.file) {
                            reader.readAsDataURL(_.file);
                        }
                    }}>
                        <StyledPhotoUploadSvg/>
                    </StyledUpload>
                    <img src={file} alt=""/>
                </Image>
                <FormWrap>
                    <h2>Parameters</h2>
                    <StyledForm form={form} onFinish={createTemplate}>
                        <Form.Item name={"Name"}>
                            <StyledInput placeholder={"Name"}/>
                        </Form.Item>
                        <Form.Item name={"Description"}>
                            <StyledInput.TextArea placeholder={"Description"}/>
                        </Form.Item>
                        <Form.Item name={"Price"}>
                            <StyledInput type={"number"} suffix={<DollarSvg/>} placeholder={"Cost"}/>
                        </Form.Item>
                        <Form.Item name={"Type"}>
                            <StyledSelect suffixIcon={<ArrowCircleSvg/>} placeholder={"Type"}>
                                <Option value={"award"}>Award</Option>
                                <Option value={"diploma"}>Diploma</Option>
                            </StyledSelect>
                        </Form.Item>
                        {/*<Form.Item name={"Frequency"}>*/}
                        {/*    <StyledSelect suffixIcon={<ArrowCircleSvg/>} placeholder={"Rarity"}>*/}
                        {/*        <Option value={"Common"}>Common</Option>*/}
                        {/*        <Option value={"Uncommon"}>Uncommon</Option>*/}
                        {/*        <Option value={"Rare"}>Rare</Option>*/}
                        {/*        <Option value={"Very rare"}>Very rare</Option>*/}
                        {/*        <Option value={"Epic"}>Epic</Option>*/}
                        {/*        <Option value={"Legendary"}>Legendary</Option>*/}
                        {/*    </StyledSelect>*/}
                        {/*</Form.Item>*/}
                        <Form.Item name={"Visibility"}>
                            <StyledSelect suffixIcon={<ArrowCircleSvg/>} placeholder={"Visibility"}>
                                <Option value={"public"}>Public</Option>
                                <Option value={"private"}>Private</Option>
                            </StyledSelect>
                        </Form.Item>
                        <Form.Item name={"CollectionId"}>
                            <StyledSelect suffixIcon={<ArrowCircleSvg/>} allowClear clearIcon={<CrossCircleSvg/>}
                                          placeholder={"Collection"}
                                          dropdownRender={menu => (
                                              <>
                                                  {menu}
                                                  <Divider style={{margin: '8px 0'}}/>
                                                  <Space align="center" style={{padding: '0 8px 4px'}}>
                                                      <Input placeholder="Please enter item" value={name}
                                                             onChange={({target: {value}}: any) => setName(value)}/>
                                                      <Typography.Link
                                                          onClick={() => name !== "" ? onCollectionAdd() : message.error("Name field is empty!")}
                                                          style={{
                                                              whiteSpace: 'nowrap',
                                                              display: 'flex',
                                                              alignItems: 'center'
                                                          }}>
                                                          <PlusCircleSvg style={{marginRight: 10}}/> Add item
                                                      </Typography.Link>
                                                  </Space>
                                              </>
                                          )}>
                                {collections.map((collection: any) => <Option key={collection.Id}
                                                                              value={collection.Id}>{collection.Name}</Option>)}
                            </StyledSelect>
                        </Form.Item>
                        {/*<Form.Item name={"Collection"}>*/}
                        {/*    <StyledSelect suffixIcon={<PlusCircleSvg/>} mode={"multiple"} placeholder={"Collection"}>*/}
                        {/*        <Option value={1}>1</Option>*/}
                        {/*        <Option value={2}>2</Option>*/}
                        {/*    </StyledSelect>*/}
                        {/*</Form.Item>*/}
                        <Form.Item>
                            <ColorPicker onChange={(_color) => {
                                console.log(`%ccolor ${_color}`, `color:${_color}`)
                                setColor(_color);
                            }}/>
                        </Form.Item>
                        <Form.Item>
                            <FormFooter>
                                {/*<CostWrap>*/}
                                {/*    <CostLabel>Cost</CostLabel>*/}
                                {/*    <Cost>12<span>$</span></Cost>*/}
                                {/*</CostWrap>*/}
                                <StyledButton htmlType={"submit"}>Create<NextSvg/></StyledButton>
                            </FormFooter>
                        </Form.Item>
                    </StyledForm>
                </FormWrap>
            </Content>
        </StyledCreateTemplate>
    );
}

export default CreateTemplate;