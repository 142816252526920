import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import update from 'immutability-helper';
import ElementItem from "./ElementItem";

const StyledElementsList = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  padding: 18px 18px 20px;
  display: flex;
  flex-direction: column;
`;

type ElementsListProps = {
    images: any[],
    children: any,
    onChange: any
}


function ElementsList({images, children, onChange}: ElementsListProps) {

    const moveItem = useCallback((dragIndex, hoverIndex) => {
        onChange((prevItem:any) => update(prevItem, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, prevItem[dragIndex]],
            ],
        }));
    }, []);

    const onDrop = () => {
        console.log(images);
    }

    const renderItem = useCallback((item, index) => {
        return (<ElementItem key={index} index={index} onDrop={onDrop} moveItem={moveItem}>{item.element}</ElementItem>);
    }, []);

    useEffect(()=>{
        onChange(images)
        console.log(images);
    }, [images])

    return (
        <StyledElementsList>
            {images.map((item, index) => renderItem(item, index))}
            {children}
        </StyledElementsList>
    );
}

export default ElementsList;