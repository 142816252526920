import React from 'react';

function CrossCircleSvg(props: any) {
    return (
        <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="13" fill="white" stroke="#C6CFE5" strokeWidth="2"/>
            <rect x="16.8286" y="9.75684" width="2" height="10" rx="1" transform="rotate(45 16.8286 9.75684)"
                  fill="#3F75FF"/>
            <rect x="18.2427" y="16.8281" width="2" height="10" rx="1" transform="rotate(135 18.2427 16.8281)"
                  fill="#3F75FF"/>
        </svg>
    );
}

export default CrossCircleSvg;