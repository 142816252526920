import React from 'react';

function BankSvg(props: any) {
    return (
        <svg {...props} width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.5 12C28.697 12 28.8921 11.9613 29.0741 11.8859C29.2561 11.8106 29.4215 11.7001 29.5608 11.5608C29.7001 11.4215 29.8106 11.2561 29.8859 11.0741C29.9613 10.8921 30.0001 10.697 30 10.5V6C30.0001 5.68526 29.9011 5.37847 29.7171 5.12316C29.533 4.86785 29.2733 4.67699 28.9746 4.57764L15.4746 0.0776365C15.1665 -0.0249023 14.8335 -0.0249023 14.5254 0.0776365L1.02539 4.57764C0.72674 4.67699 0.466963 4.86785 0.282909 5.12316C0.0988548 5.37847 -0.000129197 5.68526 1.26561e-07 6V10.5C-5.11127e-05 10.697 0.0387127 10.8921 0.114077 11.0741C0.189441 11.2561 0.299928 11.4215 0.439226 11.5608C0.578524 11.7001 0.743903 11.8106 0.925914 11.8859C1.10793 11.9613 1.303 12 1.5 12H3V22.7764C2.12511 23.0846 1.36705 23.656 0.829901 24.4122C0.292754 25.1685 0.00285371 26.0724 1.26561e-07 27V30C-5.11127e-05 30.197 0.0387127 30.3921 0.114077 30.5741C0.189441 30.7561 0.299928 30.9215 0.439226 31.0608C0.578524 31.2001 0.743903 31.3106 0.925914 31.3859C1.10793 31.4613 1.303 31.5 1.5 31.5H28.5C28.697 31.5 28.8921 31.4613 29.0741 31.3859C29.2561 31.3106 29.4215 31.2001 29.5608 31.0608C29.7001 30.9215 29.8106 30.7561 29.8859 30.5741C29.9613 30.3921 30.0001 30.197 30 30V27C29.9971 26.0724 29.7072 25.1685 29.1701 24.4122C28.633 23.656 27.8749 23.0846 27 22.7764V12H28.5ZM27 28.5H3V27C3.0004 26.6023 3.15856 26.221 3.43978 25.9398C3.721 25.6586 4.1023 25.5004 4.5 25.5H25.5C25.8977 25.5004 26.279 25.6586 26.5602 25.9398C26.8414 26.221 26.9996 26.6023 27 27V28.5ZM6 22.5V12H9V22.5H6ZM12 22.5V12H18V22.5H12ZM21 22.5V12H24V22.5H21ZM3 9V7.08105L15 3.08055L27 7.08105V9H3Z"
                fill="#6780BE"/>
        </svg>
    );
}

export default BankSvg;