import React from 'react';

function EyeEditSvg(props:any) {
    return (
        <svg {...props} width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.15385 7.3076C6.15385 5.18343 7.87583 3.46144 10 3.46144C12.1242 3.46144 13.8462 5.18343 13.8462 7.3076C13.8462 9.43177 12.1242 11.1538 10 11.1538C7.87583 11.1538 6.15385 9.43177 6.15385 7.3076ZM10 4.99991C8.7255 4.99991 7.69231 6.03309 7.69231 7.3076C7.69231 8.5821 8.7255 9.61529 10 9.61529C11.2745 9.61529 12.3077 8.5821 12.3077 7.3076C12.3077 6.03309 11.2745 4.99991 10 4.99991Z"
                  fill="#2361FF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.12659 5.91924C1.69673 6.53866 1.53846 7.02319 1.53846 7.3076C1.53846 7.59201 1.69673 8.07653 2.12659 8.69595C2.54212 9.29472 3.15977 9.94378 3.9412 10.5435C5.50747 11.7456 7.65482 12.6922 10 12.6922C12.3452 12.6922 14.4925 11.7456 16.0588 10.5435C16.8402 9.94378 17.4579 9.29472 17.8734 8.69595C18.3033 8.07653 18.4615 7.59201 18.4615 7.3076C18.4615 7.02319 18.3033 6.53866 17.8734 5.91924C17.4579 5.32048 16.8402 4.67141 16.0588 4.07167C14.4925 2.86957 12.3452 1.92298 10 1.92298C7.65482 1.92298 5.50747 2.86957 3.9412 4.07167C3.15977 4.67141 2.54212 5.32048 2.12659 5.91924ZM3.00452 2.85122C4.77913 1.48922 7.24716 0.384521 10 0.384521C12.7528 0.384521 15.2209 1.48922 16.9955 2.85122C17.8845 3.53353 18.6187 4.29472 19.1373 5.04211C19.6417 5.76884 20 6.56637 20 7.3076C20 8.04883 19.6417 8.84635 19.1373 9.57309C18.6187 10.3205 17.8845 11.0817 16.9955 11.764C15.2209 13.126 12.7528 14.2307 10 14.2307C7.24716 14.2307 4.77913 13.126 3.00452 11.764C2.11551 11.0817 1.38134 10.3205 0.86267 9.57309C0.358329 8.84635 0 8.04883 0 7.3076C0 6.56637 0.358329 5.76884 0.86267 5.04211C1.38134 4.29472 2.11551 3.53353 3.00452 2.85122Z"
                  fill="#2361FF"/>
        </svg>
    );
}

export default EyeEditSvg;