import React from 'react';

function PenEditSvg(props: any) {
    return (
        <svg {...props} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 16.9999H7.24C7.37161 17.0007 7.50207 16.9755 7.62391 16.9257C7.74574 16.8759 7.85656 16.8026 7.95 16.7099L14.87 9.77994L17.71 6.99994C17.8037 6.90698 17.8781 6.79637 17.9289 6.67452C17.9797 6.55266 18.0058 6.42195 18.0058 6.28994C18.0058 6.15793 17.9797 6.02722 17.9289 5.90536C17.8781 5.7835 17.8037 5.6729 17.71 5.57994L13.47 1.28994C13.377 1.19621 13.2664 1.12182 13.1446 1.07105C13.0227 1.02028 12.892 0.994141 12.76 0.994141C12.628 0.994141 12.4973 1.02028 12.3754 1.07105C12.2536 1.12182 12.143 1.19621 12.05 1.28994L9.23 4.11994L2.29 11.0499C2.19732 11.1434 2.12399 11.2542 2.07423 11.376C2.02446 11.4979 1.99924 11.6283 2 11.7599V15.9999C2 16.2652 2.10536 16.5195 2.29289 16.707C2.48043 16.8946 2.73478 16.9999 3 16.9999ZM12.76 3.40994L15.59 6.23994L14.17 7.65994L11.34 4.82994L12.76 3.40994ZM4 12.1699L9.93 6.23994L12.76 9.06994L6.83 14.9999H4V12.1699ZM19 18.9999H1C0.734784 18.9999 0.48043 19.1053 0.292893 19.2928C0.105357 19.4804 0 19.7347 0 19.9999C0 20.2652 0.105357 20.5195 0.292893 20.707C0.48043 20.8946 0.734784 20.9999 1 20.9999H19C19.2652 20.9999 19.5196 20.8946 19.7071 20.707C19.8946 20.5195 20 20.2652 20 19.9999C20 19.7347 19.8946 19.4804 19.7071 19.2928C19.5196 19.1053 19.2652 18.9999 19 18.9999Z"
                fill="#2361FF"/>
        </svg>
    );
}

export default PenEditSvg;