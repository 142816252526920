import React from 'react';

function LikeSvg(props: any) {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.6 16.16C38.038 15.4853 37.3348 14.9421 36.54 14.5689C35.7451 14.1957 34.8781 14.0015 34 14H24.88L26 11.14C26.4659 9.88773 26.621 8.54118 26.4521 7.21581C26.2832 5.89044 25.7954 4.62582 25.0304 3.53043C24.2654 2.43503 23.2461 1.54156 22.0599 0.926653C20.8737 0.311747 19.5561 -0.00624093 18.22 -3.28878e-05C17.8353 0.000769836 17.459 0.112518 17.1361 0.321813C16.8133 0.531108 16.5577 0.829067 16.4 1.17997L10.7 14H6C4.4087 14 2.88258 14.6321 1.75736 15.7573C0.632141 16.8825 0 18.4087 0 20V34C0 35.5913 0.632141 37.1174 1.75736 38.2426C2.88258 39.3678 4.4087 40 6 40H31.46C32.8636 39.9995 34.2227 39.5069 35.3006 38.608C36.3786 37.7091 37.1073 36.4607 37.36 35.08L39.9 21.08C40.0571 20.2147 40.0221 19.3255 39.7974 18.4753C39.5728 17.6251 39.164 16.8347 38.6 16.16ZM10 36H6C5.46957 36 4.96086 35.7893 4.58579 35.4142C4.21071 35.0391 4 34.5304 4 34V20C4 19.4695 4.21071 18.9608 4.58579 18.5858C4.96086 18.2107 5.46957 18 6 18H10V36ZM36 20.36L33.46 34.36C33.3748 34.8259 33.127 35.2464 32.7608 35.5468C32.3945 35.8471 31.9336 36.0077 31.46 36H14V16.42L19.44 4.17997C20 4.34322 20.52 4.62078 20.9673 4.99513C21.4146 5.36949 21.7794 5.8325 22.0388 6.35495C22.2981 6.8774 22.4464 7.44795 22.4741 8.03057C22.5019 8.61319 22.4085 9.19523 22.2 9.73997L21.14 12.6C20.9141 13.2045 20.8379 13.8547 20.9177 14.495C20.9976 15.1354 21.2312 15.7469 21.5987 16.2774C21.9661 16.808 22.4564 17.2417 23.0278 17.5416C23.5992 17.8416 24.2347 17.9988 24.88 18H34C34.2938 17.9995 34.5841 18.0638 34.8503 18.1882C35.1165 18.3126 35.352 18.4942 35.54 18.72C35.7327 18.9426 35.8738 19.2051 35.9533 19.4886C36.0328 19.772 36.0488 20.0696 36 20.36Z"
                fill="#7EA2FF"/>
        </svg>
    );
}

export default LikeSvg;