import React from 'react';

function ViewSvg(props:any) {
    return (
        <svg {...props} width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.30619 7.30769C6.30619 5.18352 8.02817 3.46154 10.1523 3.46154C12.2765 3.46154 13.9985 5.18352 13.9985 7.30769C13.9985 9.43186 12.2765 11.1538 10.1523 11.1538C8.02817 11.1538 6.30619 9.43186 6.30619 7.30769ZM10.1523 5C8.87784 5 7.84465 6.03319 7.84465 7.30769C7.84465 8.58219 8.87784 9.61538 10.1523 9.61538C11.4268 9.61538 12.46 8.58219 12.46 7.30769C12.46 6.03319 11.4268 5 10.1523 5Z"
                  fill="#2361FF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.27893 5.91933C1.84907 6.53875 1.69081 7.02328 1.69081 7.30769C1.69081 7.5921 1.84907 8.07663 2.27893 8.69604C2.69447 9.29481 3.31211 9.94388 4.09355 10.5436C5.65981 11.7457 7.80717 12.6923 10.1523 12.6923C12.4975 12.6923 14.6449 11.7457 16.2111 10.5436C16.9926 9.94388 17.6102 9.29481 18.0258 8.69604C18.4556 8.07663 18.6139 7.5921 18.6139 7.30769C18.6139 7.02328 18.4556 6.53875 18.0258 5.91933C17.6102 5.32057 16.9926 4.6715 16.2111 4.07176C14.6449 2.86966 12.4975 1.92307 10.1523 1.92307C7.80717 1.92307 5.65981 2.86966 4.09355 4.07176C3.31211 4.6715 2.69447 5.32057 2.27893 5.91933ZM3.15686 2.85131C4.93148 1.48931 7.39951 0.384613 10.1523 0.384613C12.9052 0.384613 15.3732 1.48931 17.1478 2.85131C18.0368 3.53362 18.771 4.29481 19.2897 5.0422C19.794 5.76893 20.1523 6.56646 20.1523 7.30769C20.1523 8.04892 19.794 8.84644 19.2897 9.57318C18.771 10.3206 18.0368 11.0818 17.1478 11.7641C15.3732 13.1261 12.9052 14.2308 10.1523 14.2308C7.39951 14.2308 4.93148 13.1261 3.15686 11.7641C2.26785 11.0818 1.53369 10.3206 1.01501 9.57318C0.510673 8.84644 0.152344 8.04892 0.152344 7.30769C0.152344 6.56646 0.510673 5.76893 1.01501 5.0422C1.53369 4.29481 2.26785 3.53362 3.15686 2.85131Z"
                  fill="#2361FF"/>
        </svg>
    );
}

export default ViewSvg;