import React from 'react';

function BackSvg(props: any) {
    return (
        <svg {...props} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 6L1.29289 6.70711L0.585785 6L1.29289 5.29289L2 6ZM7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16L7 18ZM6.29289 11.7071L1.29289 6.70711L2.70711 5.29289L7.70711 10.2929L6.29289 11.7071ZM1.29289 5.29289L6.29289 0.292892L7.70711 1.70711L2.70711 6.70711L1.29289 5.29289ZM2 5L12.5 5L12.5 7L2 7L2 5ZM12.5 18L7 18L7 16L12.5 16L12.5 18ZM19 11.5C19 15.0898 16.0898 18 12.5 18L12.5 16C14.9853 16 17 13.9853 17 11.5L19 11.5ZM12.5 5C16.0899 5 19 7.91015 19 11.5L17 11.5C17 9.01472 14.9853 7 12.5 7L12.5 5Z"
                fill="#2361FF"/>
        </svg>

    );
}

export default BackSvg;