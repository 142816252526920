import React from 'react';

function PhoneSvg(props: any) {
    return (
        <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.95647 8.74034C5.08538 13.3761 8.87375 17.0616 13.5832 19.0587L13.598 19.065L14.5078 19.4705C15.6664 19.9866 17.0281 19.62 17.7709 18.592L19.2888 16.4912C19.3815 16.3628 19.3576 16.1844 19.2344 16.085L16.5845 13.9472C16.4528 13.8409 16.259 13.8657 16.1583 14.0016L15.1269 15.3933C14.8764 15.7313 14.4241 15.8488 14.0408 15.6753C10.5194 14.0817 7.68963 11.2519 6.09598 7.73045C5.92254 7.34719 6.03998 6.89486 6.37796 6.64439L7.76965 5.61304C7.90563 5.51226 7.93039 5.31855 7.82411 5.18682L5.68599 2.53651C5.58662 2.41333 5.40825 2.3894 5.27993 2.48204L3.16771 4.00687C2.13287 4.75393 1.76844 6.12712 2.29666 7.289L2.95576 8.73879C2.95599 8.73931 2.95623 8.73982 2.95647 8.74034ZM12.8781 20.7004C7.76103 18.5283 3.64526 14.5225 1.33204 9.48431L1.33064 9.48127L0.670162 8.02844C-0.210199 6.09197 0.39719 3.80332 2.12192 2.55822L4.23413 1.03339C5.13239 0.384936 6.38096 0.552414 7.07657 1.41466L9.2147 4.06497C9.95862 4.9871 9.78535 6.34308 8.83345 7.04851L8.03599 7.63949C9.38305 10.2533 11.518 12.3882 14.1318 13.7353L14.7228 12.9378C15.4282 11.9859 16.7842 11.8127 17.7063 12.5566L20.3563 14.6944C21.2188 15.3902 21.3861 16.6393 20.737 17.5376L19.2191 19.6384C17.9811 21.3518 15.7116 21.9628 13.7807 21.1025L12.8781 20.7004Z"
                  fill="#2361FF"/>
        </svg>
    );
}

export default PhoneSvg;