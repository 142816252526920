import React from 'react';

function Google2Svg(props: any) {
    return (
        <svg {...props} width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42.6175" height="42.6175" rx="8" fill="#A6A6A6"/>
            <path
                d="M21.4834 16.5555C22.6051 16.5366 23.6891 16.9606 24.5003 17.7357L26.7639 15.4721C25.3354 14.1304 23.443 13.3938 21.4834 13.4165C18.4994 13.4154 15.7701 15.0979 14.4307 17.7644L17.0674 19.8092C17.6891 17.8897 19.4659 16.5806 21.4834 16.5555Z"
                fill="white"/>
            <path
                d="M14.4312 17.7644C13.312 19.9947 13.312 22.6227 14.4312 24.853L17.0679 22.8082C16.7379 21.8358 16.7379 20.7816 17.0679 19.8092L14.4312 17.7644Z"
                fill="white"/>
            <path
                d="M24.1559 25.3085C21.943 26.7289 18.9975 26.0865 17.5771 23.8735C17.3636 23.5409 17.1924 23.183 17.0673 22.8081L14.4307 24.8529C15.7701 27.5193 18.4994 29.2019 21.4834 29.2007C23.4046 29.2528 25.273 28.5689 26.7065 27.2887L24.1559 25.3085Z"
                fill="white"/>
            <path
                d="M28.9163 19.8738H21.4834V22.9266H25.7308C25.5532 23.9024 24.9842 24.763 24.156 25.3086C24.1534 25.3102 24.1508 25.3114 24.1483 25.313C24.1508 25.3114 24.1534 25.3102 24.156 25.3086L26.7065 27.2888L26.7065 27.2888C28.2773 25.7759 29.1326 23.6677 29.0598 21.4881C29.0603 20.9468 29.0122 20.4065 28.9163 19.8738Z"
                fill="white"/>
        </svg>
    );
}

export default Google2Svg;