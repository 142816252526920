import React from 'react';

function CrossSvg(props: any) {
    return (
        <svg {...props} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8131 27.1872L27.1875 14.8128" stroke="black" strokeWidth="2.625" strokeLinecap="round"/>
            <path d="M14.8131 14.8128L27.1875 27.1872" stroke="black" strokeWidth="2.625" strokeLinecap="round"/>
        </svg>
    );
}

export default CrossSvg;