import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import PhoneSvg from "../images/PhoneSvg";
import MailSvg from "../images/MailSvg";
import BalloonSvg from "../images/BalloonSvg";
import FacebookSvg from "../images/FacebookSvg";
import LinkedInSvg from "../images/LinkedInSvg";
import {IconButton} from "../components/styled/IconButton";
import ShareIconSvg from "../images/ShareIconSvg";
import testBackground from "../images/testBackground.png";
import AwardsLogo from "../images/AwardsLogo";
import {useParams} from "react-router-dom";
import axios from "axios";
import {api} from "../config";
import {message, Modal} from "antd";
import nouser from "../images/nouser.png";
import History from "../utils/history";
import moment from "moment";
import {QrImg} from "../components/styled/QrImg";

const Left = styled.div`
  display: flex;
  margin-right: 30px;
  max-width: 580px;
  z-index: 1;
  width: 100%;
`;
const Company = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #D4DFFB;
  box-shadow: 0 0 18px rgba(27, 26, 26, 0.05);
  border-radius: 12px;
  padding: 42px 16px;
`;
const CompanyHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 40px;
  padding: 0 26px 42px;
`;
const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 16px;
  flex: 0 0 auto;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const Greeting = styled.div`
  color: #757575;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 6px;
`;
const Title = styled.div`
  font-size: 42px;
  color: #262626;
  font-weight: 600;
  line-height: 48px;
  word-wrap: anywhere;
`;
const About = styled.div`
  padding: 0 26px;

  p {
    color: #474747;
    font-size: 16px;
    line-height: 150%;
    word-wrap: anywhere;
  }
`;
const Contact = styled.div`
  padding: 0 26px;
  display: flex;
  flex-direction: column;
  margin-top: 42px;
`;
const ContactTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h1 {
    margin: 0;
  }
`;
const Share = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #656565;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  ${IconButton} {
    margin-left: 8px;
  }
`;
const ContactContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ContactLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 60%;

  span {
    display: flex;
    color: #7b7b7b;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    word-break: break-word;

    &:not(:last-child) {
      margin-bottom: 28px;
    }

    svg {
      flex: 0 0 auto;
      margin-right: 18px;
    }
  }
`;
const ContactRight = styled.div`
  display: flex;
  align-items: center;

  a {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const Right = styled.div`
  display: flex;
  width: 100%;
  z-index: 1;
`;

const RightTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const Statistic = styled.div`
  padding: 40px 80px;
  display: flex;
  background: #FFFFFF;
  border: 1px solid #D4DFFB;
  box-shadow: 0 0 18px rgba(27, 26, 26, 0.05);
  border-radius: 12px;
`;
const StatisticItem = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 80px;
  }
`;
const StatisticValue = styled.div`
  color: #2361ff;
  font-size: 42px;
  font-weight: 800;
  line-height: 51px;
`;
const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 310px;
  width: 100%;
  object-fit: cover;
  z-index: 0;
`;

const StatisticLabel = styled.div`
  color: #7b7b7b;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-top: 6px;
`;
const Content = styled.div`
  padding: 72px 60px;
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E4E4E4;
  padding: 44px 60px;
  margin-top: auto;

  svg {
    cursor: pointer;

    path, rect {
      fill: #CBD3E1;
    }
  }
`;

const FooterText = styled.div`
  color: #cbd3e1;
  font-size: 14px;
  line-height: 54px;
`;

const MobileTop = styled.div`
  display: none;
`;

const StatisticMobile = styled.div`
  display: none;
  padding-bottom: 32px;
  margin: 0 26px 24px;
  border-bottom: 1px solid #e4e4e4;
  justify-content: space-between;

  ${StatisticItem} {
    &:not(:last-child) {
      margin-right: 10px;
    }

    ${StatisticValue} {
      font-size: 34px;
      line-height: 41px;
    }
  }
`;

const StyledPublicCompany = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  position: relative;

  h1 {
    color: #262626;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 24px;
    line-height: 34px;
  }

  @media (max-width: 1100px) {
    padding: 0 48px;
    ${Background} {
      height: 610px;
    }

    ${Content} {
      flex-direction: column;
      align-items: center;
      padding: 72px 0;

      ${MobileTop} {
        z-index: 1;
        width: 100%;
        max-width: 580px;
        margin-bottom: 200px;
        display: flex;
        background: #FFFFFF;
        border: 1px solid #D4DFFB;
        box-shadow: 0 0 18px rgba(27, 26, 26, 0.05);
        padding: 20px 44px;
        border-radius: 12px;
        align-items: center;
        justify-content: center;

        ${Avatar} {
          height: 82px;
          width: 82px;
        }

        ${TitleWrap} {
          ${Greeting} {
            font-size: 14px;
            margin-bottom: 4px;
            line-height: 17px;
          }

          ${Title} {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }

      ${Left} {
        width: 100%;
        margin-right: 0;

        ${Company} {
          width: 100%;

          ${StatisticMobile} {
            display: flex;
          }

          ${CompanyHeader} {
            display: none;
          }

          ${Contact} {
            ${ContactTop} {
              ${Share} {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }

      ${Right} {
        ${RightTop} {
          display: none;
        }
      }
    }

    ${Footer} {
      flex-direction: column;
      padding-bottom: 22px;
    }
  }
  @media (max-width: 400px) {
    padding: 0 8px;
  }
`;

function PublicCompany() {

    const {publicId, id} = useParams();

    const companyId = publicId || id;

    const [company, setCompany] = useState<any>({});

    const getQr = () => {
        new (Modal.success as any)({
            maskClosable: true,
            icon: null,
            content: <QrImg src={`${api}/groups/${companyId}/share`} alt={"qr"}/>
        })
    }

    const getCompany = () => {
        axios(`${api}/public/group/${companyId}`).then(({data}) => {
            setCompany(data);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    useEffect(() => {
        getCompany();
    }, [])

    return (
        <StyledPublicCompany>
            <Content>
                <MobileTop>
                    <Avatar>
                        <img
                            src={company.Photo ? `/${company.Photo}` : nouser}
                            alt=""/>
                    </Avatar>
                    <TitleWrap>
                        <Greeting>This is best company!</Greeting>
                        <Title>{company.Name}</Title>
                    </TitleWrap>
                </MobileTop>
                <Left>
                    <Company>
                        <CompanyHeader>
                            <Avatar>
                                <img
                                    src={company.Photo ? `/${company.Photo}` : nouser}
                                    alt=""/>
                            </Avatar>
                            <TitleWrap>
                                <Greeting>This is best company!</Greeting>
                                <Title>{company.Name}</Title>
                            </TitleWrap>
                        </CompanyHeader>
                        <StatisticMobile>
                            <StatisticItem>
                                <StatisticValue>-</StatisticValue>
                                <StatisticLabel>Awarded users</StatisticLabel>
                            </StatisticItem>
                            <StatisticItem>
                                <StatisticValue>-</StatisticValue>
                                <StatisticLabel>Experience</StatisticLabel>
                            </StatisticItem>
                            <StatisticItem>
                                <StatisticValue>Best</StatisticValue>
                                <StatisticLabel>On the best</StatisticLabel>
                            </StatisticItem>
                        </StatisticMobile>
                        <About>
                            <h1>About us</h1>
                            <p>{company.Description}</p>
                        </About>
                        <Contact>
                            <ContactTop>
                                <h1>Contact</h1>
                                <Share onClick={getQr}><span>Share company</span> <IconButton><ShareIconSvg/></IconButton></Share>
                            </ContactTop>
                            <ContactContent>
                                <ContactLeft>
                                    {company.Phone && <span><PhoneSvg/>{company.Phone}</span>}
                                    {company.Email && <span><MailSvg/>{company.Email}</span>}
                                    {company.Address && <span><BalloonSvg/>{company.Address}</span>}
                                </ContactLeft>
                                <ContactRight>
                                    {company.FacebookLink && <a target={"_blank"} rel={"noopener noreferrer nofollow"}
                                                                href={company.FacebookLink}><FacebookSvg/></a>}
                                    {company.LinkedinLink && <a target={"_blank"} rel={"noopener noreferrer nofollow"}
                                                                href={company.LinkedinLink}><LinkedInSvg/></a>}
                                </ContactRight>
                            </ContactContent>
                        </Contact>
                    </Company>
                </Left>
                <Right>
                    <RightTop>
                        <Statistic>
                            <StatisticItem>
                                <StatisticValue>-</StatisticValue>
                                <StatisticLabel>Awarded users</StatisticLabel>
                            </StatisticItem>
                            <StatisticItem>
                                <StatisticValue>-</StatisticValue>
                                <StatisticLabel>Experience</StatisticLabel>
                            </StatisticItem>
                            <StatisticItem>
                                <StatisticValue>Best</StatisticValue>
                                <StatisticLabel>On the best</StatisticLabel>
                            </StatisticItem>
                        </Statistic>
                    </RightTop>
                </Right>
            </Content>
            <Footer>
                <AwardsLogo onClick={() => History.push("/")}/>
                <FooterText>N- Awards copyright. 2021-{moment().year()}</FooterText>
            </Footer>
            <Background src={testBackground}/>
        </StyledPublicCompany>
    );
}

export default PublicCompany;