import React, {useState} from 'react';
import styled from "styled-components";
import Navigation from "../components/Navigation";
import award from "../images/Award1.png";
import award2 from "../images/Award2.svg";
import discount from "../images/discount.png";
import phone from "../images/Phone.png";
import Receipt from "../images/Receipt";
import Medal from "../images/Medal";
import Zoom from "../images/Zoom";
import AwardsLogo from "../images/AwardsLogo";
import QuotesSVG from "../images/QuotesSVG";
import LikeSvg from "../images/LikeSvg";
import LockSvg from "../images/LockSvg";
import SpeedSvg from "../images/SpeedSvg";
import Footer from "../components/Footer";
import UserSvg from "../images/UserSvg";
import History from "../utils/history";
import {useSelector} from "react-redux";
import MobileMenu from "../components/MobileMenu";

const Button = styled.button`
  background: #2361FF;
  border-radius: 14px;
  padding: 26px 100px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: auto;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }

  &:active {
    opacity: .9;
  }
`;


const QuoteText = styled.div`
  margin-left: 15px;
  display: flex;
  width: 100%;

  svg {
    margin-right: 20px;
    flex: 1 0 auto;
  }

  &:after {
    content: '';
    display: flex;
    width: 100%;
    border-bottom: 1px solid #96b3ff;
    transform: translateY(-50%);
    margin-right: 33px;
    margin-left: 15px;
  }
`;

const Quote = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(265.24deg, #739AFF 17.7%, #5C89FF 66.86%);
  padding: 32px 50px;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  border-radius: 12px;
  margin: 80px 220px 0 220px;

  > svg {
    flex: 1 0 auto;

    &:last-child {
      path, rect {
        fill: white;
      }
    }

`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  img {
    object-fit: contain;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 145px;

  ${Button} {
    margin-bottom: 20px;
  }

  p {
    font-size: 22px;
    font-weight: 500;
    color: #555555;
    margin-bottom: 82px;

    span {
      color: #2361ff;
    }
  }
`;

const TopBlock = styled.div`
  background: linear-gradient(90deg, #F8F8F8 0%, #FBFBFB 18.23%, #F3F7FF 39.15%, #FFFFFF 50.52%, #FCFDFF 77.09%, #F4F7FF 100%);
  padding: 28px 220px 0 220px;

  h1 {
    font-size: 64px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }
`;

const MobileButton = styled(Button)`
  display: none;
`;

const WhyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Promo = styled.div`
  display: flex;
  color: #818181;
  font-size: 24px;
  max-width: 77%;
  margin-top: 45px;

  img {
    margin-right: 20px;
  }
`;

const WhyContent = styled.div`
  display: flex;
  align-items: flex-start;

  > img {
    position: relative;
    right: -25px;
    z-index: -1;
  }
`;

const Why = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #dbe5ff;
  border-radius: 12px;
  padding: 100px 50px 0 50px;
  background: white;
`;

const WhyBlock = styled.div`
  width: 48%;
  margin-bottom: 100px;
`;

const WhyTitle = styled.div`
  color: #222222;
  font-weight: 600;
  font-size: 20px;
  margin: 24px 0;
`;

const WhyText = styled.div`
  color: #636363;
  font-weight: 500;
  font-size: 18px;
`;

const Steps = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
`;

const StepNumber = styled.div`
  font-size: 48px;
  color: #2361ff;
  padding-bottom: 5px;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 15px;
  font-weight: 600;
`;

const StepText = styled.div`
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  width: 80%;
`;

const Banner = styled.div`
  background: linear-gradient(106.64deg, #F8F8F8 -1.27%, #F7F8F8 7.36%, #F3F7FF 103.55%);
  border-radius: 12px;
  display: flex;
  position: relative;
  margin-top: 200px;
  margin-bottom: 120px;

  img {
    max-width: 40%;
    object-fit: contain;
    position: absolute;
    top: -20px;
    right: 0;
    height: calc(100% + 20px);
    overflow: hidden;
    border-radius: 12px;
  }
`;

const Block = styled.div`
  padding: 82px 220px 0 220px;

  h1 {
    text-align: center;
    font-size: 42px;
    color: #222222;
    font-weight: bold;
    margin-bottom: 82px;
  }
`;

const Statement = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div`
  margin-bottom: 22px;
`;

const Title = styled.div`
  color: #222222;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const StatementText = styled.div`
  color: #636363;
  font-weight: 500;
  font-size: 18px;
`;


const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 68px 0 68px 150px;
  width: 55%;

  h1 {
    color: #222222;
    font-size: 86px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: start;
  }

  p {
    color: #222222;
    font-size: 32px;
    margin-bottom: 74px;
  }
`;

const ForWhat = styled.div`
  display: flex;

  ${Statement} {
    &:not(:last-child) {
      border-right: 1px solid #d3d3d3;
      padding-right: 100px;
    }

    &:not(:first-child) {
      padding-left: 100px;
    }
  }
`;

const StyledMainPage = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  img {
    overflow: hidden;
  }

  @media (max-width: 1480px) {
    ${TopBlock} {
      padding: 28px 120px 0 120px;
    }

    ${Quote} {
      margin: 80px 120px 0 120px;
    }

    ${WhyContent} {
      > img {
        display: none;
      }
    }

    ${Why} {
      flex-direction: column;

      ${WhyBlock} {
        width: 100%;
      }
    }

    ${ForWhat} {
      flex-direction: column;

      ${Statement} {
        padding: 0;
        border: 0;
      }
    }

    ${Banner} {
      ${LeftSide} {
        padding: 68px 120px 68px 120px;
        width: 100%;
      }

      img {
        display: none;
      }
    }

    ${Block} {
      padding: 28px 120px 0 120px;
    }
  }
  @media (max-width: 1750px) {
    ${Text} {
      margin-right: 0;
    }

    ${TopBlock}, ${Why} {
      img {
        display: none;
      }
    }
  }

  @media (max-width: 820px) {
    ${Block} {
      padding: 82px 36px 0 36px;
    }

    ${Banner} {
      margin-top: 100px;

      img {
        object-fit: contain;
        overflow: hidden;
        max-width: 80%;
        left: 50%;
        transform: translateX(-40%) translateY(-65%);
        z-index: -1;
        top: 0;
        height: auto;
      }

      ${LeftSide} {
        padding: 32px 44px;
        width: 100%;

        h1 {
          font-size: 42px;
        }

        p {
          font-size: 20px;
          margin-bottom: 32px;
        }
      }
    }

    ${Steps} {
      flex-direction: column;

      ${Step} {
        width: 100%;
      }
    }

    ${Quote} {
      margin: 28px 36px 0 36px;
      flex-direction: column-reverse;
      align-items: flex-start;

      svg {
        margin-bottom: 24px;
      }

      ${QuoteText} {
        display: flex;
        font-size: 16px;
        margin: 0;
        border-top: 1px solid #96B3FF;
        padding-top: 25px;

        svg {
          margin-right: 20px;
          position: relative;
          top: 6px;
        }

        ::after {
          display: none;
        }
      }
    }

    ${Promo} {
      font-size: 18px;
      overflow: hidden;
      max-width: none;

      div {
        width: 100%;
      }

      img {
        object-fit: contain;
        overflow: hidden;
      }
    }

    ${Button} {
      font-size: 14px;
      padding: 26px 10px;
      width: 100%;
    }

    ${TopBlock} {
      padding: 28px 80px 0 80px;

      img {
        display: block;
      }

      ${Button} {
        display: none;
      }

      ${MobileButton} {
        display: flex;
        top: -10px;
        position: relative;
      }

      ${Text} {
        margin-right: 0;
      }

      ${Content} {
        flex-direction: column;

        img {
          width: 100%;
        }

        h1 {
          font-size: 27px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 550px) {
    ${TopBlock} {
      padding: 28px 30px 0 30px;
    }

    ${Promo} {
      flex-direction: column;

      img {
        width: 100%;
      }
    }
  }
`;

function MainPage() {
    const userData = useSelector(({loginReducer: {userData}}: any) => userData);
    const [menuOpened, setMenuOpened] = useState<boolean>(false);

    return (
        <StyledMainPage>
            {menuOpened && <MobileMenu onBurgerClick={() => setMenuOpened(!menuOpened)}/>}
            <TopBlock>
                <Navigation onBurgerClick={() => setMenuOpened(!menuOpened)}/>
                <Content>
                    <Text>
                        <h1>All achievements and awards in digital world</h1>
                        <p>Service through which you can create <span>awards and certificates as NFT tokens</span>
                        </p>
                        <Button onClick={() => userData.Login ? History.push("/home") : History.push("/signup")}>Try it</Button>
                    </Text>
                    <img src={award} alt="award"/>
                    <MobileButton onClick={() => userData.Login ? History.push("/home") : History.push("/signup")}>Try it</MobileButton>
                </Content>
            </TopBlock>
            <Block>
                <h1 id={"forwhat"}>For what</h1>
                <ForWhat>
                    <Statement>
                        <Icon><Receipt/></Icon>
                        <Title>Consumption reduction</Title>
                        <StatementText>No more printing waste paper or useless creation</StatementText>
                    </Statement>
                    <Statement>
                        <Icon><Medal/></Icon>
                        <Title>Competitive and collectible point</Title>
                        <StatementText>Employees will be able to compete by collecting awards for their
                            merits</StatementText>
                    </Statement>
                    <Statement>
                        <Icon><Zoom/></Icon>
                        <Title>Convenient platform for tracking employee progress</Title>
                        <StatementText>When applying for a job, it is easy to evaluate a candidate</StatementText>
                    </Statement>
                </ForWhat>
            </Block>
            <Quote>
                <QuoteText>
                    <QuotesSVG/>
                    The future is already here, the digital world has become no less significant than the real one.
                    It's
                    time to replace physical rewards with digital ones
                </QuoteText>
                <AwardsLogo/>
            </Quote>
            <Block>
                <h1 id={"why"}>Why it is amazing?</h1>
                <WhyContent>
                    <img src={award2} alt="award2"/>
                    <WhyWrap>
                        <Why>
                            <WhyBlock>
                                <LikeSvg/>
                                <WhyTitle>Better than paper options</WhyTitle>
                                <WhyText>Using digital versions is much more convenient for both users and
                                    companies</WhyText>
                            </WhyBlock>
                            <WhyBlock>
                                <LockSvg/>
                                <WhyTitle>These documents cannot be forged.</WhyTitle>
                                <WhyText>The service is based on the technology of non-fungible NFT tokens - this is
                                    a
                                    unique digital certificate</WhyText>
                            </WhyBlock>
                            <WhyBlock>
                                <SpeedSvg/>
                                <WhyTitle>Creation speed and no headaches</WhyTitle>
                                <WhyText>No more wasting time issuing documents. Instead of holding a ceremony, you
                                    just
                                    need to assign the property to any user</WhyText>
                            </WhyBlock>
                            <WhyBlock>
                                <UserSvg/>
                                <WhyTitle>The user page is convenient to view for any employer</WhyTitle>
                                <WhyText>The user will have his own page with awards, the appearance of which he can
                                    customize as he wishes</WhyText>
                            </WhyBlock>
                        </Why>
                        <Promo>
                            <img src={discount} alt=""/>
                            <div>The award can also be accompanied by a gift coupon.</div>
                        </Promo>
                    </WhyWrap>
                </WhyContent>
            </Block>
            <Block>
                <h1 id={"how"}>How to use</h1>
                <Steps>
                    <Step>
                        <StepNumber>01.</StepNumber>
                        <StepText>Just choose a template</StepText>
                    </Step>
                    <Step>
                        <StepNumber>02.</StepNumber>
                        <StepText>Enter a cover letter</StepText>
                    </Step>
                    <Step>
                        <StepNumber>03.</StepNumber>
                        <StepText>Transfer ownership to any user</StepText>
                    </Step>
                </Steps>

                <Banner>
                    <LeftSide>
                        <h1>Like the idea?</h1>
                        <p>Why are you waiting?<br/> Click the button and start presenting awards now</p>
                        <Button
                            onClick={() => userData.Login ? History.push("/home") : History.push("/signup")}>Join
                            now</Button>
                    </LeftSide>
                    <img src={phone} alt="phone"/>
                </Banner>
            </Block>
            <Footer onBurgerClick={() => setMenuOpened(!menuOpened)}/>
        </StyledMainPage>
    );
}

export default MainPage;