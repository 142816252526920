import React from 'react';

function GoogleSvg(props: any) {
    return (
        <svg {...props} width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.382324" width="42.6175" height="42.6175" rx="8" fill="#DF3737"/>
            <path
                d="M21.8662 16.5555C22.988 16.5366 24.0719 16.9606 24.8831 17.7357L27.1468 15.4721C25.7182 14.1304 23.8258 13.3938 21.8662 13.4165C18.8822 13.4154 16.1529 15.0979 14.8135 17.7644L17.4502 19.8092C18.0719 17.8897 19.8487 16.5806 21.8662 16.5555Z"
                fill="white"/>
            <path
                d="M14.8136 17.7644C13.6943 19.9947 13.6943 22.6227 14.8136 24.853L17.4502 22.8082C17.1202 21.8358 17.1202 20.7816 17.4502 19.8092L14.8136 17.7644Z"
                fill="white"/>
            <path
                d="M24.5388 25.3085C22.3258 26.7289 19.3803 26.0865 17.9599 23.8735C17.7464 23.5409 17.5752 23.183 17.4501 22.8081L14.8135 24.8529C16.1529 27.5193 18.8822 29.2019 21.8662 29.2007C23.7874 29.2528 25.6558 28.5689 27.0893 27.2887L24.5388 25.3085Z"
                fill="white"/>
            <path
                d="M29.2991 19.8738H21.8662V22.9266H26.1136C25.936 23.9024 25.367 24.763 24.5388 25.3086C24.5363 25.3102 24.5337 25.3114 24.5312 25.313C24.5337 25.3114 24.5363 25.3102 24.5388 25.3086L27.0893 27.2888L27.0894 27.2888C28.6601 25.7759 29.5154 23.6677 29.4426 21.4881C29.4431 20.9468 29.3951 20.4065 29.2991 19.8738Z"
                fill="white"/>
        </svg>
    );
}

export default GoogleSvg;