import React from 'react';
import styled from "styled-components";
import Menu, {StyledMenu} from "../components/Menu";
import Notifications, {StyledNotifications} from "../components/Notifications";
import MobileNav, {StyledMobileNav} from "../components/MobileNav";
import MobileDashboardMenu from "../components/MobileDashboardMenu";

const Content = styled.div`
  display: flex;
  margin-left: 62px;
  width: 100%;
  overflow: hidden;

  &.noMenu {
    margin-left: 0;
  }
`;

const StyledLayout = styled.div`
  display: flex;
  background: linear-gradient(106.64deg, #F8F8F8 -1.27%, #F7F8F8 7.36%, #F3F7FF 103.55%);
  width: 100%;

  * {
    font-family: Inter, Montserrat, sans-serif;
  }

  ${StyledMenu} {
    margin: 45px 0 45px 60px;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    padding: 0;

    ${StyledNotifications} {
      display: none;
    }

    ${StyledMobileNav} {
      display: flex;
    }

    ${Content} {
      margin-left: 0;
    }
  }
`;

type MainLayoutProps = {
    children?: any,
    noUsers?: boolean
    noMenu?: boolean
}

function MainLayout({children, noUsers = false, noMenu = false}: MainLayoutProps) {
    return (
        <StyledLayout>
            {/*<MobileDashboardMenu/>*/}
            {!noMenu && <Menu/>}
            {!noMenu && <MobileNav/>}
            <Content className={noMenu ? "noMenu" : ""}>
                {children}
                {!noUsers && <Notifications/>}
            </Content>
        </StyledLayout>
    );
}

export default MainLayout;