import React from 'react';

function RectPlusSvg2(props:any) {
    return (
        <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5 16.5H13.5V19.5C13.5 19.8978 13.658 20.2794 13.9393 20.5607C14.2206 20.842 14.6022 21 15 21C15.3978 21 15.7794 20.842 16.0607 20.5607C16.342 20.2794 16.5 19.8978 16.5 19.5V16.5H19.5C19.8978 16.5 20.2794 16.342 20.5607 16.0607C20.842 15.7794 21 15.3978 21 15C21 14.6022 20.842 14.2206 20.5607 13.9393C20.2794 13.658 19.8978 13.5 19.5 13.5H16.5V10.5C16.5 10.1022 16.342 9.72064 16.0607 9.43934C15.7794 9.15804 15.3978 9 15 9C14.6022 9 14.2206 9.15804 13.9393 9.43934C13.658 9.72064 13.5 10.1022 13.5 10.5V13.5H10.5C10.1022 13.5 9.72064 13.658 9.43934 13.9393C9.15804 14.2206 9 14.6022 9 15C9 15.3978 9.15804 15.7794 9.43934 16.0607C9.72064 16.342 10.1022 16.5 10.5 16.5ZM28.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V28.5C0 28.8978 0.158035 29.2794 0.43934 29.5607C0.720644 29.842 1.10218 30 1.5 30H28.5C28.8978 30 29.2794 29.842 29.5607 29.5607C29.842 29.2794 30 28.8978 30 28.5V1.5C30 1.10218 29.842 0.720644 29.5607 0.43934C29.2794 0.158035 28.8978 0 28.5 0ZM27 27H3V3H27V27Z"
                fill="#6780BE"/>
        </svg>
    );
}

export default RectPlusSvg2;