import React from 'react';

function MenuUserSvg(props: any) {
    return (
        <svg {...props} width="30" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.95 13.08C17.7504 12.3872 18.3923 11.5303 18.8323 10.5675C19.2723 9.60474 19.5 8.55856 19.5 7.5C19.5 5.51088 18.7098 3.60322 17.3033 2.1967C15.8968 0.790176 13.9891 0 12 0C10.0109 0 8.10322 0.790176 6.6967 2.1967C5.29018 3.60322 4.5 5.51088 4.5 7.5C4.49999 8.55856 4.7277 9.60474 5.16769 10.5675C5.60767 11.5303 6.24963 12.3872 7.05 13.08C4.95021 14.0308 3.1687 15.5663 1.91847 17.5028C0.668243 19.4393 0.00220778 21.695 0 24C0 24.3978 0.158035 24.7794 0.43934 25.0607C0.720644 25.342 1.10218 25.5 1.5 25.5C1.89782 25.5 2.27936 25.342 2.56066 25.0607C2.84196 24.7794 3 24.3978 3 24C3 21.613 3.94821 19.3239 5.63604 17.636C7.32387 15.9482 9.61305 15 12 15C14.3869 15 16.6761 15.9482 18.364 17.636C20.0518 19.3239 21 21.613 21 24C21 24.3978 21.158 24.7794 21.4393 25.0607C21.7206 25.342 22.1022 25.5 22.5 25.5C22.8978 25.5 23.2794 25.342 23.5607 25.0607C23.842 24.7794 24 24.3978 24 24C23.9978 21.695 23.3318 19.4393 22.0815 17.5028C20.8313 15.5663 19.0498 14.0308 16.95 13.08ZM12 12C11.11 12 10.24 11.7361 9.49993 11.2416C8.75991 10.7471 8.18314 10.0443 7.84254 9.22207C7.50195 8.39981 7.41283 7.49501 7.58647 6.62209C7.7601 5.74918 8.18868 4.94736 8.81802 4.31802C9.44736 3.68868 10.2492 3.2601 11.1221 3.08647C11.995 2.91283 12.8998 3.00195 13.7221 3.34254C14.5443 3.68314 15.2471 4.25991 15.7416 4.99993C16.2361 5.73995 16.5 6.60998 16.5 7.5C16.5 8.69347 16.0259 9.83807 15.182 10.682C14.3381 11.5259 13.1935 12 12 12Z"
                fill="#677FBE"/>
        </svg>
    );
}

export default MenuUserSvg;