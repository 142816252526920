import styled from "styled-components";

export const IconButton = styled.div`
  width: 38px;
  height: 38px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #CBDAFF;
  border-radius: 8px;
  cursor: pointer;
`;