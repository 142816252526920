import React, {useState} from 'react';
import styled from "styled-components";
import SettingsCompanyContent, {Content, StyledSettingsContent} from "../components/SettingsCompanyContent";
import ArrowLeftSvg from "../images/ArrowLeftSvg";
import History from "../utils/history";
import axios from "axios";
import {api} from "../config";
import store from "../redux/store";
import {getGroups} from "../redux/actions/pageActions";
import {Button, message} from "antd";
import RectPlusSvg2 from "../images/RectPlusSvg2";
import NextSvg from "../images/NextSvg";

const StyledCreateCompany = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  padding: 0 48px 48px 48px;

  h1 {
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    color: #262626;
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 50px;

    svg {
      margin-right: 15px;
    }
  }

  ${StyledSettingsContent} {
    width: 100%;

    ${Content} {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;

      > div {
        margin-bottom: 20px;
      }
    }
  }
`;

const CompanyCreate = styled(Button)`
  display: flex;
  align-items: center;
  background: #2361FF !important;
  border: none;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  padding: 24px 25px;
  border-radius: 12px;
  width: auto;
  margin-top: 40px;

  &:hover {
    opacity: .5;
  }

  &:active, &:focus {
    opacity: .8;
  }

  svg {
    margin-left: auto;
  }
`;

function CreateCompany() {
    const [fields, setFields] = useState<any>({});

    const createCompany = () => {
        const reader = new FileReader();
        reader.onloadend = function () {
            // preview.src = reader.result;
            console.log({
                ...fields,
                Photo: reader.result,
                Type: "company",
                Visibility: "public",
            })

            axios.post(`${api}/groups`, {
                ...fields,
                Photo: "",
                PhotoRaw: reader.result,
                Type: "company",
                Visibility: "public",
            }).then(() => {
                setFields("");
                store.dispatch(getGroups());
                History.push("/companies/my")
            })
        }
        if (fields.Photo) {
            reader.readAsDataURL(fields.Photo);
        } else {
            message.error("You need to upload photo!");
        }
    }


    return (
        <StyledCreateCompany>
            <h1><ArrowLeftSvg onClick={() => History.push(-1)}/> Create company</h1>
            <SettingsCompanyContent fields={fields}
                                    setFields={setFields}/>
            <CompanyCreate onClick={createCompany}>Create<NextSvg/></CompanyCreate>
        </StyledCreateCompany>
    );
}

export default CreateCompany;