import React from 'react';
import styled from "styled-components";
import {StyledInput} from "./styled/StyledInput";
import {InputProps} from "antd/lib/input/Input";

export const StyledLabeledInput = styled.div<{ label?: string }>`
  position: relative;

  ${StyledInput} {
    padding-top: 28px;
    padding-left: 20px;
    color: #09101d;
    font-size: 14px;
    font-weight: 600;

    svg {
      width: 22px;
      height: 22px;
    }

    input {
      color: #09101d;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

const Label = styled.div`
  position: absolute;
  top: 0;
  color: #747b84;
  padding: 10px 0 0 20px;
  font-size: 12px;
  z-index: 2;
`;

type LabeledInputProps = InputProps & {
    label?: string
}


function LabeledInput({label = "", ...props}: LabeledInputProps) {
    return (
        <StyledLabeledInput label={label}>
            <StyledInput {...props}/>
            <Label>{label}</Label>
        </StyledLabeledInput>
    );
}

export default LabeledInput;