import React from 'react';
import styled from "styled-components";
import {Select} from "antd";
import LabeledInput, {StyledLabeledInput} from "../components/LabeledInput";
import PhoneSvg from "../images/PhoneSvg";
import LabeledTextarea, {StyledLabeledTextarea} from "../components/LabeledTextarea";
import MailSvg from "../images/MailSvg";
import BalloonSvg from "../images/BalloonSvg";
import Facebook2Svg from "../images/Facebook2Svg";
import {StyledSelect} from "./styled/StyledSelect";
import {StyledInput} from "./styled/StyledInput";
import ArrowCircleSvg from "../images/ArrowCircleSvg";
import Google2Svg from "../images/Google2Svg";

const {Option} = Select;

const Content = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 58px;
  border-bottom: 1px solid #dcdcdc;

  ${StyledLabeledTextarea}, textarea {
    height: 100%;
  }

  ${StyledLabeledInput} {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
`;

const Button = styled.div`
  display: flex;
  color: #09101d;
  font-size: 14px;
  font-weight: 600;
  margin-top: 40px;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const InputsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 35px;
`;

const Advantage = styled.div`
  display: flex;
  margin-top: 58px;
`;

const AdvantageBlock = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  padding: 28px;

  ${StyledSelect} {
    margin-bottom: 12px;
  }
`;

const AdvantageItem = styled.div`
  h2 {
    color: #262626;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
  }

  .ant-select {
    width: 100%;
  }

  &:not(:last-child) {
    margin-right: 45px;
  }
`;

const StyledSettingsContent = styled.div`

`;

function SettingsContent() {
    return (
        <StyledSettingsContent>
            <Content>
                <InputsBlock>
                    <LabeledInput defaultValue={"Techen Group"} label={"Your name"}/>
                    <LabeledTextarea
                        defaultValue={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultricies a malesuada malesuada. Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultricies a malesuada malesuada. Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique.  Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique.  Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique. Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique. "}
                        label={"About us"}/>
                </InputsBlock>
                <InputsBlock>
                    <LabeledInput prefix={<PhoneSvg/>} defaultValue={"+972 54-312-6546"} label={"Phone"}/>
                    <LabeledInput prefix={<MailSvg/>} defaultValue={"nawards@vaicom.tech"} label={"E-mail"}/>
                    <LabeledInput prefix={<BalloonSvg/>}
                                  defaultValue={"Steven Bernson, Attorney at Law 1556 Broadway, suite 416 New York, NY, 10120, USA"}
                                  label={"Address"}/>
                    <Button>
                        <Google2Svg/>
                        Connect
                    </Button>
                </InputsBlock>
                <InputsBlock>
                    <LabeledInput
                        defaultValue={"https://www.facebook.com/zuck"}
                        label={"Facebook"} suffix={<Facebook2Svg/>}/>
                </InputsBlock>
            </Content>
            {/*<Advantage>*/}
            {/*    <AdvantageItem>*/}
            {/*        <h2>Advantage #1</h2>*/}
            {/*        <AdvantageBlock>*/}
            {/*            <StyledSelect suffixIcon={<ArrowCircleSvg/>}>*/}
            {/*                <Option value={"1"}>1</Option>*/}
            {/*            </StyledSelect>*/}
            {/*            <StyledInput/>*/}
            {/*        </AdvantageBlock>*/}
            {/*    </AdvantageItem>*/}
            {/*    <AdvantageItem>*/}
            {/*        <h2>Advantage #2</h2>*/}
            {/*        <AdvantageBlock>*/}
            {/*            <StyledSelect suffixIcon={<ArrowCircleSvg/>}>*/}
            {/*                <Option value={"1"}>1</Option>*/}
            {/*            </StyledSelect>*/}
            {/*            <StyledInput/>*/}
            {/*        </AdvantageBlock>*/}
            {/*    </AdvantageItem>*/}
            {/*    <AdvantageItem>*/}
            {/*        <h2>Advantage #3</h2>*/}
            {/*        <AdvantageBlock>*/}
            {/*            <StyledSelect suffixIcon={<ArrowCircleSvg/>}>*/}
            {/*                <Option value={"1"}>1</Option>*/}
            {/*            </StyledSelect>*/}
            {/*            <StyledInput/>*/}
            {/*        </AdvantageBlock>*/}
            {/*    </AdvantageItem>*/}
            {/*</Advantage>*/}
        </StyledSettingsContent>
    );
}

export default SettingsContent;