import React, {useState} from 'react';
import styled, {css} from "styled-components";

const StyledSpoiler = styled.div<any>(({visible}: any) => css`
  content: "";
  cursor: pointer;
  display: inline;
  user-select: ${visible ? "" : "none"};
  background: ${visible ? "" : "#2b2b2b"};
  color: ${visible ? "" : "#2b2b2b"};
  border-radius: ${visible ? "" : "2px"};
  word-wrap: anywhere;
`);

type SpoilerProps = {
    children: any,
    visible?: boolean
}


function Spoiler({visible = false, children}: SpoilerProps) {

    const [_visible, setVisible] = useState(visible);

    return (
        <StyledSpoiler visible={_visible} onClick={() => setVisible(!_visible)}>
            {children}
        </StyledSpoiler>
    );
}

export default Spoiler;