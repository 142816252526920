import React from 'react';
import styled from "styled-components";
import MenuHomeSvg from "../images/MenuHomeSvg";
import MenuShopSvg from "../images/MenuShopSvg";
import BankSvg from "../images/BankSvg";
import MenuUserSvg from "../images/MenuUserSvg";
import AwardsLogo from "../images/AwardsLogo";
import QuestionInCircleSvg from "../images/QuestionInCircleSvg";
import {api} from "../config";
import ExitSvg from "../images/ExitSvg";
import {Link} from "react-router-dom";

const StyledMobileDashboardMenu = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 50px;
`;
const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;
const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  color: #6780be;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;

  &:not(:last-child) {
    margin-bottom: 38px;
  }

  svg {
    margin-right: 18px;
  }
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  border-top: 1px solid #b3c3ed;
  padding-top: 32px;

  > svg {
    margin-right: 18px;

    &:first-child {
      margin-right: auto;
    }
  }
`;

function MobileDashboardMenu({callback}:any) {
    return (
        <StyledMobileDashboardMenu>
            <MenuItems>
                <MenuItem onClick={callback} to={"/home"}><MenuHomeSvg/>Home</MenuItem>
                <MenuItem onClick={callback} to={"/shop"}><MenuShopSvg/>Awards shop</MenuItem>
                <MenuItem onClick={callback} to={"/companies/my"}><BankSvg/>My company</MenuItem>
                <MenuItem onClick={callback} to={"/user/profile"}><MenuUserSvg/>Profile</MenuItem>
            </MenuItems>
            <Footer>
                <AwardsLogo/>
                <QuestionInCircleSvg/>
                <a style={{height: 30, transform: "rotateZ(90deg)"}} href={`${api}/exit`}><ExitSvg/></a>
            </Footer>
        </StyledMobileDashboardMenu>
    );
}

export default MobileDashboardMenu;