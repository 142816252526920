import React from 'react';

function DollarSvg(props:any) {
    return (
        <svg {...props} width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.95205 19.2804H6.48914L6.49639 17.62C9.60683 17.3952 11.3904 15.6841 11.3977 13.2552C11.3904 10.6813 9.26606 9.50677 7.03292 8.97748L6.55439 8.85423L6.5834 4.71423C7.74346 4.91724 8.49026 5.61328 8.60627 6.65735H11.1947C11.1512 4.35171 9.33856 2.66961 6.5979 2.40859L6.60515 0.719238H5.06805L5.0608 2.40859C2.34189 2.68411 0.384273 4.35171 0.391524 6.7516C0.391524 8.88323 1.90686 10.1086 4.35026 10.7393L5.0028 10.9061L4.9738 15.2926C3.67597 15.0896 2.74066 14.3501 2.64641 13.0377H0C0.101506 15.7566 1.97212 17.3735 4.9593 17.62L4.95205 19.2804ZM6.51089 15.2926L6.53989 11.3121C7.85947 11.6964 8.70052 12.2039 8.70777 13.2335C8.70052 14.3066 7.83772 15.0751 6.51089 15.2926ZM5.0173 8.4627C4.01674 8.14368 3.10319 7.6144 3.11769 6.58484C3.12494 5.64954 3.82823 4.92449 5.0463 4.71423L5.0173 8.4627Z"
                fill="#C6CFE5"/>
        </svg>
    );
}

export default DollarSvg;