import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Select, Upload} from "antd";
import LabeledInput, {StyledLabeledInput} from "../components/LabeledInput";
import PhoneSvg from "../images/PhoneSvg";
import LabeledTextarea, {StyledLabeledTextarea} from "../components/LabeledTextarea";
import MailSvg from "../images/MailSvg";
import BalloonSvg from "../images/BalloonSvg";
import Facebook2Svg from "../images/Facebook2Svg";
import {StyledSelect} from "./styled/StyledSelect";
import {StyledInput} from "./styled/StyledInput";
import ArrowCircleSvg from "../images/ArrowCircleSvg";
import Google2Svg from "../images/Google2Svg";
import CameraSvg from "../images/CameraSvg";
import LinkedInSvg from "../images/LinkedInSvg";

const {Option} = Select;

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 58px;
  border-bottom: 1px solid #dcdcdc;

  ${StyledLabeledTextarea}, textarea {
    height: 100%;
  }

  ${StyledLabeledInput} {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
`;

const Button = styled.div`
  display: flex;
  color: #09101d;
  font-size: 14px;
  font-weight: 600;
  margin-top: 40px;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const InputsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 35px;

  .ant-upload {
    width: 100%;
  }

`;

const Advantage = styled.div`
  display: flex;
  margin-top: 58px;
`;

const AdvantageBlock = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
  padding: 28px;

  ${StyledSelect} {
    margin-bottom: 12px;
  }
`;

const AdvantageItem = styled.div`
  h2 {
    color: #262626;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
  }

  .ant-select {
    width: 100%;
  }

  &:not(:last-child) {
    margin-right: 45px;
  }
`;

const UploadPhoto = styled.div`
  padding: 22px 22px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 2px solid #EDF2FF;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7B7B7B;
  margin-bottom: 18px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`;

export const StyledSettingsContent = styled.div`

`;
type SettingsCompanyContentProps = {
    fields: any,
    setFields: (value: any) => void
}

function SettingsContent({fields, setFields}: SettingsCompanyContentProps) {

    const [uploadedFile, setUploadedFile] = useState<any>("");

    const [name, setName] = useState(fields.Name || "");
    const [about, setAbout] = useState(fields.Description || "");
    const [phone, setPhone] = useState(fields.Phone || "");
    const [email, setEmail] = useState(fields.Email || "");
    const [address, setAddress] = useState(fields.Address || "");
    const [facebookLink, setFacebookLink] = useState(fields.FacebookLink || "");
    const [linkedinLink, setLinkedinLink] = useState(fields.LinkedinLink || "");

    const isMounted = useRef<any>(null);

    useEffect(() => {
        if (isMounted.current)
            setFields({
                Name: name,
                Description: about,
                Phone: phone,
                Email: email,
                Address: address,
                Photo: uploadedFile,
                FacebookLink: facebookLink,
                LinkedinLink: linkedinLink,
            })
    }, [name, about, phone, email, address, facebookLink, linkedinLink, uploadedFile])

    useEffect(() => {
        isMounted.current = true;
    }, [])
    useEffect(() => {
        setName(fields.Name || "");
        setAbout(fields.Description || "");
        setPhone(fields.Phone || "");
        setEmail(fields.Email || "");
        setAddress(fields.Address || "");
        setFacebookLink(fields.FacebookLink || "");
        setLinkedinLink(fields.LinkedinLink || "");
        setUploadedFile(fields.Photo || "");
    }, [fields])

    return (
        <StyledSettingsContent>
            <Content>
                <InputsBlock>
                    <Upload showUploadList={false}
                            customRequest={(_: any) => setUploadedFile(_.file)}><UploadPhoto><CameraSvg/> {uploadedFile?.name || "Upload photo"}
                    </UploadPhoto></Upload>
                    <LabeledInput value={name} onChange={({target: {value}}) => setName(value)} label={"Name company"}/>
                    <LabeledTextarea
                        value={about}
                        onChange={({target: {value}}) => setAbout(value)}
                        defaultValue={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultricies a malesuada malesuada. Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultricies a malesuada malesuada. Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique.  Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique.  Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique. Quis ullamcorper nulla aliquet vestibulum at id vel consectetur tristique. "}
                        label={"About us"}/>
                </InputsBlock>
                <InputsBlock>
                    <LabeledInput value={phone} onChange={({target: {value}}) => setPhone(value)} prefix={<PhoneSvg/>}
                                  label={"Phone"}/>
                    <LabeledInput value={email} onChange={({target: {value}}) => setEmail(value)} prefix={<MailSvg/>}
                                  defaultValue={"techen.group@gmai.com"} label={"E-mail"}/>
                    <LabeledInput value={address} onChange={({target: {value}}) => setAddress(value)}
                                  prefix={<BalloonSvg/>}
                                  defaultValue={"Steven Bernson, Attorney at Law 1556 Broadway, suite 416 New York, NY, 10120, USA"}
                                  label={"Address"}/>
                    <LabeledInput value={facebookLink} onChange={({target: {value}}) => setFacebookLink(value)}
                                  defaultValue={"https://www.facebook.com/zuck"}
                                  label={"Facebook"} suffix={<Facebook2Svg/>}/>
                    <LabeledInput value={linkedinLink} onChange={({target: {value}}) => setLinkedinLink(value)}
                                  defaultValue={"https://il.linkedin.com/in/vaizman"}
                                  label={"LinkedIn"} suffix={<LinkedInSvg/>}/>

                    {/*<Button>*/}
                    {/*    <Google2Svg/>*/}
                    {/*    Connect*/}
                    {/*</Button>*/}
                </InputsBlock>
            </Content>
            {/*<Advantage>*/}
            {/*    <AdvantageItem>*/}
            {/*        <h2>Advantage #1</h2>*/}
            {/*        <AdvantageBlock>*/}
            {/*            <StyledSelect suffixIcon={<ArrowCircleSvg/>}>*/}
            {/*                <Option value={"1"}>1</Option>*/}
            {/*            </StyledSelect>*/}
            {/*            <StyledInput/>*/}
            {/*        </AdvantageBlock>*/}
            {/*    </AdvantageItem>*/}
            {/*    <AdvantageItem>*/}
            {/*        <h2>Advantage #2</h2>*/}
            {/*        <AdvantageBlock>*/}
            {/*            <StyledSelect suffixIcon={<ArrowCircleSvg/>}>*/}
            {/*                <Option value={"1"}>1</Option>*/}
            {/*            </StyledSelect>*/}
            {/*            <StyledInput/>*/}
            {/*        </AdvantageBlock>*/}
            {/*    </AdvantageItem>*/}
            {/*    <AdvantageItem>*/}
            {/*        <h2>Advantage #3</h2>*/}
            {/*        <AdvantageBlock>*/}
            {/*            <StyledSelect suffixIcon={<ArrowCircleSvg/>}>*/}
            {/*                <Option value={"1"}>1</Option>*/}
            {/*            </StyledSelect>*/}
            {/*            <StyledInput/>*/}
            {/*        </AdvantageBlock>*/}
            {/*    </AdvantageItem>*/}
            {/*</Advantage>*/}
        </StyledSettingsContent>
    );
}

export default SettingsContent;