import React from 'react';

function ArrowLeftSvg(props: any) {
    return (
        <svg {...props} width="15" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.4142 25.081C13.6332 25.862 12.3668 25.862 11.5858 25.081L0.91912 14.4143C0.138072 13.6332 0.138072 12.3669 0.91912 11.5859L11.5858 0.919201C12.3668 0.138152 13.6332 0.138152 14.4142 0.919201C15.1953 1.70025 15.1953 2.96658 14.4142 3.74763L5.16176 13.0001L14.4142 22.2525C15.1953 23.0336 15.1953 24.2999 14.4142 25.081Z"
                  fill="#C7C7C7"/>
        </svg>
    );
}

export default ArrowLeftSvg;