import React from 'react';

function ExitSvg(props: any) {
    return (
        <svg {...props} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.23852 17.8117C8.63734 19.3002 9.51616 20.6154 10.7387 21.5535C11.9612 22.4915 13.4591 23 15 23C16.5409 23 18.0388 22.4915 19.2613 21.5535C20.4838 20.6154 21.3627 19.3002 21.7615 17.8117"
                stroke="#F56A6A" strokeWidth="2"/>
            <path
                d="M15 7L14.3753 6.21913L15 5.71937L15.6247 6.21913L15 7ZM16 16C16 16.5523 15.5523 17 15 17C14.4477 17 14 16.5523 14 16L16 16ZM9.37531 10.2191L14.3753 6.21913L15.6247 7.78087L10.6247 11.7809L9.37531 10.2191ZM15.6247 6.21913L20.6247 10.2191L19.3753 11.7809L14.3753 7.78087L15.6247 6.21913ZM16 7L16 16L14 16L14 7L16 7Z"
                fill="#F56A6A"/>
            <ellipse cx="15" cy="15.5" rx="13" ry="13.5" stroke="#F56A6A" strokeWidth="3"/>
        </svg>


    );
}

export default ExitSvg;