import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import store from "../redux/store";
import {getAwards} from "../redux/actions/pageActions";
import {useSelector} from "react-redux";
import clip from "text-clipper";
import History from "../utils/history";
import {IconButton} from "../components/styled/IconButton";
import ShareIconSvg from "../images/ShareIconSvg";
import {Badge, Modal} from "antd";
import {api} from "../config";
import BellSvg from "../images/BellSvg";
import MiniAwardInfo, {StyledMiniAwardInfo} from "../components/MiniAwardInfo";
import CrossSvg from "../images/CrossSvg";
import {QrImg} from "../components/styled/QrImg";

const Name = styled.div`
  color: #262626;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: #707070;
  margin-top: 20px;
`;

const FavoriteItem = styled.div<{ color: string }>(({color}: any) => css`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 16px;
  padding: 20px;
  max-width: 242px;
  overflow-wrap: anywhere;
  cursor: pointer;

  img {
    background: ${color || ""};
    overflow: hidden;
    width: 200px;
    height: 200px;
    border-radius: 16px;
  }
`);

const Favorites = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  gap: 20px;

  &::-webkit-scrollbar {
    width: 0;
  }

`;

const VerticalGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    margin-right: 20px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;

  object-fit: cover;
  border-radius: 100%;
  margin-right: 8px;
`;
const Text = styled.div`
  margin-right: 100px;
`;
const Title = styled.div`
  color: #262626;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;
const AwardsCount = styled.div`
  display: flex;
  color: #2361ff;
  font-size: 10px;
  font-weight: 500;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;
const Info = styled.div`
  margin-right: 56px;
  color: #919bb3;
  font-size: 14px;
  font-weight: 500;
`;
const AwardPhoto = styled.div`
  height: 67px;
  width: 53px;


  img {
    height: 100%;
  }
`;
const HistoryItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 26px;
  background: #FFFFFF;
  border: 1px solid #EDF2FF;
  border-radius: 12px;
`;

const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${HistoryItem} {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
`;


const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 10px 0;

  .notificationsMobileBtn{
    display: none;
  }
  
  h1 {
    margin: 0 auto 0 0;
  }

  ${IconButton} {
    margin-left: 20px;
  }
`;

const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  margin: 45px 62px 45px 0;
  overflow: hidden;

  h1 {
    color: #262626;
    font-size: 42px;
    font-weight: 800;
    line-height: 51px;
  }

  h2 {
    color: #262626;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
  }

  @media (max-width: 1100px) {
    margin: 45px 48px;
    .notificationsMobileBtn{
      display: flex;
    }
    h1 {
      font-size: 32px;
    }

    ${FavoriteItem} {
      flex: 1 1 auto;
      max-width: none;
      align-items: center;
    }
  }
`;

// const History = styled.div`
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
//
//   margin-top: 30px;
//
//   h2 {
//     font-weight: bold;
//     margin-bottom: 18px;
//   }
// `;

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    line-height: 122px;
  }

  h1 {
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    color: #262626;
    margin-bottom: 50px;
  }

  .ant-modal-content {
    background: none;

    .ant-modal-body {
      border-radius: 12px !important;
      border: 1px solid #E0E9FF;
      background: #F5F8FE;
      box-shadow: 0 0 15px rgba(147, 197, 234, 0.15);
    }
  }
`;

const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;

  ${StyledMiniAwardInfo} {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`;


function Home() {
    const awards = useSelector(({pageReducer: {awards}}: any) => awards);
    const notifications: any = useSelector(({pageReducer: {notifications}}: any) => notifications);

    const [notificationsVisible, setNotificationsVisible] = useState<boolean>(false);

    useEffect(() => {
        store.dispatch(getAwards())
    }, [])

    const getQr = () => {
        new (Modal.success as any)({
            maskClosable: true,
            icon: null,
            content: <QrImg src={`${api}/user/share`} alt={"qr"}/>
        })
    }


    return (
        <StyledHome>
            <StyledModal visible={notificationsVisible} onCancel={() => setNotificationsVisible(false)} footer={null}
                         closeIcon={<CrossSvg/>}>
                <h1>Notifications</h1>
                <NotificationsList>
                    {notifications?.map((notification: any, index: number) => <MiniAwardInfo key={index} index={index + 1}
                                                                                             notification={notification}/>)}
                </NotificationsList>
            </StyledModal>
            <Top>
                <h1>Home</h1>
                <IconButton onClick={getQr}><ShareIconSvg/></IconButton>
                <Badge className={"notificationsMobileBtn"} color={"#2361FF"} style={{left: 0, right: "auto"}} count={notifications?.length || 0}><IconButton
                    onClick={() => setNotificationsVisible(true)}><BellSvg/></IconButton></Badge>
            </Top>
            <Favorites>
                {awards?.map((award: any, index: number) =>
                    <FavoriteItem key={index} color={award.Meta?.background_color}
                                  onClick={() => History.push(`/award/${award.Id}`)}>
                        <Name>{award.Meta?.name}</Name>
                        <img src={award.Meta?.image} alt={award.Meta?.name}/>
                        <Description><b>Description</b><br/>{clip(award.Meta?.description, 32) || "-"}</Description>
                    </FavoriteItem>)}
            </Favorites>
        </StyledHome>
    );
}

export default Home;