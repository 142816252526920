import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from "styled-components";
import NoneColorSvg from "../images/NoneColorSvg";


const Color = styled.div<{ color: string, selected: boolean }>(({color, selected}) => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 10px;
  border: ${selected ? "2px solid #80A7FFFF" : "none"};

  &:after {
    height: 28px;
    width: 28px;
    border-radius: 6px;
    content: "";
    position: absolute;
    background: ${color};
  }

  &:hover {
    border: 2px solid #BFD1FF;
  }
`);

const Colors = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  ${Color} {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const Label = styled.div`
  color: #747b84;
  font-size: 12px;
  line-height: 140%;
`;

const StyledColorPicker = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  padding: 12px 18px 14px 12px;
  display: flex;
  flex-direction: column;
`;

type ColorPickerProps = {
    onChange?: (color: string) => void,
    text?: string
}

function ColorPicker({onChange, text = "Choose the default color"}: ColorPickerProps) {

    const [selectedColor, setSelectedColor] = useState("");
    const isMounted = useRef<any>(null);

    useEffect(() => {
        if (isMounted.current)
            onChange && onChange(selectedColor);
    }, [selectedColor])

    useEffect(() => {
        isMounted.current = true;
    }, [])

    return (
        <StyledColorPicker>
            <Label>{text}</Label>
            <Colors>
                <Color selected={selectedColor === "transparent"} onClick={() => setSelectedColor("transparent")}
                       color={"transparent"}><NoneColorSvg/></Color>
                <Color selected={selectedColor === "#FFB400"} onClick={() => setSelectedColor("#FFB400")}
                       color={"#FFB400"}/>
                <Color selected={selectedColor === "#2361FF"} onClick={() => setSelectedColor("#2361FF")}
                       color={"#2361FF"}/>
                <Color selected={selectedColor === "#DF3737"} onClick={() => setSelectedColor("#DF3737")}
                       color={"#DF3737"}/>
                <Color selected={selectedColor === "#EEEEEE"} onClick={() => setSelectedColor("#EEEEEE")}
                       color={"#EEEEEE"}/>
                <Color selected={selectedColor === "#313131"} onClick={() => setSelectedColor("#313131")}
                       color={"#313131"}/>
                <Color selected={selectedColor === "#00CD90"} onClick={() => setSelectedColor("#00CD90")}
                       color={"#00CD90"}/>
                <Color selected={selectedColor === "#BC0066"} onClick={() => setSelectedColor("#BC0066")}
                       color={"#BC0066"}/>
                <Color selected={selectedColor === "#4CC800"} onClick={() => setSelectedColor("#4CC800")}
                       color={"#4CC800"}/>
            </Colors>
        </StyledColorPicker>
    );
}

export default ColorPicker;