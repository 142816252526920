import styled from "styled-components";
import {Select} from "antd";

export const StyledSelect = styled(Select)`
  &.ant-select {
    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-right-width: 2px !important;
    }

    .ant-select-clear {
      top: 34%;
      right: 23px;
      background: none;
    }

    .ant-select-arrow {
      top: 34%;
      right: 23px;
    }

    .ant-select-selection-item {
      line-height: normal;
    }

    &.ant-select-focused {
      &:not(.ant-select-disabled) {
        &.ant-select {
          &:not(.ant-select-customize-input) {
            .ant-select-selector {
              border-right-width: 2px !important;
            }
          }
        }
      }
    }

    .ant-select-selector {
      font-size: 14px;
      padding-top: 15px;
      padding-bottom: 15px;
      height: auto;
      background: #FFFFFF;
      border: 2px solid #EDF2FF;
      border-radius: 12px;
      color: #828282;

      &:hover, &:active, &:focus {
        border-right-width: 2px !important;
      }

      &:after {
        line-height: normal;
      }

      .ant-select-selection-placeholder {
        line-height: normal;
      }
    }
  }
`;
