import React from 'react';

function NextSvg(props:any) {
    return (
        <svg {...props} width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.210145 11.2685C-0.0114383 11.0387 -0.0632965 10.6939 0.0809081 10.4091L0.78241 9.02373C2.44268 5.74491 5.80563 3.67826 9.48084 3.67827H9.82573C9.85089 3.13189 9.88336 2.58576 9.92314 2.04003L9.99098 1.10939C10.0461 0.352573 10.89 -0.0704938 11.5295 0.338088C13.6271 1.67845 15.4507 3.40554 16.903 5.4273L17.3591 6.06223C17.547 6.32371 17.547 6.67589 17.3591 6.93736L16.903 7.5723C15.4507 9.59406 13.6271 11.3211 11.5295 12.6615C10.89 13.0701 10.0461 12.647 9.99098 11.8902L9.92314 10.9596C9.87563 10.3078 9.83855 9.65556 9.8119 9.00295C7.66207 8.94088 5.51486 9.3748 3.5415 10.2856L1.06431 11.4289C0.774475 11.5627 0.431728 11.4983 0.210145 11.2685ZM2.42839 9.14726L2.91291 8.92364C5.32405 7.81081 7.96958 7.341 10.5947 7.54262C10.977 7.57199 11.2756 7.88499 11.2869 8.26826C11.3124 9.12962 11.3565 9.99058 11.4192 10.8505L11.4246 10.925C13.0681 9.76331 14.5083 8.33492 15.6848 6.69717L15.8266 6.4998L15.6848 6.30243C14.5083 4.66468 13.0681 3.23629 11.4246 2.07463L11.4192 2.14909C11.3632 2.91669 11.3221 3.68511 11.2958 4.45391C11.282 4.85791 10.9505 5.17827 10.5462 5.17827L9.48084 5.17827C6.58352 5.17827 3.91555 6.69617 2.42839 9.14726Z"
                  fill="white"/>
        </svg>
    );
}

export default NextSvg;