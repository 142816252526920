import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import BalanceSvg from "../images/BalanceSvg";
import axios from "axios";
import {api} from "../config";
import ApplySvg from "../images/ApplySvg";
import DeclineSvg from "../images/DeclineSvg";
import moment from "moment";
import {message, Spin} from "antd";
import store from "../redux/store";
import {
    addLoadingNotifications,
    getAwards,
    getNotifications,
    removeLoadingNotifications
} from "../redux/actions/pageActions";
import {useSelector} from "react-redux";
import MiniAwardInfo, {StyledMiniAwardInfo} from "./MiniAwardInfo";

const Notification = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  padding-right: 20px;
  position: relative;
  overflow: hidden;
`;

const NotificationsList = styled.div`
  margin-top: 35px;
  overflow: auto;

  ${StyledMiniAwardInfo} {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }


  &::-webkit-scrollbar {
    width: 0;
  }


  ${Notification} {
    &:not(:last-child) {
      margin-bottom: 16px;

      &:after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        right: -50%;
        border-bottom: 1px solid #E7E7E7;
      }
    }
  }
`;

const Index = styled.div`
  font-weight: 600;
  line-height: 17px;
  margin-right: 22px;
  font-size: 14px;
  color: #ababab;
`;

const Avatar = styled.div`
  height: 64px;
  width: 64px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 12px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: #262626;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  margin-bottom: 2px;
`;

const Balance = styled.div`
  display: flex;
  align-items: center;
  color: #2361ff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

const Date = styled.div`

`;

export const StyledNotifications = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 60px 0 4px 30px;
  border-left: 1px solid #DCDCDC;
  max-width: 365px;
  width: 100%;
  margin-left: auto;
  overflow: hidden;

  h1 {
    color: #262626;
    font-size: 28px;
    font-weight: bold;
    line-height: 34px;
  }
`;

function Notifications() {

    const loadingNotifications = useSelector(({pageReducer: {loadingNotifications}}: any) => loadingNotifications);

    const notifications: any = useSelector(({pageReducer: {notifications}}: any) => notifications);

    const applyInvite = (notifId: number, id: string) => {
        console.log(id);
        store.dispatch(addLoadingNotifications(id))

        axios(`${api}/transitions/${id}/apply`).then(() => {
            message.success("Invitation successfully accepted");
            removeNotification(notifId);
            store.dispatch(getAwards());
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        }).finally(() => {
            store.dispatch(removeLoadingNotifications(id))
        })
    }

    const cancelInvite = (notifId: number, id: number) => {
        axios(`${api}/transitions/${id}/cancel`).then(() => {
            message.warning("Invitation successfully declined");
            removeNotification(notifId);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }


    const removeNotification = (id: number) => {
        axios.delete(`${api}/user/notifications/${id}`).then(() => {
            store.dispatch(getNotifications())
        })
    }

    useEffect(() => {
        store.dispatch(getNotifications())
    }, [])

    return (
        <StyledNotifications>
            <h1>Notifications</h1>
            <NotificationsList>
                {notifications?.length > 0 ? notifications?.map((notification: any, index: number) => <MiniAwardInfo
                    key={index}
                    index={index + 1}
                    notification={notification}/>) : "Empty..."}

                {/*{notifications?.length > 0 ? notifications.map((notification: any, index: number) =>*/}
                {/*    <Notification>*/}
                {/*        <Index>{index + 1}</Index>*/}
                {/*        <Text>*/}
                {/*            <Title>{notification.Text || notification.Type}</Title>*/}
                {/*            <Date>{moment(notification.Created).format("DD.MM.YY")}</Date>*/}
                {/*        </Text>*/}
                {/*        <Actions>*/}
                {/*            {notifications?.map((notification: any, index: number) => <MiniAwardInfo key={index}*/}
                {/*                                                                                     index={index + 1}*/}
                {/*                                                                                     notification={notification}/>)}*/}

                {/*            /!*{loadingNotifications.includes(notification.Data.Id) ?*!/*/}
                {/*            /!*    <Spin/> :*!/*/}
                {/*            /!*    <>*!/*/}
                {/*            /!*        <ApplySvg*!/*/}
                {/*            /!*            onClick={() => applyInvite(notification.Id, notification.Data.Id)}/>*!/*/}
                {/*            /!*        <DeclineSvg onClick={() => cancelInvite(notification.Id, notification.Data.Id)}/>*!/*/}
                {/*            /!*    </>*!/*/}
                {/*            /!*}*!/*/}
                {/*        </Actions>*/}
                {/*    </Notification>) : "Empty..."}*/}
            </NotificationsList>
        </StyledNotifications>
    );
}

export default Notifications;