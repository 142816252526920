import React from 'react';

function PhotoUploadSvg(props: any) {
    return (
        <svg {...props} width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.85" cx="37" cy="37" r="37" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M28.3283 39.3125C28.3283 34.5232 32.2109 30.6406 37.0002 30.6406C41.7895 30.6406 45.6721 34.5232 45.6721 39.3125C45.6721 44.1018 41.7895 47.9844 37.0002 47.9844C32.2109 47.9844 28.3283 44.1018 28.3283 39.3125ZM37.0002 34.1094C34.1266 34.1094 31.7971 36.4389 31.7971 39.3125C31.7971 42.1861 34.1266 44.5156 37.0002 44.5156C39.8738 44.5156 42.2033 42.1861 42.2033 39.3125C42.2033 36.4389 39.8738 34.1094 37.0002 34.1094Z"
                  fill="#2361FF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M33.8 22.5469C31.3831 22.5469 29.4237 24.5062 29.4237 26.9232C29.4237 28.4632 28.2415 29.7453 26.7065 29.87L21.5506 30.2887C20.5382 30.3709 19.7057 31.1195 19.5167 32.1175C18.479 37.5981 18.4016 43.2174 19.288 48.7245L19.5129 50.1217C19.7253 51.4413 20.8114 52.4452 22.1435 52.5534L26.6351 52.9181C33.5338 53.4784 40.4666 53.4784 47.3653 52.9181L51.8569 52.5534C53.189 52.4452 54.2751 51.4413 54.4875 50.1217L54.7124 48.7245C55.5988 43.2174 55.5214 37.5981 54.4837 32.1175C54.2947 31.1195 53.4622 30.3709 52.4498 30.2887L47.2939 29.87C45.7589 29.7453 44.5767 28.4632 44.5767 26.9232C44.5767 24.5062 42.6173 22.5469 40.2004 22.5469H33.8ZM25.969 26.4497C26.2138 22.3375 29.6263 19.0781 33.8 19.0781H40.2004C44.3741 19.0781 47.7866 22.3374 48.0314 26.4497L52.7306 26.8313C55.2996 27.04 57.4124 28.9397 57.8919 31.4722C59.0044 37.3477 59.0873 43.3718 58.137 49.2758L57.9121 50.673C57.4459 53.5695 55.0619 55.7732 52.1377 56.0107L47.6461 56.3755C40.5605 56.9509 33.4399 56.9509 26.3543 56.3755L21.8627 56.0107C18.9385 55.7732 16.5545 53.5695 16.0883 50.673L15.8634 49.2758C14.9131 43.3718 14.996 37.3477 16.1085 31.4722C16.588 28.9397 18.7008 27.04 21.2698 26.8313L25.969 26.4497Z"
                  fill="#2361FF"/>
        </svg>
    );
}

export default PhotoUploadSvg;