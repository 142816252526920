export const initialState = {
    loginReducer: {
        loading: true,
        userData: {}
    },
    pageReducer: {
        templates: [],
        groups: [],
        awards: [],
        collections: [],
        notifications: [],
        loadingNotifications: [],
    }
}