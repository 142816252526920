import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import CirclePlusSvg from "../images/CirclePlusSvg";
import {Button, Form, message, Modal, Select, Upload} from "antd";
import {StyledInput} from "../components/styled/StyledInput";
import {StyledSelect} from '../components/styled/StyledSelect';
import ColorPicker from "../components/ColorPicker";
import ArrowCircleSvg from "../images/ArrowCircleSvg";
import axios from "axios";
import {api} from "../config";
import {useParams} from "react-router-dom";
import HearthSvg from "../images/HearthSvg";
import MoreSvg from "../images/MoreSvg";
import {IconButton} from "../components/styled/IconButton";
import ListItemSvg from "../images/ListItemSvg";
import ImageListSvg from "../images/ImageListSvg";
import ElementsList from "../components/ElementsList";
import {v4 as uuidv4} from 'uuid';
import UserSvg from "../images/UserSvg";
import UserInputSvg from "../images/UserInputSvg";
import NextSvg from "../images/NextSvg";
import TrashSvg from "../images/TrashSvg";
import BackSvg from "../images/BackSvg";
import History from "../utils/history";
import store from "../redux/store";
import {getGroups} from "../redux/actions/pageActions";
import {useSelector} from "react-redux";
import ShareIconSvg from "../images/ShareIconSvg";

const {Option} = Select;

const Content = styled.div`
  display: flex;
  overflow: hidden;
`;

const ImageWrap = styled.div`
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-row, .ant-form-item-control-input, .ant-form-item-control-input-content, textarea {
    height: 100%;
  }

  .ant-row {
    margin-top: 30px;
  }
`;

const Image = styled.div<{ color: string }>(({color}: any) => css`
  width: 100%;
  height: 100%;
  border-radius: 21px;
  overflow: hidden;
  min-height: 560px;
  max-height: 560px;
  flex: 1 1 auto;
  position: relative;
  background: ${color || "none"};

  img {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`);

const AwardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`

`;

const Collection = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #8B8B8B;

  span {
    margin-left: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3D3D3D;
  }
`;

const Counter = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #3A3A3A;
`;

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 140%;
  color: #262626;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  ${IconButton} {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const Rarity = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-top: 12px;
  margin-bottom: 24px;
  color: #710ED5;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: #707070;
  margin-bottom: 34px;
`;

const Elements = styled.div`
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #414141;
  }
`;

const ElementItemName = styled.div`
  margin-right: auto;
  font-size: 14px;
  color: #2A2A2A;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AddNew = styled.div`
  margin-top: 16px;

  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #2361FF;
  }

  span.ant-upload {
    cursor: pointer;
  }

  svg {
    margin-left: 15px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  background: #2361FF !important;
  border-radius: 12px;
  padding: 24px 35px;
  color: #ffffff !important;
  border: none;

  &:hover {
    opacity: .5;
  }

  &:active, &:focus {
    opacity: .8;
  }

  svg {
    margin-left: 80px;
  }
`;

const CostWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const CostLabel = styled.div`
  color: #aeaeae;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.02em;
`;

const Cost = styled.div`
  display: flex;
  margin-top: 2px;
  align-items: center;
  font-size: 42px;
  font-weight: 800;
  color: #262626;
  line-height: 51px;
  letter-spacing: -0.1em;

  span {
    margin-left: 16px;
    color: #2361ff;
    font-weight: 600;
    font-size: 24px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const FormWrap = styled.div`
  margin-top: 28px;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #414141;
  }
`;

const QrImg = styled.img`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const StyledCreateAward = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 120px 45px 0;
  width: 100%;

  .ant-form {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  h1 {
    font-weight: 800;
    font-size: 42px;
    line-height: 51px;
    color: #262626;
  }

  @media (max-width: 1100px) {
    padding: 45px 48px;
    h1 {
      font-size: 32px;
      line-height: 39px;
    }

    ${Content} {
      flex-direction: column;

      ${StyledButton} {
        svg {
          margin-left: 20px;
        }
      }

      ${ImageWrap} {
        margin-right: 0;

        ${Image} {
          width: auto;
          height: 300px;
          min-height: auto;
          min-width: auto;
        }
      }
    }
  }
`;

function CreateAward() {

    const groups = useSelector(({pageReducer: {groups}}: any) => groups);

    const [template, setTemplate] = useState<any>({});
    const {templateId} = useParams();

    const [color, setColor] = useState("");

    const [images, setImages] = useState<any[]>([{
        img: "",
        element: <React.Fragment><ListItemSvg/><ElementItemName>template</ElementItemName></React.Fragment>
    }]);

    const getTemplate = () => {
        axios(`${api}/templates/${templateId}`).then(({data}) => {
            setTemplate(data);
            setColor(data.BackgroundColor);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const getQr = () => {
        new (Modal.success as any)({
            maskClosable: true,
            icon: null,
            content: <QrImg src={`${api}/templates/${templateId}/share`} alt={"qr"}/>
        })
    }


    const sendAward = (fields: any) => {

        const templateIndex = images.findIndex(image => image.img === "");

        const data = {
            Secret: fields.Secret,
            Letter: fields.Letter,
            BackgroundColor: color,
            GroupId: fields.GroupId,
            Images: images.filter(image => image.img !== "").map((image, index: any) => {
                return {
                    OrderZ: -(index - templateIndex >= 0 ? (index - templateIndex + 1) : index - templateIndex),
                    RawImage: image.img
                }
            })
        }
        console.log("templateIndex", templateIndex);

        console.log(data);

        axios.put(`${api}/templates/${templateId}/stamp/${fields.login?.includes("@") ? "email" : "login"}/${fields.login}`, data).then(() => {
            message.success("Reward sent successfully!")
            History.push("/shop");
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const validator = async (_: any, value: any) => {
        await axios(`${api}/test/${value}`).then(() => {
            return Promise.resolve();
        }).catch(() => {
            console.log("User not found!");
            return Promise.reject("User not found!");
        })
    }


    useEffect(() => {
        getTemplate();
        store.dispatch(getGroups());
    }, [])

    // useEffect(() => {
    //     sendAward();
    // }, [images])

    // const testRef = useRef(null);
    // drag(drop(testRef))

    return (
        <StyledCreateAward>
            <h1>Create award</h1>
            <Form onFinish={sendAward}>
                <Content>
                    <ImageWrap>
                        <Image color={color}>
                            {[...images].reverse().map((image, index) => image.img === "" ?
                                <img src={`/${template.Image}`} alt="template"/> :
                                <img key={index} src={image.img} alt="template"/>)}
                        </Image>
                        <Form.Item name={"Letter"}>
                            <StyledInput.TextArea placeholder={"Description"}/>
                        </Form.Item>
                    </ImageWrap>
                    <AwardInfo>
                        <Top>
                            <HeaderInfo>
                                <Collection>
                                    Collection: <span>{template.Collection?.Name || "none"}</span>
                                </Collection>
                                <Counter>
                                    {template.Bought} times
                                </Counter>
                            </HeaderInfo>
                            <Title>
                                <Text>{template.Name}</Text>
                                <Actions>
                                    <IconButton onClick={getQr}><ShareIconSvg/></IconButton>
                                    <IconButton onClick={() => History.push(-1)}><BackSvg/></IconButton>
                                </Actions>
                            </Title>
                            <Rarity>{template.Frequency}</Rarity>
                            <Description>{template.Description}</Description>
                        </Top>
                        <Elements>
                            <h2>Add your own elements</h2>
                            <ElementsList images={images} onChange={setImages}>
                                <AddNew><Upload showUploadList={false} customRequest={(_: any) => {
                                    const id = uuidv4();
                                    const reader = new FileReader();
                                    reader.onloadend = function () {
                                        // preview.src = reader.result;
                                        setImages((images: any) => [{
                                            id: id,
                                            img: reader.result,
                                            element:
                                                <React.Fragment><ListItemSvg/><ElementItemName>{_.file.name}</ElementItemName><ImageListSvg
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        setImages((images_) => {
                                                            console.log("images_", images_, "_id", id);
                                                            return images_.filter((image: any) => image.id !== id)
                                                        })
                                                    }}/></React.Fragment>
                                        }, ...images])
                                    }
                                    if (_.file) {
                                        reader.readAsDataURL(_.file);
                                    }
                                }}>Add new element<CirclePlusSvg/></Upload></AddNew>
                            </ElementsList>
                        </Elements>
                        <FormWrap>
                            <h2>Choice</h2>
                            <Form.Item hasFeedback required rules={[{
                                required: true,
                                message: "This field is required!"
                            }, {
                                validator: validator
                            }
                            ]} name={"login"}>
                                <StyledInput placeholder={"Enter login or e-mail of user"}
                                             suffix={<UserInputSvg/>}/>
                            </Form.Item>
                            <Form.Item name={"Secret"}>
                                <StyledInput placeholder={"Secret text"}/>
                            </Form.Item>
                            {/*<Form.Item>*/}
                            {/*    <StyledSelect disabled suffixIcon={<ArrowCircleSvg/>} placeholder={"Give bonus"}>*/}

                            {/*    </StyledSelect>*/}
                            {/*</Form.Item>*/}
                            <Form.Item>
                                <ColorPicker text={"Choice color background"} onChange={(_color) => {
                                    console.log(`%ccolor ${_color}`, `color:${_color}`)
                                    setColor(_color);
                                }}/>
                            </Form.Item>
                            <Form.Item name={"GroupId"}>
                                <StyledSelect suffixIcon={<ArrowCircleSvg/>} placeholder={"From whom"}>
                                    <Option value={0}>Private</Option>
                                    {groups.map((group: any) => <Option value={group.Id}>{group.Name}</Option>)}
                                </StyledSelect>
                            </Form.Item>
                            <Form.Item>
                                <Footer>
                                    <CostWrap>
                                        <CostLabel>Cost</CostLabel>
                                        <Cost>{template.Cost || 0}<span>$</span></Cost>
                                    </CostWrap>
                                    <StyledButton htmlType={"submit"}>Present<NextSvg/></StyledButton>
                                </Footer>
                            </Form.Item>
                        </FormWrap>
                    </AwardInfo>
                </Content>
            </Form>
        </StyledCreateAward>
    );
}

export default CreateAward;